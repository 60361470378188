import { Box, Button, Tabs, Title } from '@mantine/core';
import { Modal } from 'components/shared';
import { useTranslation } from 'react-i18next';
import { useTemplateStore } from 'stores';
import { TemplatePreview as LegacyTemplatePreview } from 'legacy/TemplatePreview/TemplatePreview';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const TemplatePreviewModal = ({ isOpen, onClose }: Props) => {
  const { previewEditMode, setPreviewEditMode, editorJsonValue } =
    useTemplateStore();
  const { t } = useTranslation('templates');

  return (
    <Modal
      isOpen={isOpen}
      size={1280}
      onClose={onClose}
      withCloseButton={false}
      actionButtons={
        <Button onClick={onClose} variant="default">
          {t('previewModal.actions.close')}
        </Button>
      }
    >
      <Title order={5} sx={theme => ({ marginBottom: theme.other.spacing(2) })}>
        {t('previewModal.title')}
      </Title>
      <Tabs
        defaultValue="edit"
        value={previewEditMode ? 'edit' : 'preview'}
        onTabChange={(value: string) => {
          setPreviewEditMode(value === 'edit');
        }}
      >
        <Tabs.List sx={theme => ({ marginBottom: theme.other.spacing(2) })}>
          <Tabs.Tab value="edit">{t('previewModal.mode.edit')}</Tabs.Tab>
          <Tabs.Tab value="preview">{t('previewModal.mode.preview')}</Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <Box
        sx={theme => ({
          border: `1px solid ${theme.colors.gray[1]}`,
          borderRadius: theme.radius.sm,
          padding: theme.other.spacing(2),
          backgroundColor: '#F7F8FF',
        })}
      >
        <LegacyTemplatePreview template={editorJsonValue} />
      </Box>
    </Modal>
  );
};
