import { Box, Tabs } from '@mantine/core';
import { FormTextField } from './FormTextField';
import { FormSelectField } from './FormSelectField';
import { FormCheckboxField } from './FormCheckboxField';
import { GroupsDropdown } from './GroupsDropdown';
import { detailsFields } from '../constants';

export const DetailsTabPanel = ({
  register,
  formErrors,
  setValue,
  selectedGroup,
  companyType,
  control,
  registryCourts,
}) => {
  return (
    <Tabs.Panel value="details">
      <Box>
        {detailsFields(companyType, registryCourts).map((field, fieldIndex) => {
          if (field.name === 'groups') {
            return (
              <GroupsDropdown
                key={`${field.name}_${fieldIndex}`}
                field={field}
                setValue={setValue}
                selectedGroup={selectedGroup}
              />
            );
          }
          if (!Boolean(field.options)) {
            return (
              <FormTextField
                key={`${field.name}_${fieldIndex}`}
                inputProps={{ ...register(field.name) }}
                error={formErrors?.[field.name]?.message || ''}
                field={field}
                withErrorWrapperAlwaysEnabled={false}
              />
            );
          }

          if (Boolean(field.options)) {
            return (
              <FormSelectField
                key={`${field.name}_${fieldIndex}`}
                selectProps={{
                  ...register(field.name),
                  data: field.options,
                }}
                error={formErrors?.[field.name]?.message || ''}
                field={field}
                control={control}
              />
            );
          }

          if (Boolean(field.defaultChecked)) {
            return (
              <FormCheckboxField
                key={`${field.name}_${fieldIndex}`}
                checkboxProps={{ ...register(field.name) }}
                error={formErrors?.[field.name]?.message || ''}
                field={field}
              />
            );
          }
        })}
      </Box>
    </Tabs.Panel>
  );
};
