import { useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { InitialTextValue } from '../InitialTextValue';
import { Placeholder } from '../Placeholder';
import { Label } from '../Label';
import { Hint } from '../Hint';
import { Required } from '../Required';
import { useGetZodTextVariableSchema } from './hooks';
import { RegExp } from './RegExp';
import { ListSupport } from '../ListSupport';

export const TextVariable = ({
  fieldsInstanceRef,
  initialValues,
  onOpenConditionsManagement,
}) => {
  const schema = useGetZodTextVariableSchema();

  const {
    formState: { errors: formErrors },
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues || {
      initialValue: '',
      placeholder: '',
      label: '',
      hint: '',
      regExp: '',
      required: false,
      list: false,
    },
  });

  useImperativeHandle(fieldsInstanceRef, () => ({
    getValues,
    schema,
    clearErrors,
    setError,
  }));

  return (
    <>
      <InitialTextValue
        inputProps={{ ...register('initialValue') }}
        error={formErrors?.initialValue?.message || ''}
      />
      <Placeholder
        inputProps={{ ...register('placeholder') }}
        error={formErrors?.placeholder?.message || ''}
      />
      <Label
        inputProps={{ ...register('label') }}
        error={formErrors?.label?.message || ''}
      />
      <Hint
        inputProps={{ ...register('hint') }}
        error={formErrors?.hint?.message || ''}
      />
      <RegExp
        inputProps={{ ...register('regExp') }}
        error={formErrors?.regExp?.message || ''}
        onValueClick={value => {
          setValue('regExp', value);
        }}
      />
      <ListSupport
        checkboxProps={{ ...register('list') }}
        error={formErrors?.list?.message || ''}
      />
      <Required
        areConditionsEnabled={watch('required')}
        checkboxProps={{ ...register('required') }}
        onOpenConditionsManagement={onOpenConditionsManagement}
      />
    </>
  );
};
