import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Paper } from '@mantine/core';

import { CreateIcon } from 'icons';
import { getInputVariableValue } from 'helpers';
import { NotFound, TextInputSearch } from 'components/shared';
import { EditorContext } from 'legacy/TemplateCreatorEditor/context';
import { VariableButton } from 'legacy/TemplateCreatorEditor/VariableButton';

export const VariableValues = ({
  node,
  availableVariables,
  onClose,
  onCreateNew,
}) => {
  const { t } = useTranslation('templates');
  const editor = useContext(EditorContext);

  const [searchValue, setSearchValue] = useState('');

  const filteredVariables = availableVariables.filter(({ label }) =>
    label.toLowerCase().includes(searchValue.toLowerCase()),
  );

  return (
    <Paper
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <TextInputSearch
        sx={theme => ({
          marginBottom: theme.other.spacing(1.25),
        })}
        autoFocus
        value={searchValue}
        onChange={e => {
          setSearchValue(getInputVariableValue(e.target.value));
        }}
      />
      <Box
        sx={theme => ({
          marginBottom: theme.other.spacing(3),
          paddingTop: theme.other.spacing(1),
          maxWidth: 306,
          maxHeight: 214,
          overflowY: 'auto',
        })}
      >
        {filteredVariables.length > 0 ? (
          <Box
            sx={theme => ({
              padding: theme.other.spacing(0, 1),
            })}
            component="ul"
          >
            {filteredVariables.map(({ predefined, label, name }) => (
              <Box
                sx={theme => ({
                  marginBottom: theme.other.spacing(2),
                  textAlign: 'left',
                })}
                component="li"
                key={name}
              >
                <VariableButton
                  onClick={() => {
                    editor.updateNodeData(node, { variable: name });
                    onClose();
                  }}
                >
                  {predefined ? label : name}
                </VariableButton>
              </Box>
            ))}
          </Box>
        ) : (
          <NotFound
            sx={theme => ({
              marginBottom: theme.other.spacing(2),
            })}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          sx={theme => ({
            fontSize: theme.fontSizes.lg,
          })}
          size="xs"
          color="green"
          onClick={onCreateNew}
        >
          <CreateIcon
            sx={theme => ({
              marginRight: theme.other.spacing(1),
            })}
          />
          {t('templates:templateCreatorVariables.createNewVariable')}
        </Button>
      </Box>
    </Paper>
  );
};
