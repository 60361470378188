import { Block } from 'slate';
import {
  CHILD_OBJECT_INVALID,
  CHILD_TYPE_INVALID,
  PARENT_TYPE_INVALID,
} from 'slate-schema-violations';

import { createCell } from '../utils';

/*
 * A row's children must be cells.
 * If they're not then we wrap them within a cell.
 */
function onlyCellsInRow(opts, change, error) {
  const cell = createCell(opts, []);
  const index = error.node.nodes.findIndex(
    child => child.key === error.child.key,
  );
  change.withoutNormalizing(() => {
    change.insertNodeByKey(error.node.key, index, cell);
    change.moveNodeByKey(error.child.key, cell.key, 0);
  });
}

/*
 * Rows can't live outside a table, if one is found then we wrap it within a table.
 */
function rowOnlyInTable(opts, change, error) {
  return change.wrapBlockByKey(error.node.key, opts.typeTable);
}

/*
 * A cell's children must be "block"s.
 * If they're not then we wrap them within a block with a type of opts.typeContent
 */
function onlyBlocksInCell(opts, change, error) {
  const block = Block.create({
    type: opts.typeContent,
  });
  change.withoutNormalizing(() => {
    change.insertNodeByKey(error.node.key, 0, block);
    const inlines = error.node.nodes.filter(node => node.object !== 'block');
    inlines.forEach((inline, index) => {
      change.moveNodeByKey(inline.key, block.key, index);
    });
  });
}

/*
 * Cells can't live outside a row, if one is found then we wrap it within a row.
 */
function cellOnlyInRow(opts, change, error) {
  return change.wrapBlockByKey(error.node.key, opts.typeRow);
}

/*
 * Returns a schema definition for the plugin
 */
export default function schema(opts) {
  return {
    blocks: {
      [opts.typeTable]: {
        nodes: [{ match: { type: opts.typeRow } }],
      },
      [opts.typeRow]: {
        nodes: [{ match: { type: opts.typeCell } }],
        parent: { type: opts.typeTable },
        normalize(change, error) {
          switch (error.code) {
            case CHILD_TYPE_INVALID:
              return onlyCellsInRow(opts, change, error);
            case PARENT_TYPE_INVALID:
              return rowOnlyInTable(opts, change, error);
            default:
              return undefined;
          }
        },
      },
      [opts.typeCell]: {
        nodes: [{ match: { object: 'block' } }],
        parent: { type: opts.typeRow },
        normalize(change, error) {
          switch (error.code) {
            case CHILD_OBJECT_INVALID:
              return onlyBlocksInCell(opts, change, error);
            case PARENT_TYPE_INVALID:
              return cellOnlyInRow(opts, change, error);
            default:
              return undefined;
          }
        },
      },
    },
  };
}
