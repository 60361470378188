import { getCurrentItem } from '../utils';

/**
 * Split a list item at the start of the current range.
 */
export default function splitListItem(opts, editor) {
  const { value } = editor;
  const currentItem = getCurrentItem(opts, value);

  if (!currentItem) {
    return editor;
  }

  const splitOffset = value.selection.start.offset;

  editor.splitDescendantsByKey(
    currentItem.key,
    value.selection.start.key,
    splitOffset,
  );

  return editor.setNodeByKey(getCurrentItem(opts, editor.value).key, {
    data: {},
  });
}
