export const DEFAULT_CONTRACT_TEMPLATE = {
  object: 'value',
  document: {
    object: 'document',
    data: {
      disableAutoPunctuation: true,
      creator: '2.0',
    },
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        data: {
          id: 1,
        },
        nodes: [
          {
            object: 'text',
            leaves: [
              {
                object: 'leaf',
                text: '',
                marks: [],
              },
            ],
          },
        ],
      },
    ],
  },
};
