import { createContext } from 'react';

import {
  CompanyWrapper,
  ConditionalWrapper,
  Divider,
  Header,
  Input,
  LanguageWrapper,
  ListItem,
  LoopWrapperNode,
  OrderedList,
  PageBreak,
  Paragraph,
  Resize,
  Signatures,
  StaticTable,
  Table,
  TableCell,
  TableRow,
  Title,
  Tooltip,
  Variable,
} from './elements';

export const LoopWrapperContext = createContext(null);
export const LanguageContext = createContext(null);

export const createRules = (template, slateObj) => [
  {
    serialize(obj, children) {
      if (obj.object === 'mark') {
        switch (obj.type) {
          case 'bold':
            return <b>{children}</b>;
          case 'italic':
            return <i>{children}</i>;
          case 'underline':
            return <u>{children}</u>;
          case 'strikethrough':
            return <s>{children}</s>;
          case 'resize':
            return <Resize obj={obj}>{children}</Resize>;
          default:
            return undefined;
        }
      }

      return undefined;
    },
  },
  {
    serialize(obj, children) {
      if (obj.object === 'block') {
        switch (obj.type) {
          case 'paragraph':
          case 'alignment':
            return <Paragraph obj={obj}>{children}</Paragraph>;
          case 'orderedList':
            return (
              <OrderedList obj={obj} slateObj={slateObj}>
                {children}
              </OrderedList>
            );
          case 'listItem':
            return (
              <ListItem obj={obj} template={template}>
                {children}
              </ListItem>
            );
          case 'title':
            return <Title obj={obj}>{children}</Title>;
          case 'header':
            return (
              <Header obj={obj} template={template}>
                {children}
              </Header>
            );
          case 'conditionalWrapper':
            return (
              <ConditionalWrapper obj={obj} template={template}>
                {children}
              </ConditionalWrapper>
            );
          case 'languageWrapper':
            return <LanguageWrapper obj={obj}>{children}</LanguageWrapper>;
          case 'loopWrapper':
            return (
              <LoopWrapperNode obj={obj} template={template}>
                {children}
              </LoopWrapperNode>
            );
          case 'table':
            return <Table>{children}</Table>;
          case 'tableRow':
            return (
              <TableRow obj={obj} template={template}>
                {children}
              </TableRow>
            );
          case 'tableCell':
            return <TableCell>{children}</TableCell>;
          case 'signatures':
            return <Signatures obj={obj} />;
          case 'pageBreak':
            return <PageBreak />;
          case 'staticTable':
            return <StaticTable obj={obj} />;
          case 'interline':
            return <Divider />;
          case 'companyWrapper':
          case 'companiesWrapper':
            return <CompanyWrapper>{children}</CompanyWrapper>;
          default:
            return undefined;
        }
      } else if (obj.object === 'inline') {
        switch (obj.type) {
          case 'input':
            return <Input obj={obj} template={template} />;
          case 'variable':
            return <Variable obj={obj} template={template} />;
          case 'conditionalWrapper':
            return (
              <ConditionalWrapper obj={obj} template={template}>
                {children}
              </ConditionalWrapper>
            );
          case 'tooltip':
            return <Tooltip obj={obj} />;
          default:
            return undefined;
        }
      }

      return undefined;
    },
  },
];
