import { Box, Text } from '@mantine/core';

import {
  TransactionDocumentQueueType,
  TransactionQueueParticipantType,
} from 'types';

import { useTranslation } from 'react-i18next';
import { CheckCircleIcon, SignIcon } from 'icons';
import { getParticipantErrors } from '../helpers';
import { Participant } from '../Participant';
import { Group } from '../Group';
import {
  TransactionQueueGroupWithParticipantType,
  TransactionQueueProcessedParticipantType,
} from '../types';

function isTransactionQueueGroup(
  participant: TransactionQueueProcessedParticipantType,
): participant is TransactionQueueGroupWithParticipantType {
  return (
    (participant as TransactionQueueGroupWithParticipantType).users !==
    undefined
  );
}

function isTransactionQueueParticipantType(
  user: TransactionQueueParticipantType | string,
): user is TransactionQueueParticipantType {
  return (user as TransactionQueueParticipantType).step !== undefined;
}

const getCurrentStep = (
  stepData:
    | TransactionQueueParticipantType
    | TransactionQueueProcessedParticipantType,
) => {
  if (isTransactionQueueGroup(stepData)) {
    return isTransactionQueueParticipantType(stepData.users[0])
      ? stepData.users[0].step
      : null;
  }
  return stepData.step;
};

type Props = {
  type: 'acceptors' | 'signers';
  participants:
    | TransactionQueueProcessedParticipantType[][]
    | TransactionQueueParticipantType[][];
  queueErrors: TransactionDocumentQueueType['errors'];
};
export const ParticipantListItems = ({
  type,
  participants,
  queueErrors,
}: Props) => {
  const { t } = useTranslation('documents');
  return (
    <>
      {participants.map((stepData, stepIndex) => {
        const currentStep = getCurrentStep(stepData[0]) || stepIndex + 1;

        return (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={stepIndex}
            sx={theme => ({
              marginBottom: theme.other.spacing(2.75),
            })}
            component="li"
          >
            <div>
              <Box
                sx={theme => ({
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: theme.other.spacing(2),
                })}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {type === 'acceptors' ? (
                    <CheckCircleIcon color="orange" size={18} />
                  ) : (
                    <SignIcon color="green" size={22} />
                  )}
                  <Text
                    sx={theme => ({ marginLeft: theme.other.spacing(0.5) })}
                    color={type === 'acceptors' ? 'orange' : 'green'}
                    size="md"
                    weight={600}
                  >
                    {type === 'acceptors'
                      ? t('documents:documentRole.acceptor')
                      : t('documents:documentRole.signer')}
                  </Text>
                </Box>
                <Text size="md" weight={500}>
                  {t('documents:queueStep')} {currentStep}
                </Text>
              </Box>
              <Box>
                {stepData.map((participant, participantIndex) => {
                  const participantErrors = getParticipantErrors({
                    type,
                    queueErrors,
                    stepIndex,
                    participantIndex,
                  });

                  if (isTransactionQueueGroup(participant)) {
                    return (
                      <Group
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={`group.${participantIndex}`}
                        participant={participant}
                        errors={participantErrors}
                      />
                    );
                  }

                  return (
                    <Participant
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={`participant.${participantIndex}`}
                      participant={participant}
                      errors={participantErrors}
                    />
                  );
                })}
              </Box>
            </div>
          </Box>
        );
      })}
    </>
  );
};
