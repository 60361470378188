import { useTranslation } from 'react-i18next';
import { Select } from '@mantine/core';

import { FlagDeIcon, FlagPlIcon, FlagUkIcon } from 'icons';

import { SelectItem } from './SelectItem';

const LANGUAGES = [
  {
    value: 'pl',
    labelTranslation: 'templates:lang.polish',
    Icon: FlagPlIcon,
  },
  {
    value: 'en',
    labelTranslation: 'templates:lang.english',
    Icon: FlagUkIcon,
  },
  {
    value: 'de',
    labelTranslation: 'templates:lang.german',
    Icon: FlagDeIcon,
  },
] as const;

type LanguageType = (typeof LANGUAGES)[number]['value'];

type Props = {
  language: LanguageType;
  onLanguageChange: (language: LanguageType) => void;
};

export const LanguageSelect = ({ language, onLanguageChange }: Props) => {
  const { t } = useTranslation('templates');

  const LanguageIcon = LANGUAGES.find(({ value }) => value === language)?.Icon;

  return (
    <Select
      sx={{
        width: 180,
      }}
      styles={{
        wrapper: {
          marginBottom: 0,
        },
        icon: {
          width: 18,
          height: 18,
          transform: 'translateX(1px)',
        },
        withIcon: {
          paddingLeft: 38,
        },
      }}
      value={language}
      onChange={(value: LanguageType) => {
        onLanguageChange(value);
      }}
      icon={LanguageIcon && <LanguageIcon />}
      data={LANGUAGES.map(({ labelTranslation, ...languageItem }) => ({
        ...languageItem,
        label: t(labelTranslation),
      }))}
      itemComponent={SelectItem}
      error={null}
    />
  );
};
