import { useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Checkbox, Text } from '@mantine/core';

import { DescriptionField } from 'components/shared';

import { getNumberInputValue } from '../helpers';
import { InitialNumberValue } from '../InitialNumberValue';
import { Placeholder } from '../Placeholder';
import { Label } from '../Label';
import { Hint } from '../Hint';
import { Required } from '../Required';
import { MinMaxNumberValue } from '../MinMaxNumberValue';
import { useGetZodMoneyVariableSchema } from './hooks';
import { Currency } from './Currency';
import { LanguageSelect } from './LanguageSelect';
import { ListSupport } from '../ListSupport';

export const MoneyVariable = ({
  fieldsInstanceRef,
  initialValues,
  onOpenConditionsManagement,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('templates');

  const schema = useGetZodMoneyVariableSchema();

  const {
    formState: { errors: formErrors },
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
      ? {
          ...initialValues,
          initialValue: initialValues.initialValue
            ? Number(initialValues.initialValue)
            : undefined,
          minValue: initialValues.minValue
            ? Number(initialValues.minValue)
            : undefined,
          maxValue: initialValues.maxValue
            ? Number(initialValues.maxValue)
            : undefined,
        }
      : {
          initialValue: undefined,
          placeholder: '',
          label: '',
          hint: '',
          currency: '',
          minValue: undefined,
          maxValue: undefined,
          translateMoneyValue: true,
          defaultMoneyTranslation: language,
          required: false,
        },
  });

  useImperativeHandle(fieldsInstanceRef, () => ({
    getValues: () => {
      const { initialValue, minValue, maxValue, ...values } = getValues();

      return {
        ...values,
        initialValue: getNumberInputValue(initialValue),
        minValue: getNumberInputValue(minValue),
        maxValue: getNumberInputValue(maxValue),
      };
    },
    schema,
    clearErrors,
    setError,
  }));

  const { initialValue, minValue, maxValue } = getValues();
  const handleNumberValueChange = (value, field) => {
    clearErrors(field);
    setValue(field, value);
  };

  return (
    <>
      <InitialNumberValue
        inputProps={{
          defaultValue: initialValue,
          onChange: value => {
            handleNumberValueChange(value, 'initialValue');
          },
        }}
        error={formErrors?.initialValue?.message || ''}
      />
      <Placeholder
        inputProps={{ ...register('placeholder') }}
        error={formErrors?.placeholder?.message || ''}
      />
      <Label
        inputProps={{ ...register('label') }}
        error={formErrors?.label?.message || ''}
      />
      <Hint
        inputProps={{ ...register('hint') }}
        error={formErrors?.hint?.message || ''}
      />
      <Currency
        inputProps={{ ...register('currency') }}
        error={formErrors?.currency?.message || ''}
        onValueClick={value => {
          setValue('currency', value);
        }}
      />
      <MinMaxNumberValue
        inputMinValueProps={{
          defaultValue: minValue,
          onChange: value => {
            handleNumberValueChange(value, 'minValue');
          },
        }}
        inputMaxValueProps={{
          defaultValue: maxValue,
          onChange: value => {
            handleNumberValueChange(value, 'maxValue');
          },
        }}
        error={
          formErrors?.minValue?.message || formErrors?.maxValue?.message || ''
        }
      />
      <DescriptionField
        label={t('templates:templateCreatorVariables.translateMoneyValue')}
        description={t(
          'templates:templateCreatorVariables.translateMoneyValueDescription',
        )}
        bottomSection={
          watch('translateMoneyValue') && (
            <Grid mt={2}>
              <Grid.Col xs={12} sm={5}>
                <Text weight={600}>
                  {t(
                    'templates:templateCreatorVariables.defaultTranslationLanguage',
                  )}
                </Text>
              </Grid.Col>
              <Grid.Col xs={12} sm={7}>
                <LanguageSelect
                  language={watch('defaultMoneyTranslation')}
                  onLanguageChange={language => {
                    setValue('defaultMoneyTranslation', language);
                  }}
                />
              </Grid.Col>
            </Grid>
          )
        }
      >
        <Checkbox sx={{ margin: 0 }} {...register('translateMoneyValue')} />
      </DescriptionField>
      <ListSupport
        checkboxProps={{ ...register('list') }}
        error={formErrors?.list?.message || ''}
      />
      <Required
        areConditionsEnabled={watch('required')}
        checkboxProps={{ ...register('required') }}
        onOpenConditionsManagement={onOpenConditionsManagement}
      />
    </>
  );
};
