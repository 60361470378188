import { apiAuthClient } from 'api/helpers';
import { stringifyUrlQuery } from 'helpers';
import { GetCompaniesRequestType, GetCompaniesResponseType } from '../types';

export const getCompanies = async ({
  pathParams: { boardId },
  queryParams: { email, search, perPage, sortBy },
}: GetCompaniesRequestType) => {
  const query = stringifyUrlQuery({
    email,
    search,
    perPage,
    sortBy,
  });

  const url = `/boards/${boardId}/company/${query}`;
  const { data } = await apiAuthClient.get<GetCompaniesResponseType>(url);

  return data.data;
};
