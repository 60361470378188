import React, { forwardRef, useRef, useState } from 'react';
import {
  DatePicker as MantineDatePicker,
  DatePickerProps as MantineDatePickerProps,
} from '@mantine/dates';
import { useMergedRef } from '@mantine/hooks';
import { ClearableInputRightSection } from '../ClearableInputRightSection';
import { CalendarIcon } from '../../../../icons';

interface DatePickerProps extends MantineDatePickerProps {
  // Add additional properties if needed
}

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (props, ref) => {
    const { value, clearable = false, onBlur, onFocus, onChange } = props;

    const [isFocussed, setIsFocused] = useState(false);
    const [dropdownOpened, setDropdownOpened] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const mergedRef = useMergedRef(inputRef, ref);

    const isClearable =
      clearable &&
      (isFocussed || dropdownOpened) &&
      value !== null &&
      value !== undefined;

    return (
      <MantineDatePicker
        ref={mergedRef}
        rightSection={
          <ClearableInputRightSection
            isClearable={isClearable}
            inputRef={inputRef}
            rightIcon={<CalendarIcon />}
            valueToSet={null}
            setFocusAfterClear={false}
            onClear={() => onChange?.(null)}
          />
        }
        {...props}
        onBlur={e => {
          setIsFocused(false);

          if (onBlur) {
            onBlur(e);
          }
        }}
        onFocus={e => {
          setIsFocused(true);

          if (onFocus) {
            onFocus(e);
          }
        }}
        onDropdownOpen={() => {
          setDropdownOpened(true);
          props.onDropdownOpen?.();
        }}
        onDropdownClose={() => {
          setDropdownOpened(false);
          props.onDropdownClose?.();
        }}
      />
    );
  },
);

export default DatePicker;
