import { Box, Checkbox, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export const EditList = ({
  filteredVariables = [],
  selectedVariables = [],
  usedVariables = [],
  onChange,
}) => {
  const filteredVariablesNames = filteredVariables.map(({ name }) => name);
  const { t } = useTranslation('templates');
  const used = filteredVariablesNames.filter(name =>
    usedVariables.includes(name),
  );
  const notUsed = filteredVariablesNames.filter(
    name => !usedVariables.includes(name),
  );

  return (
    <Flex direction="column" gap={16} pt={16}>
      {notUsed.length !== 0 && (
        <>
          <Box>{t('variableNotUsed')}</Box>
          <EditListCheckbox
            variables={notUsed}
            selectedVariables={selectedVariables}
            onChange={onChange}
          />
        </>
      )}
      {used.length !== 0 && (
        <>
          {notUsed.length !== 0 && <Box>{t('variableUsed')}</Box>}
          <EditListCheckbox
            variables={used}
            selectedVariables={selectedVariables}
            onChange={onChange}
          />
        </>
      )}
    </Flex>
  );
};

const EditListCheckbox = ({
  variables = [],
  selectedVariables = [],
  onChange,
}) => {
  const { t } = useTranslation('template');

  /**
   * Select all variables
   * @param {string[]} state
   * @returns {string[]}
   */
  const selectAll = state => {
    return [...new Set([...state, ...variables.map(name => name)])];
  };

  /**
   * Deselect all variables
   * @param {string[]} state
   * @returns {string[]}
   */
  const deselectAll = state => {
    return state.filter(v => !variables.map(name => name).includes(v));
  };

  return (
    <Box
      sx={theme => ({
        display: 'grid',
        rowGap: theme.other.spacing(2),
      })}
      component="ul"
    >
      <li>
        <label>
          <Flex direction="row" gap={12}>
            <Checkbox
              sx={{ marginBottom: 0 }}
              checked={variables.every(name =>
                selectedVariables.includes(name),
              )}
              onChange={event => {
                onChange(state =>
                  event.target.checked ? selectAll(state) : deselectAll(state),
                );
              }}
              name={'selectAll'}
            />
            <Flex align="center">{t('common:selectAll')}</Flex>
          </Flex>
        </label>
      </li>

      {variables.map(name => (
        <li key={name}>
          <label>
            <Flex direction="row" gap={12}>
              <Checkbox
                sx={{ marginBottom: 0 }}
                checked={selectedVariables.includes(name)}
                onChange={event => {
                  onChange(state =>
                    event.target.checked
                      ? [...state, name]
                      : selectedVariables.filter(v => v !== name),
                  );
                }}
                name={name}
              />
              <Flex align="center">{name}</Flex>
            </Flex>
          </label>
        </li>
      ))}
    </Box>
  );
};
