import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DYNAMIC_URLS } from 'consts';
import { getHasHateoasAccess } from 'helpers';
import { useGetV1Url } from 'hooks';
import { CopyIcon, TrashIcon, EditIcon } from 'icons';
import { ActionItemType, BoardTemplateListItemType } from 'types';
import { ActionsBar } from 'components/shared';

import { getIsLocalTemplate } from '../helpers';
import { BoardTemplateLocalListItemType } from '../types';
import { DuplicateModal } from '../DuplicateModal';
import { DeleteModal } from './DeleteModal';

type Props = {
  selectedTemplates: BoardTemplateLocalListItemType[];
  setSelectedTemplates: Dispatch<
    SetStateAction<BoardTemplateLocalListItemType[]>
  >;
  refetchGetBoardTemplates: () => void;
};

const DELETE_LIMIT = 20;

export const TemplateMassActions = ({
  selectedTemplates,
  setSelectedTemplates,
  refetchGetBoardTemplates,
}: Props) => {
  const { t } = useTranslation(['common', 'templates']);

  const getV1Url = useGetV1Url();

  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const isOnlyOneTemplateSelected = selectedTemplates.length === 1;

  const isFirstTemplateSelectedLocal = Boolean(
    selectedTemplates[0] && getIsLocalTemplate(selectedTemplates[0].id),
  );

  const firstSelectedTemplate__links = isFirstTemplateSelectedLocal
    ? null
    : (selectedTemplates[0] as BoardTemplateListItemType).__links;

  const hasDeleteAccess = selectedTemplates.some(template =>
    '__links' in template
      ? getHasHateoasAccess('delete', template.__links)
      : true,
  );
  const hasUpdateAccess =
    getHasHateoasAccess('update', firstSelectedTemplate__links) ||
    isFirstTemplateSelectedLocal;
  const hasAttachLetterheadAccess = getHasHateoasAccess(
    'branding',
    firstSelectedTemplate__links,
  );

  const getActionItems = () => {
    const hasReachedDeleteLimit = selectedTemplates.length > DELETE_LIMIT;

    const actions: (ActionItemType & { shouldRender: boolean })[] = [
      {
        shouldRender: isOnlyOneTemplateSelected && hasUpdateAccess,
        name: t('common:edit'),
        to: isFirstTemplateSelectedLocal
          ? getV1Url('templatesNew', {
              localTemplateId: selectedTemplates[0].id as string,
            })
          : getV1Url('template', {
              templateId: String(selectedTemplates[0].id),
            }),
        icon: <EditIcon />,
      },
      {
        shouldRender: hasDeleteAccess,
        name: t('common:delete'),
        action: () => {
          setIsDeleteModalOpen(true);
        },
        color: 'red',
        icon: <TrashIcon />,
        tooltipLabel: hasReachedDeleteLimit
          ? t('common:massActionLimit', {
              limit: DELETE_LIMIT,
            })
          : null,
        disabled: hasReachedDeleteLimit,
      },
      {
        shouldRender: isOnlyOneTemplateSelected,
        name: t('common:duplicate'),
        action: () => {
          setIsDuplicateModalOpen(true);
        },
        icon: <CopyIcon />,
        dataTestId: 'TemplateMassActions_DuplicateTemplate',
      },
      {
        shouldRender: isOnlyOneTemplateSelected && hasAttachLetterheadAccess,
        name:
          (selectedTemplates[0] as BoardTemplateListItemType)
            .documentBrandingSource === 'template'
            ? t('templates:editLetterhead')
            : t('templates:attachLetterhead'),
        to: DYNAMIC_URLS.templateBranding({
          templateId: String(selectedTemplates[0].id),
        }),
      },
    ];

    return actions
      .filter(({ shouldRender }) => shouldRender)
      .map(({ shouldRender: _, ...actionItems }) => actionItems);
  };

  return (
    <>
      <ActionsBar
        label={t('common:selected', {
          number: selectedTemplates.length,
        })}
        items={getActionItems()}
      />
      {isOnlyOneTemplateSelected && isDuplicateModalOpen && (
        <DuplicateModal
          isOpen={isDuplicateModalOpen}
          onClose={() => {
            setIsDuplicateModalOpen(false);
          }}
          template={selectedTemplates[0]}
          refetchGetBoardTemplates={refetchGetBoardTemplates}
        />
      )}
      {hasDeleteAccess && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          selectedTemplates={selectedTemplates}
          setSelectedTemplates={setSelectedTemplates}
        />
      )}
    </>
  );
};
