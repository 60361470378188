import { Wrapper } from './Wrapper';
import { useTranslation } from 'react-i18next';
import { EditIcon, EyeIcon } from 'icons';
import { Box, Paper, Text } from '@mantine/core';

export const CompanyWrapper = ({
  attributes,
  node,
  editor,
  children,
  isSelected,
  isFocused,
}) => {
  const { t } = useTranslation(['templates']);
  const data = node.data.toJS();

  const documentData = editor.value.document.data.toJS();

  const companyVariables = (documentData.variables || []).filter(
    v => v.type === 'company',
  );

  return (
    <Wrapper
      attributes={attributes}
      isSelected={isSelected}
      isFocused={isFocused}
    >
      <Box
        sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          marginRight: -theme.other.spacing(2),
          marginBottom: theme.other.spacing(1),
          paddingTop: theme.other.spacing(2),
        })}
        contentEditable={false}
      >
        {companyVariables.length > 0 && (
          <div
            onClick={e => {
              e.stopPropagation();
              editor.setNodeByKey(node.key, {
                data: { ...data, edited: !data.edited },
              });
            }}
          >
            {!data.edited ? <EditIcon /> : <EyeIcon />}
          </div>
        )}
      </Box>
      <Box sx={!data.edited ? { display: 'none' } : {}}>{children}</Box>
      <div contentEditable={false}>
        {!data.edited && (
          <Paper
            sx={theme => ({
              padding: theme.other.spacing(1),
              backgroundColor: '#EBEDF7',
            })}
          >
            <Text>
              {t('templates:toolbar.contractingParty')}: {data.company}
            </Text>
            <Text align="center">
              {t('templates:toolbar.chooseFromListTheNumberofContractsParties')}
            </Text>
            <Text align="center">
              {t('templates:toolbar.willCompleteContractorsDatalater')}
            </Text>
          </Paper>
        )}
      </div>
    </Wrapper>
  );
};
