import { useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mantine/core';

import { DescriptionField } from 'components/shared';

import { Label } from '../Label';
import { useGetZodBooleanVariableSchema } from './hooks';
import { ListSupport } from '../ListSupport';

export const BooleanVariable = ({ fieldsInstanceRef, initialValues }) => {
  const { t } = useTranslation('templates');

  const schema = useGetZodBooleanVariableSchema();

  const {
    formState: { errors: formErrors },
    register,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues || {
      initialValue: true,
      label: '',
    },
  });

  useImperativeHandle(fieldsInstanceRef, () => ({
    getValues,
    schema,
    clearErrors,
    setError,
  }));

  return (
    <>
      <DescriptionField
        label={t('templates:templateCreatorVariables.initialValue')}
        description={t(
          'templates:templateCreatorVariables.initialValueDescription',
        )}
      >
        <Checkbox
          sx={{ margin: 0 }}
          {...register('initialValue')}
          label={t('templates:templateCreatorVariables.true')}
        />
      </DescriptionField>
      <Label
        inputProps={{ ...register('label') }}
        error={formErrors?.label?.message || ''}
      />
      <ListSupport
        checkboxProps={{ ...register('list') }}
        error={formErrors?.list?.message || ''}
      />
    </>
  );
};
