import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';

import { TransactionsIcon } from 'icons';
import { ListIcon } from 'legacy/TemplateCreatorEditor/icons';
import { EditorContext } from 'legacy/TemplateCreatorEditor/context';

import { EditorActionButton } from '../EditorActionButton';
import { Tables } from './Tables';

export const PrimarySection = () => {
  const { t } = useTranslation('templates');

  const editor = useContext(EditorContext);

  return (
    <Box sx={theme => ({ display: 'flex', gap: theme.other.spacing(2) })}>
      <EditorActionButton onClick={editor.toggleListWrap}>
        <ListIcon />
        {t('templates:toolbar.list')}
      </EditorActionButton>
      <Tables />
      <EditorActionButton onClick={editor.wrapInLoopWrapper}>
        <TransactionsIcon />
        {t('templates:loop')}
      </EditorActionButton>
    </Box>
  );
};
