import { cloneElement, Children, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Switch } from '@mantine/core';
import { LoopWrapperContext } from '../createRules';
import { useTemplateStore } from 'stores';
import { LoopWrapper } from './common/LoopWrapper';
import { getVariableName } from '../helpers';

export const ListItem = ({ obj, template, children }) => {
  const loop = obj.data.get('loop');

  if (loop) {
    const dependentVariableNames = obj
      .filterDescendants(v => v.type === 'input')
      .toJS()
      .filter(
        v =>
          !!template.document.data.variables.find(
            variable => variable.name === v.data.variable && variable.list,
          ),
      )
      .map(v => v.data.variable)
      .concat(obj.data.get('variable') || []);

    if (dependentVariableNames.length === 0) {
      return (
        <Item
          hasSublist={!!obj.findDescendant(v => v.type === 'listItem')}
          variableName={obj.data.get('variable')}
          variables={template.document.data.variables}
          obj={obj}
        >
          {children}
        </Item>
      );
    }

    return (
      <LoopWrapper
        variables={template.document.data.variables}
        dependentVariableNames={dependentVariableNames}
      >
        <Item
          hasSublist={!!obj.findDescendant(v => v.type === 'listItem')}
          variableName={obj.data.get('variable')}
          variables={template.document.data.variables}
          obj={obj}
        >
          {children}
        </Item>
      </LoopWrapper>
    );
  }

  return (
    <Item
      hasSublist={!!obj.findDescendant(v => v.type === 'listItem')}
      variableName={obj.data.get('variable')}
      variables={template.document.data.variables}
      obj={obj}
    >
      {children}
    </Item>
  );
};

const Item = ({
  hasSublist = false,
  variableName,
  variables,
  obj,
  children,
}) => {
  const { previewEditMode } = useTemplateStore();
  const { setValue, watch } = useFormContext();
  const loopWrapperIndex = useContext(LoopWrapperContext);
  const mappedVariable =
    variableName && variables.find(v => v.name === variableName);

  const variableNameLoop = getVariableName(mappedVariable, loopWrapperIndex);

  const value = mappedVariable ? watch(variableNameLoop) : null;

  if (!obj.text) {
    return null;
  }

  if (mappedVariable && mappedVariable.type === 'conditional') {
    const shouldBeRendered = evaluateConditions(
      mappedVariable.condition,
      variables,
      value,
    );

    if (previewEditMode && shouldBeRendered) {
      return (
        <li
          className={hasSublist ? '' : 'last-depth'}
          style={
            shouldBeRendered
              ? {}
              : { color: 'grey', textDecoration: 'line-through' }
          }
        >
          {Children.map(children, child =>
            cloneElement(child, {
              listBlock: true,
              hasSublist,
            }),
          )}
        </li>
      );
    }

    if (previewEditMode) {
      return (
        <div style={{ color: 'grey', textDecoration: 'line-through' }}>
          {Children.map(children, child =>
            cloneElement(child, {
              listBlock: true,
              hasSublist,
            }),
          )}
        </div>
      );
    }

    if (!previewEditMode && shouldBeRendered) {
      return (
        <li className={hasSublist ? '' : 'last-depth'}>
          {Children.map(children, child =>
            cloneElement(child, {
              listBlock: true,
              hasSublist,
            }),
          )}
        </li>
      );
    }

    return null;
  }

  if (!previewEditMode && mappedVariable && !value) {
    return null;
  }

  if (variableName) {
    return (
      <LoopWrapperContext.Consumer>
        {() => {
          return (
            <li style={{ marginBottom: '0.5rem' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  transform: previewEditMode ? 'translateY(3px)' : undefined,
                  color: !value ? 'grey' : undefined,
                  textDecoration: !value ? 'line-through' : undefined,
                }}
              >
                {previewEditMode && (
                  <Switch
                    checked={value}
                    onChange={() => setValue(variableNameLoop, !value)}
                    style={{ marginRight: '0.5rem' }}
                  />
                )}
                <Box sx={{ flex: 1 }}>
                  {Children.map(children, child =>
                    cloneElement(child, {
                      listBlock: true,
                      hasSublist,
                    }),
                  )}
                </Box>
              </div>
            </li>
          );
        }}
      </LoopWrapperContext.Consumer>
    );
  }

  return (
    <li className={hasSublist ? '' : 'last-depth'}>
      {Children.map(children, child =>
        cloneElement(child, {
          listBlock: true,
          hasSublist,
        }),
      )}
    </li>
  );
};
