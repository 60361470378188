import { useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { getNumberInputValue } from '../helpers';
import { Placeholder } from '../Placeholder';
import { Label } from '../Label';
import { Hint } from '../Hint';
import { Required } from '../Required';
import { MinMaxNumberValue } from '../MinMaxNumberValue';
import { useGetZodDateVariableSchema } from './hooks';
import { InitialValue } from './InitialValue';
import { ListSupport } from '../ListSupport';

export const DateVariable = ({
  fieldsInstanceRef,
  initialValues,
  onOpenConditionsManagement,
}) => {
  const schema = useGetZodDateVariableSchema();

  const {
    control,
    formState: { errors: formErrors },
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
      ? {
          ...initialValues,
          initialValue: initialValues.initialValue
            ? new Date(initialValues.initialValue)
            : null,
          minYearDelta:
            initialValues.minYearDelta !== undefined &&
            initialValues.minYearDelta !== null
              ? Number(initialValues.minYearDelta)
              : undefined,
          maxYearDelta:
            initialValues.maxYearDelta !== undefined &&
            initialValues.maxYearDelta !== null
              ? Number(initialValues.maxYearDelta)
              : undefined,
        }
      : {
          initialValue: null,
          placeholder: '',
          label: '',
          hint: '',
          minYearDelta: undefined,
          maxYearDelta: undefined,
          required: false,
        },
  });

  useImperativeHandle(fieldsInstanceRef, () => ({
    getValues: () => {
      const { initialValue, minYearDelta, maxYearDelta, ...values } =
        getValues();

      return {
        ...values,
        initialValue: initialValue ? initialValue.toISOString() : null,
        minYearDelta: getNumberInputValue(minYearDelta),
        maxYearDelta: getNumberInputValue(maxYearDelta),
      };
    },
    schema,
    clearErrors,
    setError,
  }));

  const { minYearDelta, maxYearDelta } = getValues();

  const handleNumberValueChange = (value, field) => {
    clearErrors(field);
    setValue(field, value);
  };

  return (
    <>
      <InitialValue
        control={control}
        error={formErrors?.initialValue?.message}
      />
      <Placeholder
        inputProps={{ ...register('placeholder') }}
        error={formErrors?.placeholder?.message || ''}
      />
      <Label
        inputProps={{ ...register('label') }}
        error={formErrors?.label?.message || ''}
      />
      <Hint
        inputProps={{ ...register('hint') }}
        error={formErrors?.hint?.message || ''}
      />
      <MinMaxNumberValue
        inputMinValueProps={{
          defaultValue: minYearDelta,
          min: 0,
          onChange: value => {
            handleNumberValueChange(value, 'minYearDelta');
          },
        }}
        inputMaxValueProps={{
          defaultValue: maxYearDelta,
          min: 0,
          onChange: value => {
            handleNumberValueChange(value, 'maxYearDelta');
          },
        }}
        error={
          formErrors?.minYearDelta?.message ||
          formErrors?.maxYearDelta?.message ||
          ''
        }
      />
      <ListSupport
        checkboxProps={{ ...register('list') }}
        error={formErrors?.list?.message || ''}
      />
      <Required
        areConditionsEnabled={watch('required')}
        checkboxProps={{ ...register('required') }}
        onOpenConditionsManagement={onOpenConditionsManagement}
      />
    </>
  );
};
