import { useMutation } from '@tanstack/react-query';
import { API_QUERY_KEYS } from 'consts';
import { patchDocumentInvitation } from '../requests/patchDocumentInvitation';

export const usePatchDocumentInvitation = ({
  pathParams: { invitationId },
}: {
  pathParams: { invitationId: string };
}) =>
  useMutation({
    mutationKey: [API_QUERY_KEYS.documentInvitation, { invitationId }],
    mutationFn: (bodyParams: any) =>
      patchDocumentInvitation({ pathParams: { invitationId }, bodyParams }),
  });
