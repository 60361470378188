import isRangeInTable from './isRangeInTable';

/**
 * Is the selection in a table
 */
export default function isSelectionInTable(opts, value) {
  if (!value.selection.start.key) {
    return false;
  }

  return isRangeInTable(opts, value.document, value.selection);
}
