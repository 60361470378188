export const TableCell = ({ children }) => (
  <td
    style={{
      border: '1px solid #ddd',
      padding: '0.5rem',
      textAlign: 'center',
      verticalAlign: 'top',
    }}
  >
    {children}
  </td>
);
