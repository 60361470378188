import { createCell } from '../utils';

/*
 * Ensure each row has the same number of columns.
 */
export default function validateNode(opts) {
  const isRow = node => node.type === opts.typeRow;
  const isCell = node => node.type === opts.typeCell;
  const countCells = row => row.nodes.count(isCell);

  return (node, editor, next) => {
    if (node.type !== opts.typeTable) {
      return next();
    }

    const rows = node.nodes.filter(isRow);
    const maxColumns = Math.max(
      1, // Minimum 1 column
      rows.map(countCells).max(),
    );
    const rowsMissingColumns = rows.filter(row => countCells(row) < maxColumns);

    if (rowsMissingColumns.isEmpty()) {
      return next();
    }

    return () => {
      rowsMissingColumns.forEach(row => {
        const numberOfCellsToAdd = maxColumns - row.nodes.size;
        const cells = Array.from({ length: numberOfCellsToAdd }).map(() =>
          createCell(opts),
        );
        editor.withoutNormalizing(() => {
          cells.forEach(cell =>
            editor.insertNodeByKey(row.key, row.nodes.size, cell),
          );
        });
      });
    };
  };
}
