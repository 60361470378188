import { CookiesCategoryType } from 'types';

export const COOKIES_CATEGORIES: CookiesCategoryType[] = [
  {
    code: 'mandatory',
    canBeDisabled: false,
    cookies: [{ name: 'cf_clearance' }],
  },
  {
    code: 'analytics',
    canBeDisabled: true,
    cookies: [
      {
        name: '_ga',
      },
      {
        name: '_ga_49ZS50KT8K', // TODO: consider fetching it dynamically from GTM or at least hardcode it in .env
      },
    ],
  },
];
