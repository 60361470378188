import { useEffect, useImperativeHandle } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { Hint } from '../Hint';
import { Required } from '../Required';
import { Label } from '../Label';
import { InitialListValue } from '../InitialListValue';
import { useGetZodTabsVariableSchema } from './hooks';
import { Options } from './Options';
import { ListSupport } from '../ListSupport';

export const TabsVariable = ({
  fieldsInstanceRef,
  initialValues,
  onOpenConditionsManagement,
}) => {
  const schema = useGetZodTabsVariableSchema();

  const {
    control,
    formState: { errors: formErrors },
    register,
    setError,
    clearErrors,
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
      ? { ...initialValues, _initialValueId: null }
      : {
          label: '',
          hint: '',
          options: [{ value: '' }],
          initialValue: '',
          _initialValueId: '',
          required: false,
        },
  });
  const optionsFieldArray = useFieldArray({
    control,
    name: 'options',
  });

  const optionsFields = optionsFieldArray.fields;
  const options = watch('options');

  useImperativeHandle(fieldsInstanceRef, () => ({
    getValues: () => {
      const { _initialValueId, ...values } = getValues();

      return {
        ...values,
        initialValue: _initialValueId
          ? options[optionsFields.findIndex(({ id }) => id === _initialValueId)]
              .value
          : undefined,
      };
    },
    schema,
    clearErrors,
    setError,
  }));

  useEffect(() => {
    const { _initialValueId, initialValue } = getValues();

    if (_initialValueId === null) {
      setValue(
        '_initialValueId',
        initialValue
          ? optionsFields.find(({ value }) => value === initialValue).id
          : '',
      );
    }
  }, []);

  useEffect(() => {
    /* clear _initialValueId after delete option */
    if (!optionsFields.some(({ id }) => id === getValues()._initialValueId)) {
      setValue('_initialValueId', '');
    }
  }, [options]);

  return (
    <>
      <Label
        inputProps={{ ...register('label') }}
        error={formErrors?.label?.message || ''}
      />
      <Hint
        inputProps={{ ...register('hint') }}
        error={formErrors?.hint?.message || ''}
      />
      <Options
        optionsFieldArray={optionsFieldArray}
        register={register}
        error={formErrors?.options?.message || ''}
        clearOptionsError={() => {
          clearErrors('options');
        }}
      />
      <InitialListValue
        control={control}
        data={options
          .filter(({ value }) => !isEmpty(value))
          .map(({ value }, index) => ({
            value: optionsFields[index].id,
            label: value,
          }))}
        error={formErrors?.initialValue?.message || ''}
      />
      <ListSupport
        checkboxProps={{ ...register('list') }}
        error={formErrors?.list?.message || ''}
      />
      <Required
        areConditionsEnabled={watch('required')}
        checkboxProps={{ ...register('required') }}
        onOpenConditionsManagement={onOpenConditionsManagement}
      />
    </>
  );
};
