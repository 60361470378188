import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { TemplateType } from 'types';
import { useTemplateStore } from 'stores';
import { getTemplate } from '../requests';
import { GetTemplateRequestType } from '../types';
import { templateMigrator } from '../helpers';

export const useGetTemplate = (
  {
    pathParams: { templateId },
  }: {
    pathParams: GetTemplateRequestType['pathParams'];
  },
  options?: {
    enabled?: boolean;
  },
) => {
  const setTemplate = useTemplateStore(state => state.setTemplate);

  return useQuery({
    queryKey: [
      API_QUERY_KEYS.template,
      {
        templateId: Number(templateId),
      },
    ],
    queryFn: () =>
      getTemplate({
        pathParams: { templateId },
      }).then(templateMigrator),
    ...options,
    onSuccess: (data: TemplateType) => {
      setTemplate(data);
    },
  });
};
