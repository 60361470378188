const companySchemaKeys = [
  'companyType',
  'email',
  'address.city',
  'address.zipCode',
  'address.street',
  'contactAddress.city',
  'contactAddress.zipCode',
  'contactAddress.street',
  'differentContactAddress',
  'representatives',
  'pesel',
  'nip',
  'regon',
  'companyName',
  'name',
  'surname',
  'identityDoc',
  'motherName',
  'fatherName',
  'taxOffice',
  'bankAccount',
  'internalName',
  'internalNumber',
  'phone',
  'partners',
  'krs',
  'registryCourt',
  'shareCapital',
];

const companyFields = companySchemaKeys.filter(
  v => !['partners', 'id', 'contrator'].includes(v),
);

export const getPredefinedVariables = t =>
  [
    {
      name: 'meta.contractName',
      label: t('templates:variablesMeta.contractName'),
    },
    {
      name: 'meta.contractNumber',
      label: t('templates:variablesMeta.contractNumber'),
    },
    {
      name: 'meta.contractorName',
      label: t('templates:variablesMeta.contractorName'),
    },
    { name: 'meta.name', label: t('templates:variablesMeta.name') },
    { name: 'meta.surname', label: t('templates:variablesMeta.surname') },
    { name: 'meta.email', label: t('templates:variablesMeta.email') },
    { name: 'loop.index', label: t('templates:variablesMeta.loopIndex') },
  ].map(v => ({ ...v, predefined: true }));

export const getVariableOptions = (
  variables,
  baseVariables = [],
  t = () => '',
) => {
  const metaVariables = getPredefinedVariables(t);
  const filterNonConditional = v => v.type !== 'conditional';

  const mapBaseVariables = v => ({ ...v, name: `base.${v.name}` });

  const mapCompanyFields = v =>
    v.type === 'company'
      ? [v, ...companyFields.map(field => ({ name: `${v.name}.${field}` }))]
      : v;

  const ensureLabel = v => ({
    ...v,
    label: v.label || v.name,
  });

  const filteredVariables = variables.filter(filterNonConditional);
  const baseVariableOptions = baseVariables
    .filter(filterNonConditional)
    .map(mapBaseVariables);

  return [...filteredVariables, ...metaVariables, ...baseVariableOptions]
    .map(mapCompanyFields)
    .flat()
    .map(ensureLabel);
};

const checkWhiteList = (allowedVariables, type) => {
  return allowedVariables ? allowedVariables.includes(type) : true;
};

const checkBlackList = (disallowedVariables, type) => {
  return disallowedVariables ? !disallowedVariables.includes(type) : true;
};

export const getAvailableVariables = (
  variables,
  allowedVariables,
  disallowedVariables,
) => {
  return variables.filter(
    ({ type }) =>
      checkWhiteList(allowedVariables, type) &&
      checkBlackList(disallowedVariables, type),
  );
};
