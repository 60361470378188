import { Select, TextInput } from '@mantine/core';
import { VARIABLE_TYPES } from '../../../consts';
import { DatePickerSingleInput } from 'components/shared/Inputs/DatePickerSingleInput';
import { useTranslation } from 'react-i18next';

export const OtherValue = ({
  internalOtherValue,
  setInternalOtherValue,
  type,
  options,
  changeOtherValue,
}) => {
  const { t } = useTranslation('templates');

  if ([VARIABLE_TYPES.select, VARIABLE_TYPES.tabs].includes(type)) {
    return (
      <Select
        label={t('templates:value')}
        placeholder={t('templates:value')}
        value={internalOtherValue}
        data={options.map(option => ({
          ...option,
          label: option.value,
        }))}
        error={null}
        withinPortal
        onChange={value => {
          setInternalOtherValue(value);
        }}
        onBlur={e => {
          changeOtherValue(e.target.value);
        }}
      />
    );
  }

  if (type === VARIABLE_TYPES.date) {
    return (
      <DatePickerSingleInput
        label={t('templates:value')}
        placeholder={t('templates:value')}
        value={internalOtherValue ? new Date(internalOtherValue) : null}
        onChange={value => {
          setInternalOtherValue(new Date(value));
          changeOtherValue(new Date(value));
        }}
      />
    );
  }

  return (
    <TextInput
      label={t('templates:value')}
      placeholder={t('templates:value')}
      value={internalOtherValue}
      onChange={e => {
        setInternalOtherValue(e.target.value);
      }}
      onBlur={e => {
        changeOtherValue(e.target.value);
      }}
    />
  );
};
