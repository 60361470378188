import { apiAuthClient } from 'api/helpers';
import { PostCompanyRequestType, PostCompanyResponseType } from '../types';

export const postCompany = async ({
  pathParams: { boardId },
  bodyParams,
}: PostCompanyRequestType) => {
  const url = `/boards/${boardId}/company`;

  const { data } = await apiAuthClient.post<PostCompanyResponseType>(url, {
    ...bodyParams,
  });

  return data.data;
};
