import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Paper, Popover } from '@mantine/core';

import { CreateIcon, EditIcon } from 'icons';
import { getInputVariableValue } from 'helpers';
import { NotFound, TextInputSearch } from 'components/shared';
import {
  EditorContext,
  ForceUpdateContext,
} from 'legacy/TemplateCreatorEditor/context';
import { VariableAddModal } from 'legacy/TemplateCreatorEditor/VariableAddModal';
import { VariableButton } from 'legacy/TemplateCreatorEditor/VariableButton';
import { VariableEditModal } from 'legacy/TemplateCreatorEditor/VariableEditModal';
import { getAvailableVariables, getVariableOptions } from '../utils';

export const VariableMapping = ({
  children,
  node,
  defaultType,
  allowedTypes,
  baseVariables = [],
  allowNullSelection = false,
}) => {
  const { t } = useTranslation(['common', 'templates']);
  const editor = useContext(EditorContext);

  const forceUpdate = useContext(ForceUpdateContext);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isVariableAddModalOpen, setIsVariableAddModalOpen] = useState(false);
  const [isVariableEditModalOpen, setIsVariableEditModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const nodeVariable = node.data.get('variable');

  const variableOptions = getVariableOptions(
    [...editor.getVariables()],
    baseVariables,
    t,
  );

  const filteredVariables = getAvailableVariables(
    variableOptions,
    allowedTypes,
  ).filter(({ name }) =>
    name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const options = [
    ...(allowNullSelection
      ? [{ predefined: true, label: t('templates:chooseVariable') }]
      : []),
    ...filteredVariables,
  ];

  return (
    <>
      <Popover
        opened={isPopoverOpen}
        exitTransitionDuration={0}
        withinPortal
        onChange={open => {
          setIsPopoverOpen(open);

          if (!open) {
            setIsVariableAddModalOpen(false);
            setIsVariableEditModalOpen(false);
            setSearchValue('');
          }
        }}
      >
        <Popover.Target
          onClick={e => {
            e.stopPropagation();

            if (
              getAvailableVariables(editor.getVariables(), allowedTypes)
                .length === 0
            ) {
              setIsVariableAddModalOpen(true);
            } else {
              setIsPopoverOpen(open => !open);
            }
          }}
        >
          {children}
        </Popover.Target>
        <Popover.Dropdown sx={{ padding: 0 }}>
          <Paper
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <TextInputSearch
              sx={theme => ({
                marginBottom: theme.other.spacing(1.25),
              })}
              autoFocus
              value={searchValue}
              onChange={e => {
                setSearchValue(getInputVariableValue(e.target.value));
              }}
            />
            <Box
              sx={theme => ({
                marginBottom: theme.other.spacing(3),
                paddingTop: theme.other.spacing(1),
                maxWidth: 306,
                maxHeight: 214,
                overflowY: 'auto',
              })}
            >
              {options.length > 0 ? (
                <Box
                  sx={theme => ({
                    padding: theme.other.spacing(0, 1),
                  })}
                  component="ul"
                >
                  {options.map(({ predefined, label, name }) => (
                    <Box
                      sx={theme => ({
                        marginBottom: theme.other.spacing(2),
                        textAlign: 'left',
                      })}
                      component="li"
                      key={name || 'no-selection'}
                    >
                      <VariableButton
                        onClick={() => {
                          editor.updateNodeData(node, { variable: name || '' });

                          setIsPopoverOpen(false);
                        }}
                      >
                        {predefined ? label : name}
                      </VariableButton>
                    </Box>
                  ))}
                </Box>
              ) : (
                <NotFound
                  sx={theme => ({
                    marginBottom: theme.other.spacing(2),
                  })}
                />
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: nodeVariable ? 'flex-end' : 'center',
              }}
            >
              {Boolean(nodeVariable) && (
                <Button
                  sx={theme => ({
                    marginRight: theme.other.spacing(1),
                    fontSize: theme.fontSizes.lg,
                  })}
                  size="xs"
                  variant="default"
                  onClick={() => {
                    setIsPopoverOpen(false);
                    setIsVariableEditModalOpen(true);
                  }}
                >
                  <EditIcon
                    sx={theme => ({
                      marginRight: theme.other.spacing(1),
                    })}
                  />
                  {t('common:edit')}
                </Button>
              )}
              <Button
                sx={theme => ({
                  fontSize: theme.fontSizes.lg,
                })}
                size="xs"
                color="green"
                onClick={() => {
                  setIsPopoverOpen(false);
                  setIsVariableAddModalOpen(true);
                }}
              >
                <CreateIcon
                  sx={theme => ({
                    marginRight: theme.other.spacing(1),
                  })}
                />
                {t('templates:templateCreatorVariables.createAndMap')}
              </Button>
            </Box>
          </Paper>
        </Popover.Dropdown>
      </Popover>
      <VariableAddModal
        isOpen={isVariableAddModalOpen}
        onClose={() => {
          setIsVariableAddModalOpen(false);
        }}
        addVariableCallback={({ name }) => {
          editor.updateNodeData(node, { variable: name });
        }}
        defaultType={defaultType}
        allowedTypes={allowedTypes}
      />
      <VariableEditModal
        isOpen={isVariableEditModalOpen}
        onClose={() => {
          setIsVariableEditModalOpen(false);
        }}
        variableName={nodeVariable}
        editVariableCallback={({ name }) => {
          editor.updateNodeData(node, { variable: name });
          forceUpdate();
        }}
        allowedTypes={allowedTypes}
      />
    </>
  );
};
