import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetCurrentBoard } from 'api/boards';
import { useGetTemplate } from 'api/templates';
import { URLS } from 'consts';
import { getHasHateoasAccess } from 'helpers';
import { Breadcrumbs, DataWrapper, MetaTitle } from 'components/shared';

import { Summary } from './Summary';
import { TemplateEditForm } from './TemplateEditForm';
import { TemplatePreviewModal } from './TemplatePreviewModal/TemplatePreviewModal';

export const TemplateEdit = () => {
  const { t } = useTranslation('templates');

  const { templateId } = useParams() as {
    templateId: string;
  };

  const [isClosingSummary, setIsClosingSummary] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const { board, isCurrentBoardError, isCurrentBoardLoading } =
    useGetCurrentBoard();

  const hasBoardTemplateFeature = board?.canAccessTemplateCreator || false;

  const {
    data: templateData,
    isError: isTemplateError,
    isLoading: isTemplateLoading,
  } = useGetTemplate(
    { pathParams: { templateId } },
    { enabled: hasBoardTemplateFeature },
  );

  const hasTemplateUpdateAccess = getHasHateoasAccess(
    'update',
    templateData?.__links,
  );

  return (
    <>
      <MetaTitle value={t('templates:templates')} />
      <DataWrapper
        data={templateData}
        isError={isCurrentBoardError || isTemplateError}
        isLoading={isCurrentBoardLoading || isTemplateLoading}
        isNoAccess={!hasBoardTemplateFeature && !isCurrentBoardLoading}
        errorMargin="xl"
      >
        {template => (
          <>
            <MetaTitle
              value={`${template.name} - ${t('templates:templates')}`}
            />
            <Breadcrumbs
              sx={theme => ({
                marginBottom: theme.other.spacing(3),
              })}
              items={[
                { name: t('templates:templates'), url: URLS.templates },
                { name: template.name, url: '#' },
              ]}
            />
            {!isEditFormOpen && (
              <Summary
                template={template}
                isLoading={isClosingSummary}
                onEdit={
                  hasTemplateUpdateAccess
                    ? () => {
                        setIsClosingSummary(true);

                        setTimeout(() => {
                          setIsClosingSummary(false);
                          setIsEditFormOpen(true);
                        }, 1000);
                      }
                    : undefined
                }
              />
            )}
            <TemplateEditForm
              isEditFormOpen={isEditFormOpen}
              template={template}
              onPreviewOpen={() => setIsPreviewModalOpen(true)}
            />
            <TemplatePreviewModal
              isOpen={isPreviewModalOpen}
              onClose={() => setIsPreviewModalOpen(false)}
            />
          </>
        )}
      </DataWrapper>
    </>
  );
};
