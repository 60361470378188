import { getVariableConditionsCopy } from './getVariableConditionsCopy';

export const getVariableConditionsWithUpdatedItem = (
  variableConditions,
  itemPath,
  updatedValues,
) => {
  const copiedObj = getVariableConditionsCopy(variableConditions);

  if (itemPath.length === 0) {
    return {
      ...copiedObj,
      ...updatedValues,
    };
  }

  return itemPath.reduce((obj, key, index) => {
    if (index !== itemPath.length - 1) {
      return obj[key];
    }

    obj[key] = Array.isArray(obj[key])
      ? [...obj[key], ...updatedValues]
      : { ...obj[key], ...updatedValues };

    return copiedObj;
  }, copiedObj);
};
