import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Paper } from '@mantine/core';

import { CreateIcon, EditIcon } from 'icons';
import { getInputVariableValue } from 'helpers';
import { NotFound, TextInputSearch } from 'components/shared';
import { EditorContext } from 'legacy/TemplateCreatorEditor/context';
import { VariableButton } from 'legacy/TemplateCreatorEditor/VariableButton';
import { getAvailableVariables } from '../../../utils';

export const VariableMapping = ({
  node,
  allowedTypes,
  allowNullSelection = false,
  onClose,
  onEdit = () => null,
  onCreateNew,
}) => {
  const { t } = useTranslation(['common', 'templates']);
  const editor = useContext(EditorContext);

  const [searchValue, setSearchValue] = useState('');

  const nodeVariable = node.data.get('variable');

  const filteredVariables = getAvailableVariables(
    editor.getVariables(),
    allowedTypes,
  ).filter(({ name }) =>
    name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const options = [
    ...(allowNullSelection
      ? [{ predefined: true, label: t('templates:chooseVariable') }]
      : []),
    ...filteredVariables,
  ];

  return (
    <Paper
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <TextInputSearch
        sx={theme => ({
          marginBottom: theme.other.spacing(1.25),
        })}
        autoFocus
        value={searchValue}
        onChange={e => {
          setSearchValue(getInputVariableValue(e.target.value));
        }}
      />
      <Box
        sx={theme => ({
          marginBottom: theme.other.spacing(3),
          paddingTop: theme.other.spacing(1),
          maxWidth: 306,
          maxHeight: 214,
          overflowY: 'auto',
        })}
      >
        {options.length > 0 ? (
          <Box
            sx={theme => ({
              padding: theme.other.spacing(0, 1),
            })}
            component="ul"
          >
            {options.map(({ predefined, label, name }) => (
              <Box
                sx={theme => ({
                  marginBottom: theme.other.spacing(2),
                  textAlign: 'left',
                })}
                component="li"
                key={name || 'no-selection'}
              >
                <VariableButton
                  onClick={() => {
                    editor.updateNodeData(node, { variable: name || '' });
                    onClose();
                  }}
                >
                  {predefined ? label : name}
                </VariableButton>
              </Box>
            ))}
          </Box>
        ) : (
          <NotFound
            sx={theme => ({
              marginBottom: theme.other.spacing(2),
            })}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: nodeVariable ? 'flex-end' : 'center',
        }}
      >
        {Boolean(nodeVariable) && (
          <Button
            sx={theme => ({
              marginRight: theme.other.spacing(1),
              fontSize: theme.fontSizes.lg,
            })}
            size="xs"
            variant="default"
            onClick={() => {
              onEdit();
            }}
          >
            <EditIcon
              sx={theme => ({
                marginRight: theme.other.spacing(1),
              })}
            />
            {t('common:edit')}
          </Button>
        )}
        <Button
          sx={theme => ({
            fontSize: theme.fontSizes.lg,
          })}
          size="xs"
          color="green"
          onClick={() => {
            onCreateNew();
          }}
        >
          <CreateIcon
            sx={theme => ({
              marginRight: theme.other.spacing(1),
            })}
          />
          {t('templates:templateCreatorVariables.createAndMap')}
        </Button>
      </Box>
    </Paper>
  );
};
