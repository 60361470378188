import { TablePosition } from '../utils';

import clearCell from './clearCell';

/**
 * Remove the row associated to a given key in a table.
 * Clear thw row if last remaining row
 */
export default function removeRowByKey(opts, change, key) {
  const { value } = change;
  const pos = TablePosition.create(opts, value.document, key);

  // Update table by removing the row
  if (pos.getHeight() > 1) {
    change.removeNodeByKey(key);
  } else {
    // If last remaining row, clear it instead
    pos.row.nodes.forEach(cell => {
      cell.nodes.forEach(() => clearCell(opts, change, cell));
    });
  }

  return change;
}
