export const getListDepthLevel = (document, node) => {
  const parent = document.getParent(node.key);

  if (parent) {
    const isList = parent.type === 'listItem' ? 1 : 0;
    return getListDepthLevel(document, parent) + isList;
  }

  return 0;
};

export const getListStyleType = (document, node) => {
  const depthLevel = getListDepthLevel(document, node);

  switch (depthLevel) {
    case 0:
      return 'decimal';
    case 1:
      return 'lower-alpha';
    default:
      return 'disc';
  }
};
