import { COOKIES_CATEGORIES } from 'consts';

import { CookiesSettingsType } from 'types';

export const initialCookiesSettings = COOKIES_CATEGORIES.reduce(
  (acc: CookiesSettingsType, category) => {
    const newAcc = { ...acc };
    if (category.canBeDisabled) newAcc[category.code] = true;
    return newAcc;
  },
  {} as CookiesSettingsType,
);
