import { Block } from 'slate';

export const companyInitialValue = {
  krs: null,
  nip: null,
  name: null,
  email: null,
  pesel: null,
  phone: null,
  regon: null,
  groups: [],
  address: {
    city: null,
    street: null,
    zipCode: null,
  },
  surname: null,
  partners: [],
  contractor: null,
  taxOffice: null,
  fatherName: null,
  motherName: null,
  bankAccount: null,
  companyName: null,
  companyType: null,
  identityDoc: null,
  paidCapital: null,
  internalName: null,
  shareCapital: null,
  registryCourt: null,
  contactAddress: { city: null, street: null, zipCode: null },
  internalNumber: null,
  representatives: [],
  differentContactAddress: false,
};
const getCompanyBlocks = (variableName, edited) => ({
  object: 'block',
  type: 'companyWrapper',
  data: { company: variableName, edited },
  nodes: [
    {
      object: 'block',
      type: 'paragraph',
      data: { writeOnly: true },
      nodes: [
        {
          object: 'text',
          leaves: [{ object: 'leaf', text: ' ', marks: [] }],
        },
        {
          object: 'inline',
          type: 'input',
          data: { variable: variableName },
          nodes: [
            {
              object: 'text',
              leaves: [{ object: 'leaf', text: '', marks: [] }],
            },
          ],
        },
        {
          object: 'text',
          leaves: [{ object: 'leaf', text: ' ', marks: [] }],
        },
      ],
    },
    {
      object: 'block',
      type: 'conditionalWrapper',
      data: {
        conditions: {
          conditions: [
            {
              variableName: `${variableName}.companyType`,
              comparator: 'isEqual',
              otherValue: 'NATURAL_PERSON',
            },
          ],
          logicalOperator: 'and',
        },
      },
      nodes: [
        {
          object: 'block',
          type: 'paragraph',
          data: {},
          nodes: [
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: '',
                  marks: [],
                },
              ],
            },
            {
              object: 'inline',
              type: 'variable',
              data: {
                variable: `${variableName}.name`,
              },
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: '',
                      marks: [],
                    },
                  ],
                },
              ],
            },
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: ' ',
                  marks: [],
                },
              ],
            },
            {
              object: 'inline',
              type: 'variable',
              data: {
                variable: `${variableName}.surname`,
              },
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: '',
                      marks: [],
                    },
                  ],
                },
              ],
            },
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: ',  ',
                  marks: [],
                },
              ],
            },
            {
              object: 'inline',
              type: 'variable',
              data: {
                variable: `${variableName}.companyType`,
              },
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: '',
                      marks: [],
                    },
                  ],
                },
              ],
            },
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: ', adres zamieszkania: ',
                  marks: [],
                },
              ],
            },
            {
              object: 'inline',
              type: 'variable',
              data: {
                variable: `${variableName}.address.city`,
              },
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: '',
                      marks: [],
                    },
                  ],
                },
              ],
            },
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: ',  ',
                  marks: [],
                },
              ],
            },
            {
              object: 'inline',
              type: 'variable',
              data: {
                variable: `${variableName}.address.street`,
              },
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: '',
                      marks: [],
                    },
                  ],
                },
              ],
            },
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: ', ',
                  marks: [],
                },
              ],
            },
            {
              object: 'inline',
              type: 'variable',
              data: {
                variable: `${variableName}.address.zipCode`,
              },
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: '',
                      marks: [],
                    },
                  ],
                },
              ],
            },
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: ', PESEL: ',
                  marks: [],
                },
              ],
            },
            {
              object: 'inline',
              type: 'variable',
              data: {
                variable: `${variableName}.pesel`,
              },
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: '',
                      marks: [],
                    },
                  ],
                },
              ],
            },
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: '',
                  marks: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      object: 'block',
      type: 'conditionalWrapper',
      data: {
        conditions: {
          conditions: [
            {
              variableName: `${variableName}.companyType`,
              comparator: 'isNotEqual',
              otherValue: 'NATURAL_PERSON',
            },
          ],
          logicalOperator: 'and',
        },
      },
      nodes: [
        {
          object: 'block',
          type: 'paragraph',
          data: {},
          nodes: [
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: '',
                  marks: [],
                },
              ],
            },
            {
              object: 'inline',
              type: 'variable',
              data: {
                variable: `${variableName}.companyName`,
              },
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: '',
                      marks: [],
                    },
                  ],
                },
              ],
            },
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: ',  ',
                  marks: [],
                },
              ],
            },
            {
              object: 'inline',
              type: 'conditionalWrapper',
              data: {
                conditions: {
                  conditions: [
                    {
                      variableName: `${variableName}.companyType`,
                      comparator: 'isNotEqual',
                      otherValue: 'OTHER',
                    },
                  ],
                  logicalOperator: 'and',
                },
              },
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: ' ',
                      marks: [],
                    },
                  ],
                },
                {
                  object: 'inline',
                  type: 'variable',
                  data: {
                    variable: `${variableName}.companyType`,
                  },
                  nodes: [
                    {
                      object: 'text',
                      leaves: [
                        {
                          object: 'leaf',
                          text: '',
                          marks: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: ', ',
                      marks: [],
                    },
                  ],
                },
              ],
            },
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: ' siedziba:  ',
                  marks: [],
                },
              ],
            },
            {
              object: 'inline',
              type: 'variable',
              data: {
                variable: `${variableName}.address.city`,
              },
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: '',
                      marks: [],
                    },
                  ],
                },
              ],
            },
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: ', ',
                  marks: [],
                },
              ],
            },
            {
              object: 'inline',
              type: 'variable',
              data: {
                variable: `${variableName}.address.street`,
              },
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: '',
                      marks: [],
                    },
                  ],
                },
              ],
            },
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: ', ',
                  marks: [],
                },
              ],
            },
            {
              object: 'inline',
              type: 'variable',
              data: {
                variable: `${variableName}.address.zipCode`,
              },
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: '',
                      marks: [],
                    },
                  ],
                },
              ],
            },
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: ',',
                  marks: [],
                },
              ],
            },
            {
              object: 'inline',
              type: 'conditionalWrapper',
              data: {
                conditions: {
                  conditions: [
                    {
                      variableName: `${variableName}.krs`,
                      comparator: 'isDefined',
                      otherValue: null,
                    },
                  ],
                  logicalOperator: 'and',
                },
              },
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: ' zarejestrowaną w rejestrze przedsiębiorców Krajowego Rejestru Sądowego pod numerem KRS ',
                      marks: [],
                    },
                  ],
                },
                {
                  object: 'inline',
                  type: 'variable',
                  data: {
                    variable: `${variableName}.krs`,
                  },
                  nodes: [
                    {
                      object: 'text',
                      leaves: [
                        {
                          object: 'leaf',
                          text: '',
                          marks: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: ', ',
                      marks: [],
                    },
                  ],
                },
                {
                  object: 'inline',
                  type: 'conditionalWrapper',
                  data: {
                    conditions: {
                      conditions: [
                        {
                          variableName: `${variableName}.registryCourt`,
                          comparator: 'isDefined',
                          otherValue: null,
                        },
                      ],
                      logicalOperator: 'and',
                    },
                  },
                  nodes: [
                    {
                      object: 'text',
                      leaves: [
                        {
                          object: 'leaf',
                          text: ' w ',
                          marks: [],
                        },
                      ],
                    },
                    {
                      object: 'inline',
                      type: 'variable',
                      data: {
                        variable: `${variableName}.registryCourt`,
                      },
                      nodes: [
                        {
                          object: 'text',
                          leaves: [
                            {
                              object: 'leaf',
                              text: '',
                              marks: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      object: 'text',
                      leaves: [
                        {
                          object: 'leaf',
                          text: ', ',
                          marks: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: ' ',
                      marks: [],
                    },
                  ],
                },
              ],
            },
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: ' ',
                  marks: [],
                },
              ],
            },
          ],
        },
        {
          object: 'block',
          type: 'conditionalWrapper',
          data: {
            conditions: {
              conditions: [
                {
                  variableName: `${variableName}.shareCapital`,
                  comparator: 'isDefined',
                  otherValue: null,
                },
              ],
              logicalOperator: 'and',
            },
          },
          nodes: [
            {
              object: 'block',
              type: 'paragraph',
              data: {},
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: 'kapitał zakładowy: ',
                      marks: [],
                    },
                  ],
                },
                {
                  object: 'inline',
                  type: 'variable',
                  data: {
                    variable: `${variableName}.shareCapital`,
                  },
                  nodes: [
                    {
                      object: 'text',
                      leaves: [
                        {
                          object: 'leaf',
                          text: '',
                          marks: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: '',
                      marks: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          object: 'block',
          type: 'paragraph',
          data: {},
          nodes: [
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: 'NIP: ',
                  marks: [],
                },
              ],
            },
            {
              object: 'inline',
              type: 'variable',
              data: {
                variable: `${variableName}.nip`,
              },
              nodes: [
                {
                  object: 'text',
                  leaves: [
                    {
                      object: 'leaf',
                      text: '',
                      marks: [],
                    },
                  ],
                },
              ],
            },
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: '',
                  marks: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});

export const getCompanySnippet = (variableNames, edited) =>
  Block.create({
    type: 'companiesWrapper',
    data: { companies: variableNames, edited },
    nodes: variableNames.reduce((memo, variableName, i) => {
      memo.push(
        {
          object: 'block',
          type: 'paragraph',
          data: {},
        },
        {
          object: 'block',
          type: 'paragraph',
          data: {},
          nodes: [
            {
              object: 'text',
              leaves: [
                {
                  object: 'leaf',
                  text: i === 0 ? 'pomiędzy:' : 'a:',
                  marks: [],
                },
              ],
            },
          ],
        },
        {
          object: 'block',
          type: 'paragraph',
          data: {},
        },
      );
      memo.push(getCompanyBlocks(variableName, edited));
      return memo;
    }, []),
  });

export const updateCompanyVariables = (
  editor,
  documentData,
  numberOfParties,
) => {
  const allCompanies = [...Array(4)].map((_, i) => `company${i + 1}`);
  const companiesToAdd = [...Array(numberOfParties)].map(
    (_, i) => `company${i + 1}`,
  );

  const newData = {
    ...documentData,
    variables: [
      ...companiesToAdd.map(v => ({
        required: true,
        hint: '',
        label: '',
        placeholder: '',
        type: 'company',
        currency: '',
        options: [],
        name: v,
      })),
      ...(documentData.variables || []).filter(
        v => !(v.type === 'company' && allCompanies.includes(v.name)),
      ),
    ],
  };

  editor.setNodeByKey(editor.value.document.key, {
    data: newData,
  });
};

export const updateCompanyWrapper = (
  node,
  editor,
  documentData,
  numberOfParties,
) => {
  updateCompanyVariables(editor, documentData, numberOfParties);

  const allCompanies = [...Array(numberOfParties)].map(
    (_, i) => `company${i + 1}`,
  );

  editor.replaceNodeByKey(node.key, getCompanySnippet(allCompanies, false));
};
