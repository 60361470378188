import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';

import { Modal } from 'components/shared';
import { Props as ModalProps } from 'components/shared/Modals/Modal';

type DeleteVariablesModalProps = {
  onClose: () => void;
  onDelete: () => void;
} & Pick<ModalProps, 'isOpen' | 'defaultButtonAction'>;

export const DeleteVariablesModal = ({
  isOpen,
  onClose,
  onDelete,
}: DeleteVariablesModalProps) => {
  const { t } = useTranslation(['common', 'templates']);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('templates:deleteVariablesTitle')}
      defaultButtonAction={onClose}
      redButtonText={t('common:yesDelete')}
      redButtonAction={onDelete}
      size="md"
    >
      <Text size="sm">{t('templates:deleteVariablesDescription')}</Text>
    </Modal>
  );
};
