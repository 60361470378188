import { Box } from '@mantine/core';

import { IconPropsType } from './types';

export const XIcon = ({ size = 12, sx, ...props }: IconPropsType) => (
  <Box
    component="svg"
    sx={sx}
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke="#E63535"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m1 1.02 10 10m-10 0 10-10"
    />
  </Box>
);
