export const plLocale = {
  useLongScale: false,
  baseSeparator: ' ',
  unitSeparator: '',
  base: {
    0: 'zero',
    1: 'jeden',
    2: 'dwa',
    3: 'trzy',
    4: 'cztery',
    5: 'pięć',
    6: 'sześć',
    7: 'siedem',
    8: 'osiem',
    9: 'dziewięć',
    10: 'dziesięć',
    11: 'jedenaście',
    12: 'dwanaście',
    13: 'trzynaście',
    14: 'czternaście',
    15: 'piętnaście',
    16: 'szesnaście',
    17: 'siedemnaście',
    18: 'osiemnaście',
    19: 'dziewietnaście',
    20: 'dwadzieścia',
    30: 'trzydzieści',
    40: 'czterdzieści',
    50: 'pięćdziesiąt',
    60: 'sześćdziesiąt',
    70: 'siedemdziesiąt',
    80: 'osiemdziesiąt',
    90: 'dziewięćdziesiąt',
    100: 'sto',
    200: 'dwieście',
    300: 'trzysta',
    400: 'czterysta',
    500: 'pięćset',
    600: 'sześćset',
    700: 'siedemset',
    800: 'osiemset',
    900: 'dziewięćset',
  },
  units: [
    {
      useBaseInstead: true,
      useBaseException: [],
    },
    {
      singular: 'tysiąc',
      few: 'tysiące',
      plural: 'tysięcy',
      useFewEnding: true,
      avoidEndingRules: [
        12, 13, 14, 15, 112, 113, 114, 115, 212, 213, 214, 215, 312, 313, 314,
        315, 412, 413, 414, 415, 512, 513, 514, 515, 612, 613, 614, 615, 712,
        713, 714, 715, 812, 813, 814, 815, 912, 913, 914, 915,
      ],
    },
    {
      singular: 'milion',
      few: 'miliony',
      plural: 'milionów',
      useFewEnding: true,
      avoidEndingRules: [
        12, 13, 14, 15, 112, 113, 114, 115, 212, 213, 214, 215, 312, 313, 314,
        315, 412, 413, 414, 415, 512, 513, 514, 515, 612, 613, 614, 615, 712,
        713, 714, 715, 812, 813, 814, 815, 912, 913, 914, 915,
      ],
    },
    {
      singular: 'miliard',
      few: 'miliardy',
      plural: 'miliardów',
      useFewEnding: true,
      avoidEndingRules: [
        12, 13, 14, 15, 112, 113, 114, 115, 212, 213, 214, 215, 312, 313, 314,
        315, 412, 413, 414, 415, 512, 513, 514, 515, 612, 613, 614, 615, 712,
        713, 714, 715, 812, 813, 814, 815, 912, 913, 914, 915,
      ],
    },
  ],
  unitExceptions: [],
};
