export const isValidNumberMinValue = ({ minValue, maxValue }) => {
  if (minValue == null || maxValue == null) {
    return true;
  }

  const parsedMinValue = Number(minValue);
  const parsedMaxValue = Number(maxValue);

  return parsedMinValue <= parsedMaxValue;
};
