import { MutableRefObject, ReactNode } from 'react';
import { ActionIcon, Box, TextInputProps } from '@mantine/core';

import { CancelIcon } from 'icons';

import { IconBox } from './IconBox';

type Props = {
  isClearable: boolean;
  inputRef: MutableRefObject<HTMLInputElement | null>;
  rightIcon?: ReactNode;
  rightIconAction?: () => void;
  disabled?: TextInputProps['disabled'];
  valueToSet?: unknown;
  setFocusAfterClear?: boolean;
  onClear?: () => void;
};

export const ClearableInputRightSection = ({
  isClearable,
  inputRef,
  rightIcon,
  rightIconAction,
  disabled = false,
  valueToSet = '',
  setFocusAfterClear = true,
  onClear = () => null,
}: Props) => {
  const clearInput = () => {
    if (inputRef.current) {
      const event = new Event('input', { bubbles: true });
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value',
      )!.set;

      nativeInputValueSetter!.call(inputRef.current, valueToSet);
      inputRef.current.dispatchEvent(event);

      onClear();

      if (setFocusAfterClear) {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <IconBox className="ClearableInputRightSection_clearableIconBox">
        {isClearable && (
          <ActionIcon
            tabIndex={-1}
            onTouchStart={clearInput}
            onMouseDown={clearInput}
          >
            <CancelIcon size={16} />
          </ActionIcon>
        )}
      </IconBox>
      {rightIcon && (
        <IconBox>
          {rightIconAction ? (
            <ActionIcon disabled={disabled} onClick={rightIconAction}>
              {rightIcon}
            </ActionIcon>
          ) : (
            rightIcon
          )}
        </IconBox>
      )}
    </Box>
  );
};
