import { LoopWrapper } from './common/LoopWrapper';

const LoopWrapperRow = ({ children, colSpan = 2, ...props }) => (
  <tr {...props}>
    <td colSpan={colSpan}>{children}</td>
  </tr>
);

export const TableRow = ({ obj, template, isFirst, children }) => {
  const loop = obj.data.get('loop');

  if (loop) {
    const dependentVariableNames = obj
      .filterDescendants(v => v.type === 'input')
      .toJS()
      .filter(
        v =>
          !!template.document.data.variables.find(
            variable => variable.name === v.data.variable && variable.list,
          ),
      )
      .map(v => v.data.variable);

    if (dependentVariableNames.length === 0) {
      return <tr>{children}</tr>;
    }

    return (
      <LoopWrapper
        variables={template.document.data.variables}
        ActionsContainer={LoopWrapperRow}
        ActionsContainerProps={{
          colSpan: children?.size,
          style: { textAlign: 'left' },
        }}
        dependentVariableNames={dependentVariableNames}
      >
        <tr
          style={{
            backgroundColor: '#fff',
          }}
        >
          {children}
        </tr>
      </LoopWrapper>
    );
  }

  const style = isFirst
    ? { backgroundColor: '#f5f5f5', fontWeight: 'bold' }
    : { backgroundColor: '#fff' };

  return <tr style={style}>{children}</tr>;
};
