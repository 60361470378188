import { useGetColor } from 'hooks';

import { Box } from '@mantine/core';

import { IconPropsType } from 'icons/types';

export const RemoveRowIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="-3 -4 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={iconColor}
        d="M13.16 12.832c.184.184.299.437.299.713a.982.982 0 0 1-.299.713l-1.414 1.414 1.414 1.414c.184.184.299.437.299.713a.982.982 0 0 1-.299.713.982.982 0 0 1-.713.299.982.982 0 0 1-.713-.3l-1.414-1.413-1.414 1.414a1.009 1.009 0 0 1-.713.299.982.982 0 0 1-.713-.3.982.982 0 0 1-.299-.712c0-.276.104-.518.3-.713l1.413-1.414-1.402-1.403a1.01 1.01 0 0 1-.3-.713c0-.276.104-.517.3-.713a.982.982 0 0 1 .713-.299c.276 0 .517.104.712.3l1.415 1.414 1.414-1.415c.184-.184.437-.299.713-.299.276 0 .517.104.713.3l-.012-.012Z"
      />
      <path
        stroke={iconColor}
        strokeWidth={1.5}
        d="M17 .753a2.25 2.25 0 0 1 2.25 2.25v5a2.25 2.25 0 0 1-2.25 2.25H3a2.25 2.25 0 0 1-2.25-2.25v-5A2.25 2.25 0 0 1 3 .753h14ZM12.75 1.003v10M6.75 1.003v10"
      />
    </Box>
  );
};
