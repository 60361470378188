import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import {
  isValidNumberInitialValue,
  isValidNumberMinValue,
} from '../../helpers';

const TEXT_LIMIT = 255;

export const useGetZodMoneyVariableSchema = () => {
  const { t } = useTranslation(['common', 'templates']);

  return z
    .object({
      initialValue: z.number().nullable(),
      placeholder: z
        .string()
        .trim()
        .max(TEXT_LIMIT, {
          message: t('common:formErrors.maxNumberOfSigns', {
            max: TEXT_LIMIT,
          }),
        }),
      label: z
        .string()
        .trim()
        .max(TEXT_LIMIT, {
          message: t('common:formErrors.maxNumberOfSigns', {
            max: TEXT_LIMIT,
          }),
        }),
      hint: z
        .string()
        .trim()
        .max(TEXT_LIMIT, {
          message: t('common:formErrors.maxNumberOfSigns', {
            max: TEXT_LIMIT,
          }),
        }),
      currency: z
        .string()
        .trim()
        .min(1, { message: t('common:formErrors.required') })
        .max(TEXT_LIMIT, {
          message: t('common:formErrors.maxNumberOfSigns', {
            max: TEXT_LIMIT,
          }),
        }),
      minValue: z.number().nullable(),
      maxValue: z.number().nullable(),
      translateMoneyValue: z.boolean(),
      defaultMoneyTranslation: z.enum(['pl', 'en', 'de']),
      required: z.boolean(),
    })
    .refine(isValidNumberInitialValue, {
      message: t('templates:templateCreatorErrors.notInRange'),
      path: ['initialValue'],
    })
    .refine(isValidNumberMinValue, {
      message: t('templates:templateCreatorErrors.minValueIsGreaterThanMax'),
      path: ['minValue'],
    });
};
