import { EMPTY_GROUP } from 'legacy/TemplateCreatorEditor/consts';

import { getVariableConditionsCopy } from './getVariableConditionsCopy';

export const getVariableConditionsWithoutItem = (
  variableConditions,
  itemPath,
) => {
  if (itemPath.length === 0) {
    return EMPTY_GROUP;
  }

  const copiedObj = getVariableConditionsCopy(variableConditions);
  let objectAtLastLevel = copiedObj;

  itemPath.slice(0, itemPath.length - 1).forEach(objKey => {
    objectAtLastLevel = objectAtLastLevel[objKey];
  });

  objectAtLastLevel.splice(itemPath[itemPath.length - 1], 1);

  if (copiedObj.conditions.length === 0) {
    return EMPTY_GROUP;
  }

  return copiedObj;
};
