import { useCallback, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { Placeholder } from '../Placeholder';
import { Label } from '../Label';
import { Hint } from '../Hint';
import { Required } from '../Required';
import { useGetZodCompanyVariableSchema } from './hooks';
import { InitialCompaniesValue } from '../InitialCompaniesValue';

export const CompanyVariable = ({
  fieldsInstanceRef,
  initialValues,
  onOpenConditionsManagement,
}) => {
  const schema = useGetZodCompanyVariableSchema();

  const {
    formState: { errors: formErrors },
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues || {
      placeholder: '',
      label: '',
      hint: '',
      initialValue: null,
      required: false,
    },
  });

  useImperativeHandle(fieldsInstanceRef, () => ({
    getValues,
    schema,
    clearErrors,
    setError,
  }));

  const handleSetInitialValue = useCallback(
    company => {
      setValue('initialValue', company);
      trigger('initialValue');
    },
    [setValue, trigger],
  );

  const selectedCompany = watch('initialValue');

  return (
    <>
      <InitialCompaniesValue
        value={selectedCompany}
        setValue={handleSetInitialValue}
        error={formErrors?.initialValue?.message || ''}
      />
      <Placeholder
        inputProps={{ ...register('placeholder') }}
        error={formErrors?.placeholder?.message || ''}
      />
      <Label
        inputProps={{ ...register('label') }}
        error={formErrors?.label?.message || ''}
      />
      <Hint
        inputProps={{ ...register('hint') }}
        error={formErrors?.hint?.message || ''}
      />
      <Required
        areConditionsEnabled={watch('required')}
        checkboxProps={{ ...register('required') }}
        onOpenConditionsManagement={onOpenConditionsManagement}
      />
    </>
  );
};
