import { Accordion, Button, Code, List } from '@mantine/core';
import { Modal } from 'components/shared';
import { RefreshIcon } from 'icons';
import { useTranslation } from 'react-i18next';

export const DynamicListPreviewModal = ({
  list,
  isOpen,
  onClose,
  onListRefresh,
}) => {
  const { t } = useTranslation('templates');

  const actionButtons = (
    <>
      <Button variant="default" onClick={onClose}>
        {t('templates:templateCreatorVariables.dynamicList.previewModal.close')}
      </Button>
      <Button
        variant="filled"
        color="blue"
        leftIcon={<RefreshIcon size={20} />}
        onClick={onListRefresh}
      >
        {t(
          'templates:templateCreatorVariables.dynamicList.previewModal.refreshList',
        )}
      </Button>
    </>
  );

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      actionButtons={actionButtons}
    >
      <Accordion>
        <Accordion.Item value="json">
          <Accordion.Control>
            {t(
              'templates:templateCreatorVariables.dynamicList.previewModal.jsonItem',
            )}
          </Accordion.Control>
          <Accordion.Panel>
            <Code block>{JSON.stringify(list, null, 2)}</Code>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="list">
          <Accordion.Control>
            {t(
              'templates:templateCreatorVariables.dynamicList.previewModal.listItem',
            )}
          </Accordion.Control>
          <Accordion.Panel>
            <List listStyleType="disc">
              {list.options.map((option, i) => (
                <List.Item key={`${option.value}-${i}`}>
                  {option.value}
                </List.Item>
              ))}
            </List>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Modal>
  );
};
