import { z } from 'zod';

import { useQuery, useQueryClient } from '@tanstack/react-query';

const schema = z.object({
  options: z.array(
    z.object({
      value: z.string(),
    }),
  ),
});

export const useDynamicListUrlCheck = (url, authHeader) => {
  const queryClient = useQueryClient();
  const {
    data: list,
    isSuccess: isUrlValid,
    isError: isUrlInvalid,
    isFetching: isCheckingUrl,
    refetch: checkUrl,
  } = useQuery({
    queryKey: ['dynamicListUrlCheck', url, authHeader],
    queryFn: async () => {
      new URL(url); // throws an error if the url is invalid
      const response = await fetch(url, {
        headers: authHeader ? { Authorization: authHeader } : undefined,
      });
      const data = await response.json();

      return schema.parse(data);
    },
    enabled: false,
    cacheTime: 0,
  });

  const resetQuery = (url, authHeader) => {
    queryClient.resetQueries(['dynamicListUrlCheck', url, authHeader]);
  };

  return {
    checkUrl,
    list,
    isUrlValid,
    isUrlInvalid,
    isCheckingUrl,
    resetQuery,
  };
};
