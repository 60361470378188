import { forwardRef, ReactNode, useRef, useState } from 'react';
import {
  packSx,
  TextInput as TextInputMantine,
  TextInputProps,
} from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';

import { useGetClearableInputRightSectionData } from '../hooks';
import { ClearableInputRightSection } from '../ClearableInputRightSection';
import { InputLimit } from './InputLimit';

export type Props = {
  rightIcon?: ReactNode;
  rightIconAction?: () => void;
  withErrorWrapperAlwaysEnabled?: boolean;
  isClearableButtonVisible?: boolean;
  inputLengthLimit?: number;
  forceFocusSate?: boolean;
  onClear?: () => void;
} & TextInputProps;

export const TextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      sx,
      error,
      rightIcon,
      rightIconAction,
      withErrorWrapperAlwaysEnabled = true,
      isClearableButtonVisible = false,
      inputLengthLimit,
      value,
      disabled,
      onBlur,
      onFocus,
      forceFocusSate = false,
      onClear = () => null,
      ...textInputMantineProps
    },
    ref,
  ) => {
    const { inputPaddingRight, rightSectionWidth } =
      useGetClearableInputRightSectionData(Boolean(rightIcon));

    const [isFocused, setIsFocused] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const mergedRef = useMergedRef(inputRef, ref);

    const isClearable =
      isClearableButtonVisible ||
      ((isFocused || forceFocusSate) && value !== '');

    return (
      <>
        <TextInputMantine
          sx={[
            {
              input: {
                paddingRight: inputPaddingRight,
              },
            },
            ...packSx(sx),
          ]}
          ref={mergedRef}
          disabled={disabled}
          value={value}
          /* TODO: remove empty space */
          error={!error && withErrorWrapperAlwaysEnabled ? ' ' : error}
          rightSection={
            <ClearableInputRightSection
              isClearable={isClearable}
              inputRef={inputRef}
              rightIcon={rightIcon}
              rightIconAction={rightIconAction}
              disabled={disabled}
              onClear={onClear}
            />
          }
          rightSectionWidth={rightSectionWidth}
          onBlur={e => {
            setIsFocused(false);

            if (onBlur) {
              onBlur(e);
            }
          }}
          onFocus={e => {
            setIsFocused(true);

            if (onFocus) {
              onFocus(e);
            }
          }}
          {...textInputMantineProps}
        />
        {typeof inputLengthLimit === 'number' && typeof value === 'string' && (
          <InputLimit
            sx={theme => ({ margin: theme.other.spacing(0.5, 0, 0, 'auto') })}
            valueLength={value.length}
            limit={inputLengthLimit}
          />
        )}
      </>
    );
  },
);
