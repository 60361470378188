import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from '@mantine/core';

import { ContextMenuIcon } from 'icons';
import { EditorContext } from 'legacy/TemplateCreatorEditor/context';

import { MenuItem } from '../../MenuItem';
import { EditorActionButton } from '../EditorActionButton';

export const InsertSection = () => {
  const { t } = useTranslation('templates');

  const editor = useContext(EditorContext);

  const ITEMS = [
    {
      value: 'field',
      translation: t('templates:field'),
      action: () => {
        editor.insertInlineWithEmptyContent('input');
      },
    },
    {
      value: 'valueOfVariable',
      translation: t('templates:valueOfVariable'),
      action: () => {
        editor.insertInlineWithEmptyContent('variable');
      },
    },
    {
      value: 'blockCondition',
      translation: t('templates:blockCondition'),
      action: () => {
        editor.wrapBlockInConditionalWrapper();
      },
    },
    {
      value: 'linearCondition',
      translation: t('templates:linearCondition'),
      action: () => {
        editor.wrapInlineInConditionalWrapper();
      },
    },
    {
      value: 'pageBreak',
      translation: t('templates:pageBreak'),
      action: () => {
        editor.insertBlockWithOptionalEmptyContent('pageBreak');
      },
    },
    {
      value: 'partiesData',
      translation: t('templates:partiesData'),
      action: () => {
        editor.insertCompanyWrapper();
      },
    },
    {
      value: 'signatures',
      translation: t('templates:partiesSignatures'),
      action: () => {
        editor.insertBlockWithOptionalEmptyContent('signatures');
      },
    },
    {
      value: 'hint',
      translation: t('templates:hint'),
      action: () => {
        editor.insertInlineWithEmptyContent('tooltip');
      },
    },
  ];

  return (
    <Menu>
      <Menu.Target>
        <EditorActionButton>
          <ContextMenuIcon />
          {t('templates:toolbar.insert')}
        </EditorActionButton>
      </Menu.Target>
      <Menu.Dropdown>
        {ITEMS.map(({ value, translation, action }) => (
          <MenuItem
            key={value}
            onClick={() => {
              if (action) {
                action();
              } else {
                alert('COMING SOON');
              }
            }}
          >
            {translation}
          </MenuItem>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
