export const Signatures = ({ obj }) => (
  <div
    type="signatures"
    firstPerson={obj.data.get('firstPerson')}
    secondPerson={obj.data.get('secondPerson')}
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 60,
    }}
  >
    <div
      style={{
        borderTop: '1px dotted black',
        padding: 10,
        textTransform: 'uppercase',
      }}
    >
      {obj.data.get('firstPerson')}
    </div>
    <div
      style={{
        borderTop: '1px dotted black',
        padding: 10,
        textTransform: 'uppercase',
      }}
    >
      {obj.data.get('secondPerson')}
    </div>
  </div>
);
