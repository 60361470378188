import { useTranslation } from 'react-i18next';

import { VARIABLES } from 'legacy/TemplateCreatorEditor/consts';

import { Box, List, Stack, Text, UnstyledButton } from '@mantine/core';
import { VariableMapping } from 'legacy/TemplateCreatorEditor/VariableMapping';
import { VariableIcon } from 'legacy/TemplateCreatorEditor/icons';
import { EditIcon } from 'icons';
import { useContext } from 'react';
import { LoopWrapperContext } from '../../loopWrapper/context';

export const ListItem = ({ children, node, attributes, editor, isActive }) => {
  const { t } = useTranslation('templates');
  const loopWrapperContext = useContext(LoopWrapperContext);

  const variables = editor.getVariables();

  const nodeVariable = node.data.get('variable');

  const variable = variables.find(({ name }) => name === nodeVariable);
  const variableType = variable?.type;
  const variableIcon = variableType
    ? VARIABLES.find(({ value }) => value === variableType)?.icon
    : null;
  const loopIssues = variable ? variable.list && !loopWrapperContext : false;

  return (
    <List.Item sx={{ paddingRight: 17 }}>
      <Box sx={{ display: 'flex' }}>
        <Stack
          spacing={1}
          sx={theme => ({ marginBottom: theme.other.spacing(1) })}
        >
          <VariableMapping
            allowedTypes={['boolean']}
            defaultType="boolean"
            {...attributes}
            node={node}
            allowNullSelection
          >
            <Box
              sx={theme => ({
                position: 'relative',
                display: 'inline-block',
                marginLeft: theme.other.spacing(1),
                userSelect: 'none',
                verticalAlign: 'middle',
              })}
              onClick={e => e.stopPropagation()}
            >
              <UnstyledButton
                sx={theme => {
                  const activeBorderColor =
                    theme.focusRingStyles.inputStyles(theme).borderColor;

                  const getBorderColor = () => {
                    if (isActive) return activeBorderColor;

                    return nodeVariable
                      ? theme.colors.gray[2]
                      : theme.colors.red[0];
                  };

                  return {
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: theme.other.spacing(1),
                    padding: theme.other.spacing(0.75, 2),
                    fontSize: theme.fontSizes.lg,
                    fontWeight: 500,
                    backgroundColor: nodeVariable
                      ? theme.colors.gray[1]
                      : theme.colors.red[1],
                    border: `1px solid ${theme.colors.gray[2]}`,
                    borderColor: getBorderColor(),
                    borderRadius: theme.radius.sm,
                    whiteSpace: 'nowrap',
                  };
                }}
              >
                {variableIcon ? variableIcon : <VariableIcon color="blue" />}
                <Box sx={{ maxWidth: 240 }} component="span">
                  {nodeVariable ? nodeVariable : t('templates:chooseVariable')}
                </Box>
                {Boolean(nodeVariable) && (
                  <EditIcon
                    sx={theme => ({
                      marginLeft: theme.other.spacing(1),
                    })}
                    color="primary"
                  />
                )}
              </UnstyledButton>
            </Box>
          </VariableMapping>
          {loopIssues && (
            <Text color="red">
              {t('templates:templateCreatorVariables.listIssues')}
            </Text>
          )}
        </Stack>
        <Box
          sx={theme => ({
            display: 'inline-block',
            marginLeft: theme.other.spacing(1),
            lineHeight: 3,
            minWidth: 1,
          })}
        >
          {children}
        </Box>
      </Box>
    </List.Item>
  );
};
