import getItemsAtRange from './getItemsAtRange';
import getListForItem from './getListForItem';

/**
 * True if selection is inside a list (and can be unwrapped)
 */
export default function isSelectionInList(opts, value, type) {
  const items = getItemsAtRange(opts, value);
  return (
    !items.isEmpty() &&
    // Check the type of the list if needed
    (!type || getListForItem(opts, value, items.first()).get('type') === type)
  );
}
