import { ReactNode } from 'react';
import {
  Box,
  Divider,
  Grid,
  DefaultProps,
  packSx,
  Text,
  Stack,
} from '@mantine/core';

type Props = {
  children: ReactNode;
  label: string;
  description: ReactNode;
  sx?: DefaultProps['sx'];
  error?: string;
  leftSection?: ReactNode;
  bottomSection?: ReactNode;
  withoutBorder?: boolean;
};

export const DescriptionField = ({
  sx,
  children,
  label,
  description,
  error,
  leftSection,
  bottomSection,
  withoutBorder = false,
}: Props) => (
  <Stack sx={{ gap: 24, ...packSx(sx) }}>
    <Grid>
      <Grid.Col xs={12} sm={5}>
        <Box pr={{ xs: 0, sm: 32 }}>
          <Text weight={600} color={error ? 'red' : undefined}>
            {label}
          </Text>
          <Text
            sx={theme => ({
              marginBottom: theme.other.spacing(1),
              letterSpacing: -0.06,
            })}
            size="md"
            color="red"
          >
            {error}
          </Text>
          <Text size="md" color={error ? 'red' : 'gray.5'}>
            {description}
          </Text>
          {leftSection}
        </Box>
      </Grid.Col>
      <Grid.Col xs={12} sm={7}>
        {children}
      </Grid.Col>
      {bottomSection && <Grid.Col xs={12}>{bottomSection}</Grid.Col>}
    </Grid>
    {!withoutBorder && (
      <Divider
        sx={theme => ({
          borderColor: theme.colors.gray[2],
        })}
      />
    )}
  </Stack>
);
