declare global {
  interface Window {
    env: any;
  }
}

export const env: {
  PUBLIC_URL: string;
  REACT_APP_V1_URL: string;
  REACT_APP_V1_URL_LOCAL: string;
  REACT_APP_API_URL: string;
  REACT_APP_TOLGEE_API_URL: string;
  REACT_APP_TOLGEE_API_KEY: string;
  REACT_APP_DICTIONARIES_REGISTRY_COURTS_URL: string;
  REACT_APP_DICTIONARIES_TAX_OFFICES_URL: string;
} = { ...process.env, ...window.env };
