const MaybeBold = ({ bold, children }) => (bold ? <b>{children}</b> : children);

const MaybeItalic = ({ italic, children }) =>
  italic ? <i>{children}</i> : children;

const MaybeResize = ({ fontSize, children }) =>
  fontSize !== null ? <span style={{ fontSize }}>{children}</span> : children;

export const MaybeTransformer = ({ bold, italic, fontSize, children }) => {
  return (
    <MaybeBold bold={bold}>
      <MaybeItalic italic={italic}>
        <MaybeResize fontSize={fontSize}>{children}</MaybeResize>
      </MaybeItalic>
    </MaybeBold>
  );
};
