export const CONTRACTOR_TYPES_LABELS = {
  NATURAL_PERSON: 'Osoba fizyczna',
  SINGLE_PERSON: 'Indywidualny przedsiębiorca',
  ZOO: 'Spółka z o.o.',
  JAWNA: 'Spółka jawna',
  CYWILNA: 'Spółka cywilna',
  KOMANDYTOWA: 'Spółka komandytowa',
  AKCYJNA: 'Spółka akcyjna',
  KOMANDYTOWO_AKCYJNA: 'Spółka komandytowo-akcyjna',
  PARTNERSKA: 'Spółka partnerska',
  FUNDACJA: 'Fundacja',
  STOWARZYSZENIE: 'Stowarzyszenie',
  SPOLDZIELNIA: 'Spółdzielnia',
  OTHER: 'Inny',
};

export const CONTRACTOR_TYPES = {
  NATURAL_PERSON: 'NATURAL_PERSON',
  SINGLE_PERSON: 'SINGLE_PERSON',
  ZOO: 'ZOO',
  JAWNA: 'JAWNA',
  CYWILNA: 'CYWILNA',
  KOMANDYTOWA: 'KOMANDYTOWA',
  AKCYJNA: 'AKCYJNA',
  KOMANDYTOWO_AKCYJNA: 'KOMANDYTOWO_AKCYJNA',
  PARTNERSKA: 'PARTNERSKA',
  FUNDACJA: 'FUNDACJA',
  STOWARZYSZENIE: 'STOWARZYSZENIE',
  SPOLDZIELNIA: 'SPOLDZIELNIA',
  OTHER: 'OTHER',
};

export const contractorTypesOptions = Object.keys(CONTRACTOR_TYPES_LABELS).map(
  k => ({
    label: CONTRACTOR_TYPES_LABELS[k],
    value: CONTRACTOR_TYPES[k],
  }),
);

export const companyBasicFields = companyType => [
  {
    name: 'companyType',
    label: 'Typ przedsiębiorcy',
    description: 'Description - typ przedsiębiorcy',
    placeholder: 'Wybierz typ',
    options: contractorTypesOptions,
  },
  {
    name: 'companyName',
    label: 'Nazwa firmy',
    description: '',
    placeholder: 'Nazwa firmy',
  },
  {
    name: 'nip',
    label: 'NIP',
    description: 'Description - NIP (format)',
    placeholder: 'NIP',
  },
  {
    name: 'name',
    label: 'Imię',
    description: '',
    placeholder: 'Imię',
  },
  {
    name: 'surname',
    label: 'Nazwisko',
    description: '',
    placeholder: 'Nazwisko',
  },
  ...(companyType === CONTRACTOR_TYPES.SINGLE_PERSON
    ? [
        {
          name: 'pesel',
          label: 'PESEL',
          description: 'Description - pesel',
          placeholder: 'PESEL',
        },
      ]
    : []),
  {
    name: 'email',
    label: 'E-mail',
    description: 'Description - e-mail',
    placeholder: 'E-mail',
  },
  {
    name: 'phone',
    label: 'Numer telefonu',
    description: 'Description - numer telefonu',
    placeholder: 'Numer telefonu',
  },
  {
    name: 'regon',
    label: 'REGON (opcjonalnie)',
    description: 'Description - regon',
    placeholder: 'REGON',
  },
];

export const individualBasicFields = taxOffices => [
  {
    name: 'companyType',
    label: 'Typ przedsiębiorcy',
    description: 'Description - typ przedsiębiorcy',
    placeholder: 'Wybierz typ',
    options: contractorTypesOptions,
  },
  {
    name: 'name',
    label: 'Imię',
    description: '',
    placeholder: 'Imię',
  },
  {
    name: 'surname',
    label: 'Nazwisko',
    description: '',
    placeholder: 'Nazwisko',
  },
  {
    name: 'pesel',
    label: 'PESEL',
    description: 'Description - pesel',
    placeholder: 'PESEL',
  },
  {
    name: 'identityDoc',
    label: 'Numer dowodu osobistego',
    description: '',
    placeholder: 'Numer dowodu osobistego',
  },
  {
    name: 'fatherName',
    label: 'Imię ojca',
    description: '',
    placeholder: 'Imię ojca',
  },
  {
    name: 'motherName',
    label: 'Imię matki',
    description: '',
    placeholder: 'Imię matki',
  },
  {
    name: 'email',
    label: 'E-mail',
    description: 'Description - e-mail',
    placeholder: 'E-mail',
  },
  {
    name: 'phone',
    label: 'Numer telefonu',
    description: 'Description - numer telefonu',
    placeholder: 'Numer telefonu',
  },
  {
    name: 'taxOffice',
    label: 'Urząd Skarbowy',
    description: 'Description - Urząd Skarbowy',
    placeholder: 'Urząd Skarbowy',
    options: taxOffices,
  },
];

export const detailsFields = (companyType, registryCourts) => [
  ...([
    CONTRACTOR_TYPES.ZOO,
    CONTRACTOR_TYPES.JAWNA,
    CONTRACTOR_TYPES.KOMANDYTOWA,
    CONTRACTOR_TYPES.PARTNERSKA,
    CONTRACTOR_TYPES.FUNDACJA,
    CONTRACTOR_TYPES.STOWARZYSZENIE,
    CONTRACTOR_TYPES.SPOLDZIELNIA,
    CONTRACTOR_TYPES.AKCYJNA,
    CONTRACTOR_TYPES.KOMANDYTOWO_AKCYJNA,
    CONTRACTOR_TYPES.OTHER,
  ].includes(companyType)
    ? [
        {
          name: 'registryCourt',
          label: 'Sąd rejestrowy',
          description: 'Description - sąd rejestrowy',
          placeholder: 'Sąd rejestrowy',
          options: registryCourts,
        },
      ]
    : []),
  ...([
    CONTRACTOR_TYPES.ZOO,
    CONTRACTOR_TYPES.JAWNA,
    CONTRACTOR_TYPES.KOMANDYTOWA,
    CONTRACTOR_TYPES.PARTNERSKA,
    CONTRACTOR_TYPES.FUNDACJA,
    CONTRACTOR_TYPES.STOWARZYSZENIE,
    CONTRACTOR_TYPES.SPOLDZIELNIA,
    CONTRACTOR_TYPES.AKCYJNA,
    CONTRACTOR_TYPES.KOMANDYTOWO_AKCYJNA,
    CONTRACTOR_TYPES.OTHER,
  ].includes(companyType)
    ? [
        {
          name: 'krs',
          label: 'Numer KRS',
          description: 'Description - numer KRS',
          placeholder: 'Numer KRS',
        },
      ]
    : []),
  ...([CONTRACTOR_TYPES.AKCYJNA, CONTRACTOR_TYPES.KOMANDYTOWO_AKCYJNA].includes(
    companyType,
  )
    ? [
        {
          name: 'shareCapital',
          label: 'Kapitał zakładowy',
          description: 'Description - kapitał zakładowy',
          placeholder: 'Kapitał zakładowy',
        },
        {
          name: 'paidCapital',
          label: 'Kapitał wpłacony',
          description: 'Description - kapitał wpłacony',
          placeholder: 'Kapitał wpłacony',
        },
      ]
    : []),
  {
    name: 'bankAccount',
    label: 'Numer konta bankowego',
    description: 'Description - numer konta bankowego',
    placeholder: 'Numer konta bankowego',
  },
  {
    name: 'internalName',
    label: 'Nazwa wewnętrzna',
    description: 'Description - nazwa wewnętrzna',
    placeholder: 'Nazwa wewnętrzna',
  },
  {
    name: 'internalNumber',
    label: 'Numer wewnętrzny',
    description: 'Description - numer wewnętrzny',
    placeholder: 'Numer wewnętrzny',
  },
  {
    name: 'groups',
    label: 'Grupa',
    description:
      'Description - grupa.\n' +
      'Pamiętaj, że gdy wybierzesz grupę, pole “E-mail” w pierwszej zakładce stanie się obowiązkowe.',
    placeholder: 'Wybierz lub utwórz nową',
  },
];

export const addressFields = [
  {
    name: 'address.city',
    label: 'Miejscowość',
    description: '',
    placeholder: 'Miejscowość',
  },
  {
    name: 'address.zipCode',
    label: 'Kod pocztowy',
    description: '',
    placeholder: 'Kod pocztowy',
  },
  {
    name: 'address.street',
    label: 'Ulica i numer',
    description: '',
    placeholder: 'Ulica i numer',
  },
  {
    name: 'differentContactAddress',
    label: 'Adres do doręczeń',
    description: '',
    placeholder: 'Inny niż adres',
    defaultChecked: false,
  },
  {
    name: 'contactAddress.city',
    label: 'Miejscowość',
    description: '',
    placeholder: 'Miejscowość',
  },
  {
    name: 'contactAddress.zipCode',
    label: 'Kod pocztowy',
    description: '',
    placeholder: 'Kod pocztowy',
  },
  {
    name: 'contactAddress.street',
    label: 'Ulica i numer',
    description: '',
    placeholder: 'Ulica i numer',
  },
];

export const singlePartnerFields = [
  {
    name: 'name',
    label: 'Imię',
    description: '',
    placeholder: 'Imię',
  },
  {
    name: 'surname',
    label: 'Nazwisko',
    description: '',
    placeholder: 'Nazwisko',
  },
  {
    name: 'city',
    label: 'Miejscowość',
    description: '',
    placeholder: 'Miejscowość',
  },
  {
    name: 'zipCode',
    label: 'Kod pocztowy',
    description: '',
    placeholder: 'Kod pocztowy',
  },
  {
    name: 'street',
    label: 'Ulica i numer',
    description: '',
    placeholder: 'Ulica i numer',
  },
];

export const formDefaultValues = {
  companyType: '',
  companyName: '',
  nip: '',
  name: '',
  surname: '',
  email: '',
  phone: '',
  pesel: '',
  regon: '',
  taxOffice: '',
  identityDoc: '',
  registryCourt: '',
  krs: '',
  shareCapital: '',
  paidCapital: '',
  bankAccount: '',
  internalName: '',
  internalNumber: '',
  groups: '',
  address: {
    city: '',
    street: '',
    zipCode: '',
  },
  contactAddress: {
    city: '',
    street: '',
    zipCode: '',
  },
  differentContactAddress: false,
  representatives: [],
  partners: [],
};
