import { TablePosition } from '../utils';

/**
 * Normalize position in a table. If x is out of the row, update y accordingly.
 * Returns [-1, -1] if the new selection is out of table
 */
function normPos(x, y, width, height) {
  if (x < 0) {
    x = width - 1;
    y -= 1;
  }

  if (y < 0) {
    return [-1, -1];
  }

  if (x >= width) {
    x = 0;
    y += 1;
  }

  if (y >= height) {
    return [-1, -1];
  }

  return [x, y];
}

/**
 * Move selection by a {x,y} relative movement
 */
export default function moveSelectionBy(
  opts,
  editor,
  x, //  Move horizontally by x
  y, // Move vertically by y
) {
  const { value } = editor;
  const { start } = value.selection;
  const pos = TablePosition.create(opts, value.document, start.key);

  if (!pos.isInCell()) {
    throw new Error('moveSelectionBy can only be applied in a cell');
  }

  const rowIndex = pos.getRowIndex();
  const colIndex = pos.getColumnIndex();
  const width = pos.getWidth();
  const height = pos.getHeight();

  const [absX, absY] = normPos(x + colIndex, y + rowIndex, width, height);
  const isGoingUp = y < 0;

  if (absX === -1) {
    // Out of table
    return editor;
  }

  const { table } = pos;
  const row = table.nodes.get(absY);
  const cell = row.nodes.get(absX);

  if (isGoingUp) {
    editor.moveToEndOfNode(cell);
  } else {
    editor.moveToStartOfNode(cell);
  }

  return editor;
}
