import { Stack } from '@mantine/core';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getZodSchemaErrorMessages } from 'helpers';
import { Modal } from 'components/shared';
import { EditorContext } from 'legacy/TemplateCreatorEditor/context';
import {
  EMPTY_GROUP,
  VARIABLE_TYPES,
} from 'legacy/TemplateCreatorEditor/consts';

import { VariableConditions } from '../VariableConditions';
import { VariableTypeFields, Name, Type } from '../VariableTypeFields';
import { useGetVariableNameError } from './hooks';

export const VariableAddModal = ({
  isOpen,
  onClose,
  addVariableCallback,
  defaultType: defaultTypeProp,
  allowedTypes,
}) => {
  const { t } = useTranslation(['common', 'templates']);

  const editor = useContext(EditorContext);

  const getVariableNameError = useGetVariableNameError();

  const defaultType = defaultTypeProp || VARIABLE_TYPES.text;

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [type, setType] = useState(defaultType);
  const [variableConditions, setVariableConditions] = useState(EMPTY_GROUP);
  const [
    variableConditionsLastStateOnModalOpen,
    setVariableConditionsLastStateOnModalOpen,
  ] = useState(null);
  const [isEditConditionModeEnabled, setIsEditConditionModeEnabled] =
    useState(false);

  const fieldsInstanceRef = useRef(null);

  const handleClose = () => {
    onClose();
    setName('');
    setNameError('');
    setType(defaultType);
    setVariableConditions(EMPTY_GROUP);

    fieldsInstanceRef.current = null;
  };

  const handleVariableConditionsModalClose = () => {
    setIsEditConditionModeEnabled(false);
    setVariableConditions(variableConditionsLastStateOnModalOpen);
  };

  return (
    <>
      <Modal
        sx={{ visibility: isEditConditionModeEnabled ? undefined : 'hidden' }}
        size="xl"
        isOpen={isOpen}
        onClose={handleVariableConditionsModalClose}
        title={t('templates:addingCondition')}
        defaultButtonText={t('common:back')}
        defaultButtonAction={handleVariableConditionsModalClose}
        primaryButtonAction={() => {
          setIsEditConditionModeEnabled(false);
        }}
      >
        {Boolean(variableConditions) && (
          <VariableConditions
            conditions={variableConditions.conditions}
            logicalOperator={variableConditions.logicalOperator}
            setVariableConditions={setVariableConditions}
          />
        )}
      </Modal>
      <Modal
        sx={{ visibility: isEditConditionModeEnabled ? 'hidden' : undefined }}
        isOpen={isOpen}
        onClose={handleClose}
        size="xl"
        title={t('templates:templateCreatorVariables.newVariable')}
        defaultButtonAction={handleClose}
        primaryButtonColor="green"
        primaryButtonText={t('common:create')}
        primaryButtonAction={() => {
          const { getValues, schema, clearErrors, setError } =
            fieldsInstanceRef.current;
          const values = getValues();

          const variableNameError = getVariableNameError(name);

          setNameError(variableNameError);

          const errors =
            getZodSchemaErrorMessages({
              schema,
              fields: values,
            }) || {};

          clearErrors();

          Object.entries(errors).forEach(([field, message]) => {
            setError(field, { message });
          });

          if (!variableNameError && Object.keys(errors).length === 0) {
            const newVariable = {
              ...values,
              name,
              type,
              requiredWhen: variableConditions,
            };

            editor.addVariable(newVariable);

            if (addVariableCallback) {
              addVariableCallback(newVariable);
            }

            handleClose();
          }
        }}
      >
        <form>
          <Stack py={24} sx={{ gap: 24 }}>
            <Name
              value={name}
              onChange={value => {
                setNameError('');
                setName(value);
              }}
              error={nameError}
            />
            <Type value={type} onChange={setType} allowedTypes={allowedTypes} />
            <VariableTypeFields
              variableType={type}
              fieldsInstanceRef={fieldsInstanceRef}
              onOpenConditionsManagement={() => {
                setIsEditConditionModeEnabled(true);
                setVariableConditionsLastStateOnModalOpen(variableConditions);
              }}
            />
          </Stack>
        </form>
      </Modal>
    </>
  );
};
