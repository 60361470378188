import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrashIcon, EditIcon } from 'icons';
import { DataTypeListItemType } from 'types';
import { Actions } from 'components/shared';

import { DataTypeEditModal } from '../DataTypeEditModal';
import { DataTypeDeleteModal } from '../DataTypeDeleteModal';

type Props = {
  name: DataTypeListItemType['name'];
};

export const DataTypeActions = ({ name }: Props) => {
  const { t } = useTranslation('common');

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <>
      <Actions
        items={[
          {
            name: t('common:edit'),
            icon: <EditIcon />,
            colorDesktop: 'blue',
            action: () => {
              setIsEditModalOpen(true);
            },
          },
          {
            name: t('common:delete'),
            icon: <TrashIcon />,
            colorDesktop: 'red',
            colorMobile: 'red',
            action: () => {
              setIsDeleteModalOpen(true);
            },
          },
        ]}
      />
      <DataTypeEditModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        name={name}
      />
      <DataTypeDeleteModal
        name={name}
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};
