import { Box, Tabs } from '@mantine/core';
import { FormTextField } from './FormTextField';
import { FormCheckboxField } from './FormCheckboxField';
import { addressFields } from '../constants';

export const AddressTabPanel = ({ register, formErrors, watch }) => {
  const differentContactAddress = watch('differentContactAddress');
  return (
    <Tabs.Panel value="address">
      <Box>
        {addressFields.map((field, fieldIndex) => {
          if (
            !differentContactAddress &&
            [
              'contactAddress.city',
              'contactAddress.zipCode',
              'contactAddress.street',
            ].includes(field.name)
          ) {
            return null;
          }

          const withoutBorder = differentContactAddress
            ? fieldIndex === addressFields.length - 1
            : fieldIndex === addressFields.length - 4;

          if (field.defaultChecked !== undefined) {
            return (
              <FormCheckboxField
                key={`${field.name}_${fieldIndex}`}
                checkboxProps={{
                  ...register(field.name),
                  label: field.placeholder,
                }}
                error={formErrors?.[field.name]?.message || ''}
                field={field}
                withoutBorder={withoutBorder}
              />
            );
          }

          const splittedFieldName = field.name.split('.');
          const error =
            formErrors?.[splittedFieldName[0]]?.[splittedFieldName[1]]
              ?.message || '';

          return (
            <FormTextField
              key={`${field.name}_${fieldIndex}`}
              inputProps={{ ...register(field.name) }}
              error={error}
              field={field}
              withErrorWrapperAlwaysEnabled={false}
              withoutBorder={withoutBorder}
            />
          );
        })}
      </Box>
    </Tabs.Panel>
  );
};
