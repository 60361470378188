import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useGetCurrentBoard } from 'api/boards';
import { useGetTemplate } from 'api/templates';
import { DYNAMIC_URLS, URLS } from 'consts';
import { getHasHateoasAccess } from 'helpers';
import {
  Breadcrumbs,
  DataWrapper,
  MetaTitle,
  NotFound,
} from 'components/shared';
import { TemplatePreviewModal } from 'pages/TemplateEdit/TemplatePreviewModal/TemplatePreviewModal';
import { TemplateAttachmentFormHandler } from './TemplateAttachmentFormHandler';

export const TemplateAttachmentEdit = () => {
  const { t } = useTranslation('templates');
  const { templateId, attachmentName } = useParams() as {
    templateId: string;
    attachmentName: string;
  };
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const { board, isCurrentBoardError, isCurrentBoardLoading } =
    useGetCurrentBoard();
  const hasBoardTemplateFeature = board?.canAccessTemplateCreator || false;
  const {
    data: templateData,
    isError: isTemplateError,
    isLoading: isTemplateLoading,
    isRefetching: isTemplateRefetching,
  } = useGetTemplate(
    { pathParams: { templateId } },
    { enabled: hasBoardTemplateFeature },
  );

  const hasTemplateUpdateAccess = getHasHateoasAccess(
    'update',
    templateData?.__links,
  );

  const attachmentIndex = templateData?.attachmentTemplates.findIndex(
    ({ type }) => type === attachmentName,
  );
  const attachment =
    attachmentIndex !== undefined
      ? templateData?.attachmentTemplates[attachmentIndex]
      : undefined;

  return (
    <>
      <MetaTitle value={t('templates:templates')} />
      <DataWrapper
        data={templateData}
        isError={isCurrentBoardError || isTemplateError}
        isLoading={
          isCurrentBoardLoading ||
          isTemplateLoading ||
          (isTemplateRefetching && !attachment)
        }
        isNoAccess={
          !hasBoardTemplateFeature &&
          hasTemplateUpdateAccess &&
          !isCurrentBoardLoading
        }
        errorMargin="xl"
      >
        {({ name, attachmentTemplates }) => (
          <>
            <MetaTitle value={`${name} - ${t('templates:templates')}`} />
            {attachment ? (
              <>
                <Breadcrumbs
                  sx={theme => ({
                    marginBottom: theme.other.spacing(3),
                  })}
                  items={[
                    { name: t('templates:templates'), url: URLS.templates },
                    { name, url: DYNAMIC_URLS.templateEdit({ templateId }) },
                    { name: attachment.type, url: '#' },
                  ]}
                />
                <TemplateAttachmentFormHandler
                  attachment={attachment}
                  attachmentIndex={attachmentIndex as number}
                  attachmentTemplates={attachmentTemplates}
                  onPreviewOpen={() => setIsPreviewModalOpen(true)}
                />
                <TemplatePreviewModal
                  isOpen={isPreviewModalOpen}
                  onClose={() => setIsPreviewModalOpen(false)}
                />
              </>
            ) : (
              <NotFound />
            )}
          </>
        )}
      </DataWrapper>
    </>
  );
};
