import { Record } from 'immutable';

/**
 * The plugin options
 */
class Options extends Record({
  typeTable: 'table',
  typeRow: 'table_row',
  typeCell: 'table_cell',
  typeContent: 'paragraph',
  exitBlockType: 'paragraph',
}) {
  // The type of table blocks
  // typeTable;
  // // The type of row blocks
  // typeRow;
  // // The type of cell blocks
  // typeCell;
  // // The default type for blocks in cells
  // typeContent;
  // // The type of block inserted when exiting
  // exitBlockType;
  /*
   * Return a node filter to find a cell.
   */
  isCell = node => {
    return node.object === 'block' && node.type === this.typeCell;
  };
}

export default Options;
