import { useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  useDynamicListUrlCheck,
  useGetZodDynamicListVariableSchema,
} from './hooks';

import { AuthHeader } from './AuthHeader';
import { Hint } from '../Hint';
import { InitialTextValue } from '../InitialTextValue';
import { Label } from '../Label';
import { Placeholder } from '../Placeholder';
import { Required } from '../Required';
import { Url } from './Url';
import { useTranslation } from 'react-i18next';
import { ListSupport } from '../ListSupport';

export const DynamicListVariable = ({
  fieldsInstanceRef,
  initialValues,
  onOpenConditionsManagement,
}) => {
  const schema = useGetZodDynamicListVariableSchema();
  const { t } = useTranslation('templates');
  const {
    formState: { errors: formErrors },
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues || {
      initialValue: '',
      placeholder: '',
      label: '',
      hint: '',
      url: '',
      authHeader: '',
      required: false,
    },
  });
  const formUrl = watch('dynamicLinkValue');
  const formAuthHeader = watch('authHeader');
  const [paramsToBeChecked, setParamsToBeChecked] = useState(true);
  const {
    list,
    isUrlValid,
    isUrlInvalid,
    isCheckingUrl,
    checkUrl,
    resetQuery,
  } = useDynamicListUrlCheck(formUrl, formAuthHeader);
  const urlFieldError = isUrlInvalid
    ? t('templateCreatorVariables.dynamicList.urlField.urlError')
    : formErrors?.url?.message || '';

  const checkParams = (force = false) => {
    if ((paramsToBeChecked && formUrl !== '') || force) {
      resetQuery(formUrl, formAuthHeader);
      checkUrl();
      setParamsToBeChecked(false);
    }
  };

  const forceCheckParams = () => {
    checkParams(true);
  };

  useEffect(() => {
    checkParams();
  }, []);

  useEffect(() => {
    setParamsToBeChecked(true);
  }, [formUrl, formAuthHeader]);

  useEffect(() => {
    setValue('options', list?.options);
  }, [list]);

  useImperativeHandle(fieldsInstanceRef, () => ({
    getValues,
    schema,
    clearErrors,
    setError,
  }));

  return (
    <>
      <InitialTextValue
        inputProps={{ ...register('initialValue') }}
        error={formErrors?.initialValue?.message || ''}
      />
      <Placeholder
        inputProps={{ ...register('placeholder') }}
        error={formErrors?.placeholder?.message || ''}
      />
      <Label
        inputProps={{ ...register('label') }}
        error={formErrors?.label?.message || ''}
      />
      <Hint
        inputProps={{ ...register('hint') }}
        error={formErrors?.hint?.message || ''}
      />
      <Url
        inputProps={{ ...register('dynamicLinkValue') }}
        error={urlFieldError}
        list={list}
        isValid={isUrlValid}
        isChecking={isCheckingUrl}
        onBlur={checkParams}
        onListRefresh={forceCheckParams}
      />
      <AuthHeader
        inputProps={{ ...register('authHeader') }}
        error={formErrors?.authHeader?.message || ''}
        onBlur={checkParams}
      />
      <ListSupport
        checkboxProps={{ ...register('list') }}
        error={formErrors?.list?.message || ''}
      />
      <Required
        areConditionsEnabled={watch('required')}
        checkboxProps={{ ...register('required') }}
        onOpenConditionsManagement={onOpenConditionsManagement}
      />
    </>
  );
};
