import { Block, Text } from 'slate';

/**
 * Create a new default content block
 */
function createEmptyContent(opts) {
  return Block.create({
    type: opts.typeContent,
    nodes: [Text.create()],
  });
}

/**
 * Create a new cell
 */
export default function createCell(opts, nodes) {
  return Block.create({
    type: opts.typeCell,
    nodes: nodes || [createEmptyContent(opts)],
  });
}
