import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const RefreshIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 19A7 7 0 0 1 7.67 6.5M12 5a7 7 0 0 1 4.33 12.5m-.33-3V18h3.5M4.5 6H8v3.5"
        stroke={iconColor}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Box>
  );
};
