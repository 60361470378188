import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Checkbox, Stack, Tooltip } from '@mantine/core';

import { DataStreamTemplateType } from 'types';
import {
  AttachmentTemplateSelect,
  TemplateSelect,
} from 'components/Transactions';
import { DescriptionField, TextInput } from 'components/shared';

const FIELD_LIMIT = 255;

const DEFAULT_EMPTY_SELECTED_TEMPLATE_ATTACHMENT = {
  templateId: null,
  documentContent: null,
  templateName: null,
};

type FormValues = { documentFromFile: boolean } & Pick<
  DataStreamTemplateType,
  | 'documentName'
  | 'documentExternalId'
  | 'documentReference'
  | 'documentContent'
  | 'isBlocked'
>;

type Props = {
  formRef: MutableRefObject<HTMLFormElement | null>;
  addDataStreamTemplate: (
    values:
      | (Omit<FormValues, 'documentFromFile'> & {
          documentContent: null;
          templateId: number;
          templateName: string;
          attachTo: string | number | null;
        })
      | (Omit<FormValues, 'documentFromFile'> & {
          documentContent: string;
          templateId: null;
          templateName: null;
          attachTo: string | number | null;
        }),
  ) => void;
  errors: { [error: string]: string } | null;
  clearError: (errorKey: string) => void;
  templates: DataStreamTemplateType[];
};

export const DataStreamAddTemplateForm = ({
  formRef,
  addDataStreamTemplate,
  errors,
  clearError,
  templates,
}: Props) => {
  const { t } = useTranslation(['common', 'dataStreams']);

  const [selectedTemplate, setSelectedTemplate] = useState<{
    id: number | null;
    name: string | null;
  }>({
    id: null,
    name: null,
  });
  const [selectedTemplateError, setSelectedTemplateError] = useState('');

  const [isAttachment, setIsAttachment] = useState(false);
  const [selectedAttachmentTemplate, setSelectedAttachmentTemplate] = useState<{
    templateId: number | null;
    templateName: string | null;
    documentContent: string | null;
  }>(DEFAULT_EMPTY_SELECTED_TEMPLATE_ATTACHMENT);

  const selectTemplateRef = useRef<HTMLInputElement>(null);
  const selectAttachmentTemplateRef = useRef<HTMLInputElement>(null);

  const schema = z
    .object({
      documentFromFile: z.boolean(),
      documentName: z
        .string()
        .trim()
        .min(1, { message: t('common:formErrors.required') })
        .max(FIELD_LIMIT, {
          message: t('common:formErrors.maxNumberOfSigns', {
            max: FIELD_LIMIT,
          }),
        }),
      documentReference: z.string(),
      documentExternalId: z
        .string()
        .trim()
        .min(1, { message: t('common:formErrors.required') })
        .max(FIELD_LIMIT, {
          message: t('common:formErrors.maxNumberOfSigns', {
            max: FIELD_LIMIT,
          }),
        }),
      documentContent: z.string(),
      isBlocked: z.boolean(),
    })
    .superRefine(({ documentFromFile, documentContent }, ctx) => {
      if (documentFromFile) {
        const documentContentParsedSchema = z
          .string()
          .trim()
          .min(1, { message: t('common:formErrors.required') })
          .max(FIELD_LIMIT, {
            message: t('common:formErrors.maxNumberOfSigns', {
              max: FIELD_LIMIT,
            }),
          })
          .safeParse(documentContent);

        if (!documentContentParsedSchema.success) {
          ctx.addIssue({
            ...documentContentParsedSchema.error.issues[0],
            path: ['documentContent'],
          });
        }
      }
    });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors: formErrors },
    setValue,
    clearErrors,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      documentFromFile: false,
      documentName: '',
      documentExternalId: '',
      documentReference: '',
      documentContent: '',
      isBlocked: false,
    },
  });

  const documentFromFileValue = watch('documentFromFile');
  const documentNameValue = watch('documentName');
  const documentExternalIdValue = watch('documentExternalId');
  const documentReferenceValue = watch('documentReference');
  const documentContentValue = watch('documentContent');
  const isBlockedValue = watch('isBlocked');

  const focusTemplateSelect = () => {
    setTimeout(() => {
      selectTemplateRef.current?.focus();
    }, 10);
  };

  const focusAttachmentTemplateSelect = () => {
    setTimeout(() => {
      selectAttachmentTemplateRef.current?.focus();
    }, 10);
  };

  const setSelectedTemplateErrorWithFocus = () => {
    setSelectedTemplateError(t('common:formErrors.required'));
    focusTemplateSelect();
  };

  const onSubmit = ({ documentFromFile: _, ...values }: FormValues) => {
    const attachTo =
      selectedAttachmentTemplate.templateId ||
      selectedAttachmentTemplate.documentContent;

    if (documentFromFileValue) {
      addDataStreamTemplate({
        ...values,
        documentContent: values.documentContent as string,
        templateId: null,
        templateName: null,
        isBlocked: false,
        attachTo,
      });
    } else if (selectedTemplate.id) {
      addDataStreamTemplate({
        ...values,
        documentContent: null,
        templateId: selectedTemplate.id,
        templateName: selectedTemplate.name as string,
        isBlocked: values.isBlocked,
        attachTo,
      });
    } else {
      setSelectedTemplateErrorWithFocus();
    }
  };

  const onError = () => {
    if (!selectedTemplate.id) {
      setSelectedTemplateErrorWithFocus();
    }
  };

  const customRegister = (fieldName: keyof FormValues) => ({
    ...register(fieldName),
    onChange: async (e: { target: any; type?: any }) => {
      setValue(
        e.target.name,
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      );
      clearError(fieldName);
      clearErrors(fieldName);
    },
  });

  useEffect(() => {
    if (errors?.templateId) {
      focusTemplateSelect();
    } else if (errors?.attachTo) {
      focusAttachmentTemplateSelect();
    }
  }, [errors]);

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}>
      <Stack py={24} sx={{ gap: 24 }}>
        <DescriptionField
          label={t('dataStreams:editModal.documentFromFile')}
          description={t('dataStreams:editModal.documentFromFileDescription')}
          error={formErrors?.isBlocked?.message || errors?.isBlocked}
        >
          <Checkbox
            sx={theme => ({
              marginBottom: theme.other.spacing(1),
            })}
            {...customRegister('documentFromFile')}
          />
        </DescriptionField>
        {documentFromFileValue ? (
          <DescriptionField
            label={t('dataStreams:editModal.fileField')}
            description={t('dataStreams:editModal.fileFieldDescription')}
            error={
              formErrors?.documentContent?.message || errors?.documentContent
            }
          >
            <TextInput
              {...customRegister('documentContent')}
              value={documentContentValue || ''}
              onChange={e => {
                customRegister('documentContent').onChange(e);
                setSelectedAttachmentTemplate(
                  DEFAULT_EMPTY_SELECTED_TEMPLATE_ATTACHMENT,
                );
              }}
              placeholder={t(
                'dataStreams:editModal.documentContentPlaceholder',
              )}
              inputLengthLimit={FIELD_LIMIT}
            />
          </DescriptionField>
        ) : (
          <DescriptionField
            label={t('dataStreams:editModal.template')}
            description={t('dataStreams:editModal.templateDescription')}
            error={selectedTemplateError || errors?.templateId}
          >
            <TemplateSelect
              selectRef={selectTemplateRef}
              templateId={selectedTemplate.id}
              templateName={selectedTemplate.name}
              onTemplateChange={({ id, name }) => {
                setSelectedTemplate({ id, name });
                setSelectedTemplateError('');
                clearError('templateId');
                setSelectedAttachmentTemplate(
                  DEFAULT_EMPTY_SELECTED_TEMPLATE_ATTACHMENT,
                );
              }}
            />
          </DescriptionField>
        )}
        <DescriptionField
          label={t('dataStreams:editModal.documentName')}
          description={t('dataStreams:editModal.documentNameDescription')}
          error={formErrors?.documentName?.message || errors?.documentName}
        >
          <TextInput
            {...customRegister('documentName')}
            value={documentNameValue}
            withErrorWrapperAlwaysEnabled={false}
            placeholder={t('dataStreams:editModal.documentNamePlaceholder')}
            inputLengthLimit={FIELD_LIMIT}
          />
        </DescriptionField>
        <DescriptionField
          label={t('dataStreams:editModal.externalId')}
          description={t('dataStreams:editModal.externalIdDescription')}
          error={
            formErrors?.documentExternalId?.message ||
            errors?.documentExternalId
          }
        >
          <TextInput
            {...customRegister('documentExternalId')}
            value={documentExternalIdValue}
            withErrorWrapperAlwaysEnabled={false}
            placeholder={t('dataStreams:editModal.externalIdPlaceholder')}
            inputLengthLimit={FIELD_LIMIT}
          />
        </DescriptionField>
        <DescriptionField
          label={t('dataStreams:editModal.documentReference')}
          description={t('dataStreams:editModal.documentReferenceDescription')}
          error={
            formErrors?.documentReference?.message || errors?.documentReference
          }
        >
          <TextInput
            {...customRegister('documentReference')}
            value={documentReferenceValue || ''}
            withErrorWrapperAlwaysEnabled={false}
            placeholder={t(
              'dataStreams:editModal.documentReferencePlaceholder',
            )}
            inputLengthLimit={FIELD_LIMIT}
          />
        </DescriptionField>
        <DescriptionField
          label={t('dataStreams:editModal.dontUpdate')}
          description={t('dataStreams:editModal.dontUpdateDescription')}
          error={formErrors?.isBlocked?.message || errors?.isBlocked}
        >
          {documentFromFileValue ? (
            <Tooltip
              label={t(
                'dataStreams:editModal.cantChangeValueForDataStreamFromFile',
              )}
            >
              <Box sx={{ width: 'fit-content' }}>
                <Checkbox checked disabled />
              </Box>
            </Tooltip>
          ) : (
            <Checkbox
              defaultChecked={isBlockedValue}
              {...customRegister('isBlocked')}
            />
          )}
        </DescriptionField>
        <DescriptionField
          sx={{ borderBottom: isAttachment ? 'none' : undefined }}
          label={t('dataStreams:editModal.attachment')}
          description={t('dataStreams:editModal.attachmentDescription')}
          withoutBorder={!isAttachment}
        >
          <Checkbox
            checked={isAttachment}
            onChange={e => {
              const isChecked = e.target.checked;

              setIsAttachment(isChecked);

              if (!isChecked) {
                setSelectedAttachmentTemplate(
                  DEFAULT_EMPTY_SELECTED_TEMPLATE_ATTACHMENT,
                );
              }
            }}
          />
        </DescriptionField>
        {isAttachment && (
          <DescriptionField
            sx={{ paddingTop: 0 }}
            label={t('dataStreams:editModal.baseTemplate')}
            description={t('dataStreams:editModal.baseTemplateDescription')}
            error={errors?.attachTo}
          >
            <AttachmentTemplateSelect
              selectRef={selectAttachmentTemplateRef}
              isDataStreamFromFile={documentFromFileValue}
              templates={templates.filter(({ attachTo }) => attachTo === null)}
              selectedAttachmentTemplate={selectedAttachmentTemplate}
              onAttachmentTemplateChange={({
                templateId,
                templateName,
                documentContent: templateDocumentContent,
              }) => {
                setSelectedAttachmentTemplate({
                  templateId,
                  templateName,
                  documentContent: templateDocumentContent,
                });
                clearError('attachTo');
              }}
            />
          </DescriptionField>
        )}
      </Stack>
    </form>
  );
};
