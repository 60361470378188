import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCurrentBoard } from 'api/boards';
import { URLS } from 'consts';
import { getHasHateoasAccess } from 'helpers';
import { Breadcrumbs, DataWrapper, MetaTitle } from 'components/shared';
import { TemplatePreviewModal } from 'pages/TemplateEdit/TemplatePreviewModal/TemplatePreviewModal';

import { TemplateAddForm } from './TemplateAddForm';

export const TemplateAdd = () => {
  const { t } = useTranslation('templates');
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const { board, isCurrentBoardError, isCurrentBoardLoading } =
    useGetCurrentBoard();

  const hasBoardTemplateCreateFeature = getHasHateoasAccess(
    'createTemplate',
    board?.__links,
  );

  return (
    <>
      <MetaTitle value={t('templates:templates')} />
      <DataWrapper
        isError={isCurrentBoardError}
        isLoading={isCurrentBoardLoading}
        isNoAccess={!hasBoardTemplateCreateFeature && !isCurrentBoardLoading}
        errorMargin="xl"
      >
        {() => (
          <>
            <Breadcrumbs
              sx={theme => ({
                marginBottom: theme.other.spacing(3),
              })}
              items={[
                { name: t('templates:templates'), url: URLS.templates },
                { name: t('templates:newTemplate'), url: '#' },
              ]}
            />
            <TemplateAddForm
              onPreviewOpen={() => setIsPreviewModalOpen(true)}
            />
            <TemplatePreviewModal
              isOpen={isPreviewModalOpen}
              onClose={() => setIsPreviewModalOpen(false)}
            />
          </>
        )}
      </DataWrapper>
    </>
  );
};
