import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mantine/core';

const LOGICAL_OPERATOR_ITEMS = [
  {
    value: 'and',
    translationKey: 'templates:templateCreatorVariables.and',
  },
  {
    value: 'or',
    translationKey: 'templates:templateCreatorVariables.or',
  },
];

export const LogicalOperatorSwitch = ({ value: valueProp, onChange }) => {
  const { t } = useTranslation('templates');

  return (
    <Box sx={theme => ({ display: 'flex', gap: theme.other.spacing(1) })}>
      {LOGICAL_OPERATOR_ITEMS.map(({ value, translationKey }) => {
        const isActive = value === valueProp;

        return (
          <Button
            sx={theme => ({
              fontSize: theme.fontSizes.lg,
              textTransform: 'uppercase',
              color: isActive ? undefined : theme.colors.blue[0],
            })}
            key={value}
            color={isActive ? 'blue' : 'blue.1'}
            onClick={() => {
              onChange(value);
            }}
          >
            {t(translationKey)}
          </Button>
        );
      })}
    </Box>
  );
};
