import { isSelectionInTable } from '../utils';

// import onEnter from './onEnter';
import onModEnter from './onModEnter';
import onTab from './onTab';
// import onBackspace from './onBackspace';
import onUpDown from './onUpDown';

const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';
// const KEY_BACKSPACE = 'Backspace';
const KEY_DOWN = 'ArrowDown';
const KEY_UP = 'ArrowUp';
const DELETE = 'Delete';

/**
 * User is pressing a key in the editor
 */
export default function onKeyDown(opts, event, editor, next) {
  // Only handle events in cells
  if (!isSelectionInTable(opts, editor.value)) {
    if (event.key === DELETE) {
      // to prevent case when slate would try to merge table with preceding block
      const lastBlock = editor.value.endBlock;
      const sibling = editor.value.document.getNextSibling(lastBlock.key);

      if (
        sibling &&
        sibling.type === 'table' &&
        lastBlock.text &&
        editor.value.selection.isCollapsed &&
        editor.value.selection.start.isAtEndOfNode(lastBlock)
      ) {
        return null;
      }
    }

    return next();
  }

  // Build arguments list
  const args = [event, editor, opts];

  switch (event.key) {
    case KEY_ENTER:
      if (event.shiftKey && opts.exitBlockType) {
        return onModEnter(...args, next);
      }
      // return onEnter(...args, next); // this method create new row when user press enter key
      return onUpDown(...args, next);
    case KEY_TAB:
      return onTab(...args, next);
    // case KEY_BACKSPACE:
    //   return onBackspace(...args, next);
    case KEY_DOWN:
    case KEY_UP:
      return onUpDown(...args, next);
    default:
      return next();
  }
}
