import { Range } from 'immutable';
import { Block } from 'slate';

import createCell from './createCell';

/**
 * Create a new row block
 */
export default function createRow(
  opts,
  columns,
  getCellContent, // ?: (column: number) => Node[]
) {
  const cellNodes = Range(0, columns)
    .map(i => createCell(opts, getCellContent ? getCellContent(i) : undefined))
    .toList();

  return Block.create({
    type: opts.typeRow,
    nodes: cellNodes,
  });
}
