/*
 * Allows to define a normalize function through a keyed collection of functions
 */
function normalize(
  reasons /* : { [string]: (Change, context: any) => any } */,
) {
  return (change, error) => {
    const reasonFn = reasons[error.code];

    if (reasonFn) {
      reasonFn(change, error);
    }
  };
}

/**
 * Wraps all child of a node in the default block type.
 * Returns a change, for chaining purposes
 */
function wrapChildrenInDefaultBlock(opts, editor, node) {
  editor.withoutNormalizing(() => {
    editor.wrapBlockByKey(node.nodes.first().key, opts.typeDefault);

    const wrapper = editor.value.document.getDescendant(node.key).nodes.first();

    // Add in the remaining items
    node.nodes
      .rest()
      .forEach((child, index) =>
        editor.moveNodeByKey(child.key, wrapper.key, index + 1),
      );
  });

  return editor;
}

/**
 * Create a schema definition with rules to normalize lists
 */
export default function schema(opts) {
  const constructedSchema = {
    blocks: {
      [opts.typeItem]: {
        parent: opts.types.map(type => ({ type })),
        nodes: [{ match: { object: 'block' } }],
        normalize: normalize({
          parent_type_invalid: (editor, error) =>
            editor.withoutNormalizing(() => {
              editor.unwrapBlockByKey(error.node.key);
            }),
          child_object_invalid: (editor, error) =>
            wrapChildrenInDefaultBlock(opts, editor, error.node),
        }),
      },
    },
  };

  // validate all list types, ensure they only have list item children
  opts.types.forEach(type => {
    constructedSchema.blocks[type] = {
      nodes: [{ match: { type: opts.typeItem } }],
      normalize: normalize({
        child_type_invalid: (editor, error) =>
          editor.withoutNormalizing(() => {
            editor.wrapBlockByKey(error.child.key, opts.typeItem);
          }),
      }),
    };
  });

  return constructedSchema;
}
