import { useTranslation } from 'react-i18next';
import { Box, Paper, UnstyledButton } from '@mantine/core';

import { TransactionsIcon } from 'icons';
import { LoopWrapperContextProvider } from './context';
import { EditTable } from '../table/legacy/editTable';
import { LoopWrapperToolBar } from './LoopWrapperToolBar';

const LoopWrapper = ({ attributes, node, editor, children, isActive }) => {
  const { t } = useTranslation(['common', 'templates']);

  return (
    <LoopWrapperContextProvider>
      <Paper
        {...attributes}
        sx={theme => {
          const activeBorderColor =
            theme.focusRingStyles.inputStyles(theme).borderColor;

          return {
            marginTop: theme.other.spacing(1),
            border: '1px solid',
            borderColor: isActive ? activeBorderColor : undefined,
            userSelect: 'none',
          };
        }}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <LoopWrapperToolBar editor={editor} node={node} />
        {children}
      </Paper>
    </LoopWrapperContextProvider>
  );
};

export const loopWrapper = {
  renderNode: (
    { attributes, node, children, isSelected, isFocused },
    editor,
    next,
  ) => {
    if (node.type === 'loopWrapper') {
      return (
        <LoopWrapper
          attributes={attributes}
          node={node}
          editor={editor}
          isActive={isSelected && isFocused}
        >
          {children}
        </LoopWrapper>
      );
    }

    return next();
  },
  commands: {
    wrapInLoopWrapper: editor => {
      if (EditTable().utils.isSelectionInTable(editor.value)) {
        const tableRow = editor.value.document
          .getAncestors(editor.value.blocks.get(0).key)
          .filter(v => v.type === 'tableRow')
          .get(0);

        editor.setNodeByKey(tableRow.key, {
          data: { loop: true },
        });
      } else {
        editor.wrapBlockWithOptionalEmptyContent('loopWrapper');
      }
    },
  },
};
