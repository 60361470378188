import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from '@mantine/core';

import { EditorContext } from 'legacy/TemplateCreatorEditor/context';

import { MenuItem } from '../../../MenuItem';
import { EditorActionButton } from '../../EditorActionButton';

export const Punctuation = () => {
  const { t } = useTranslation('templates');

  const editor = useContext(EditorContext);

  const isAutoPunctuationEnabled = !editor.value.document.data.get(
    'disableAutoPunctuation',
  );
  const handleAutoPunctuationChange = autoPunctuation => {
    editor.updateNodeData(editor.value.document, {
      disableAutoPunctuation: autoPunctuation === 'manual',
    });
  };

  return (
    <Menu>
      <Menu.Target>
        <EditorActionButton>
          {t('templates:toolbar.punctuation')}
          <b>
            {isAutoPunctuationEnabled
              ? t('templates:toolbar.auto')
              : t('templates:toolbar.manual')}
          </b>
        </EditorActionButton>
      </Menu.Target>
      <Menu.Dropdown>
        <MenuItem
          isActive={!isAutoPunctuationEnabled}
          onClick={() => {
            handleAutoPunctuationChange('manual');
          }}
        >
          {t('templates:toolbar.manual')}
        </MenuItem>
        <MenuItem
          isActive={isAutoPunctuationEnabled}
          onClick={() => {
            handleAutoPunctuationChange('auto');
          }}
        >
          {t('templates:toolbar.auto')}
        </MenuItem>
      </Menu.Dropdown>
    </Menu>
  );
};
