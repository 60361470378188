import { Box, Button, Tabs } from '@mantine/core';
import { SinglePartner } from './SinglePartner';
import { NotFound } from 'components/shared';

export const PartnersTabPanel = ({
  register,
  formErrors,
  partnersFieldArray,
}) => {
  const { fields, append, remove } = partnersFieldArray;

  const handleAppend = () => {
    append({
      name: '',
      surname: '',
      city: '',
      zipCode: '',
      street: '',
    });
  };

  return (
    <Tabs.Panel value="partners">
      {fields.length > 0 ? (
        <>
          <Box>
            <Button
              color="green"
              onClick={() => {
                handleAppend();
              }}
            >
              Dodaj wspólnika
            </Button>
          </Box>
          <Box
            sx={theme => ({
              display: 'flex',
              flexDirection: 'column',
              marginTop: theme.other.spacing(2),
            })}
          >
            {fields.map((field, index) => (
              <SinglePartner
                key={`${field.name}_${index}`}
                id={field.id}
                index={index}
                register={register}
                errors={formErrors['partners']?.[index]}
                remove={remove}
              />
            ))}
          </Box>
        </>
      ) : (
        <Box
          sx={theme => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: theme.other.spacing(4),
          })}
        >
          <NotFound
            header="Nie dodałeś jeszcze żadnego wspólnika"
            sx={theme => ({
              marginBottom: theme.other.spacing(2),
            })}
          />
          <Button
            color="green"
            onClick={() => {
              append({ value: '' });
            }}
            sx={() => ({
              flexBasis: 'auto',
            })}
          >
            Dodaj wspólnika
          </Button>
        </Box>
      )}
    </Tabs.Panel>
  );
};
