import { TablePosition, createCell } from '../utils';

import moveSelection from './moveSelection';

/**
 * Insert a new column in current table
 */
export default function insertColumn(
  opts,
  editor,
  at, // ?: number, // Column index
  getCell, // ?: (column: number, row: number) => Block,
) {
  const { value } = editor;
  const { start } = value.selection;
  const pos = TablePosition.create(opts, value.document, start.key);
  const { table } = pos;
  const columnIndex = typeof at === 'undefined' ? pos.getColumnIndex() + 1 : at;

  // Insert the new cell
  editor.withoutNormalizing(() => {
    table.nodes.forEach((row, rowIndex) => {
      const newCell = getCell
        ? getCell(columnIndex, rowIndex)
        : createCell(opts);
      editor.insertNodeByKey(row.key, columnIndex, newCell);
    });
  });

  // Update the selection (not doing can break the undo)
  return moveSelection(
    opts,
    editor,
    pos.getColumnIndex() + 1,
    pos.getRowIndex(),
  );
}
