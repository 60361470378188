import { VARIABLE_TYPES } from './variableTypes';

export const VARIABLE_COMPARATORS = {
  IS_DEFINED: {
    value: 'isDefined',
    translationKey: 'templates:defined',
    isValueToCompareRequired: false,
    availableForTypes: [
      VARIABLE_TYPES.text,
      VARIABLE_TYPES.textarea,
      VARIABLE_TYPES.integer,
      VARIABLE_TYPES.select,
      VARIABLE_TYPES.tabs,
      VARIABLE_TYPES.money,
      VARIABLE_TYPES.date,
    ],
  },
  IS_NOT_DEFINED: {
    value: 'isNotDefined',
    translationKey: 'templates:notDefined',
    isValueToCompareRequired: false,
    availableForTypes: [
      VARIABLE_TYPES.text,
      VARIABLE_TYPES.textarea,
      VARIABLE_TYPES.integer,
      VARIABLE_TYPES.select,
      VARIABLE_TYPES.tabs,
      VARIABLE_TYPES.money,
      VARIABLE_TYPES.date,
    ],
  },
  IS_EQUAL: {
    value: 'isEqual',
    translationKey: 'templates:isEqual',
    isValueToCompareRequired: true,
    availableForTypes: [
      VARIABLE_TYPES.text,
      VARIABLE_TYPES.textarea,
      VARIABLE_TYPES.integer,
      VARIABLE_TYPES.select,
      VARIABLE_TYPES.tabs,
      VARIABLE_TYPES.money,
      VARIABLE_TYPES.date,
    ],
  },
  IS_NOT_EQUAL: {
    value: 'isNotEqual',
    translationKey: 'templates:isNotEqual',
    isValueToCompareRequired: true,
    availableForTypes: [
      VARIABLE_TYPES.text,
      VARIABLE_TYPES.textarea,
      VARIABLE_TYPES.integer,
      VARIABLE_TYPES.select,
      VARIABLE_TYPES.tabs,
      VARIABLE_TYPES.money,
      VARIABLE_TYPES.date,
    ],
  },
  IS_BIGGER: {
    value: 'isBigger',
    translationKey: 'templates:isGreater',
    isValueToCompareRequired: true,
    availableForTypes: [
      VARIABLE_TYPES.integer,
      VARIABLE_TYPES.money,
      VARIABLE_TYPES.date,
    ],
  },
  IS_SMALLER: {
    value: 'isSmaller',
    translationKey: 'templates:isLess',
    isValueToCompareRequired: true,
    availableForTypes: [
      VARIABLE_TYPES.integer,
      VARIABLE_TYPES.money,
      VARIABLE_TYPES.date,
    ],
  },
  IS_BIGGER_OR_EQUAL: {
    value: 'isBiggerOrEqual',
    translationKey: 'templates:isGreaterOrEqual',
    isValueToCompareRequired: true,
    availableForTypes: [
      VARIABLE_TYPES.integer,
      VARIABLE_TYPES.money,
      VARIABLE_TYPES.date,
    ],
  },
  IS_SMALLER_OR_EQUAL: {
    value: 'isSmallerOrEqual',
    translationKey: 'templates:isLessOrEqual',
    isValueToCompareRequired: true,
    availableForTypes: [
      VARIABLE_TYPES.integer,
      VARIABLE_TYPES.money,
      VARIABLE_TYPES.date,
    ],
  },
  IS_TRUE: {
    value: 'isTrue',
    translationKey: 'templates:isTruth',
    isValueToCompareRequired: false,
    availableForTypes: [VARIABLE_TYPES.boolean],
  },
  IS_FALSE: {
    value: 'isFalse',
    translationKey: 'templates:isNotTruth',
    isValueToCompareRequired: false,
    availableForTypes: [VARIABLE_TYPES.boolean],
  },
};

export const VARIABLE_TYPES_COMPARATORS = {
  [VARIABLE_TYPES.text]: [
    VARIABLE_COMPARATORS.IS_DEFINED,
    VARIABLE_COMPARATORS.IS_NOT_DEFINED,
    VARIABLE_COMPARATORS.IS_EQUAL,
    VARIABLE_COMPARATORS.IS_NOT_EQUAL,
  ],
  [VARIABLE_TYPES.textarea]: [
    VARIABLE_COMPARATORS.IS_DEFINED,
    VARIABLE_COMPARATORS.IS_NOT_DEFINED,
    VARIABLE_COMPARATORS.IS_EQUAL,
    VARIABLE_COMPARATORS.IS_NOT_EQUAL,
  ],
  [VARIABLE_TYPES.integer]: [
    VARIABLE_COMPARATORS.IS_DEFINED,
    VARIABLE_COMPARATORS.IS_NOT_DEFINED,
    VARIABLE_COMPARATORS.IS_EQUAL,
    VARIABLE_COMPARATORS.IS_NOT_EQUAL,
    VARIABLE_COMPARATORS.IS_BIGGER,
    VARIABLE_COMPARATORS.IS_SMALLER,
    VARIABLE_COMPARATORS.IS_BIGGER_OR_EQUAL,
    VARIABLE_COMPARATORS.IS_SMALLER_OR_EQUAL,
  ],
  [VARIABLE_TYPES.select]: [
    VARIABLE_COMPARATORS.IS_DEFINED,
    VARIABLE_COMPARATORS.IS_NOT_DEFINED,
    VARIABLE_COMPARATORS.IS_EQUAL,
    VARIABLE_COMPARATORS.IS_NOT_EQUAL,
  ],
  [VARIABLE_TYPES.tabs]: [
    VARIABLE_COMPARATORS.IS_DEFINED,
    VARIABLE_COMPARATORS.IS_NOT_DEFINED,
    VARIABLE_COMPARATORS.IS_EQUAL,
    VARIABLE_COMPARATORS.IS_NOT_EQUAL,
  ],
  [VARIABLE_TYPES.money]: [
    VARIABLE_COMPARATORS.IS_DEFINED,
    VARIABLE_COMPARATORS.IS_NOT_DEFINED,
    VARIABLE_COMPARATORS.IS_EQUAL,
    VARIABLE_COMPARATORS.IS_NOT_EQUAL,
    VARIABLE_COMPARATORS.IS_BIGGER,
    VARIABLE_COMPARATORS.IS_SMALLER,
    VARIABLE_COMPARATORS.IS_BIGGER_OR_EQUAL,
    VARIABLE_COMPARATORS.IS_SMALLER_OR_EQUAL,
  ],
  [VARIABLE_TYPES.date]: [
    VARIABLE_COMPARATORS.IS_DEFINED,
    VARIABLE_COMPARATORS.IS_NOT_DEFINED,
    VARIABLE_COMPARATORS.IS_EQUAL,
    VARIABLE_COMPARATORS.IS_NOT_EQUAL,
    VARIABLE_COMPARATORS.IS_BIGGER,
    VARIABLE_COMPARATORS.IS_SMALLER,
    VARIABLE_COMPARATORS.IS_BIGGER_OR_EQUAL,
    VARIABLE_COMPARATORS.IS_SMALLER_OR_EQUAL,
  ],
  [VARIABLE_TYPES.boolean]: [
    VARIABLE_COMPARATORS.IS_TRUE,
    VARIABLE_COMPARATORS.IS_FALSE,
  ],
  [VARIABLE_TYPES.meta]: [
    VARIABLE_COMPARATORS.IS_EQUAL,
    VARIABLE_COMPARATORS.IS_NOT_EQUAL,
  ],
  [VARIABLE_TYPES.company]: [
    VARIABLE_COMPARATORS.IS_DEFINED,
    VARIABLE_COMPARATORS.IS_NOT_DEFINED,
    VARIABLE_COMPARATORS.IS_EQUAL,
    VARIABLE_COMPARATORS.IS_NOT_EQUAL,
  ],
};
