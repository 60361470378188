import { format, parseISO } from 'date-fns';

const wrapGroupInParenthesisWhenNotRoot = (group, root) =>
  root ? group : `(${group})`;

const formatValue = (value, variable) =>
  variable && variable.type === 'date'
    ? format(typeof value === 'string' ? parseISO(value) : value, 'yyyy-MM-dd')
    : value;

const conditionsTranslateTemp = {
  addRule: 'templates:conditionalWrapper.conditions.addRule',
  addGroup: 'templates:conditionalWrapper.conditions.addGroup',
  and: 'templates:conditionalWrapper.conditions.and',
  or: 'templates:conditionalWrapper.conditions.or',
  value: 'templates:conditionalWrapper.conditions.value',
  isTrue: 'templates:conditionalWrapper.conditions.isTrue',
  isFalse: 'templates:conditionalWrapper.conditions.isFalse',
  isDefined: 'templates:conditionalWrapper.conditions.isDefined',
  isNotDefined: 'templates:conditionalWrapper.conditions.isNotDefined',
  isEqual: 'templates:conditionalWrapper.conditions.isEqual',
  isNotEqual: 'templates:conditionalWrapper.conditions.isNotEqual',
  isBigger: 'templates:conditionalWrapper.conditions.isBigger',
  isSmaller: 'templates:conditionalWrapper.conditions.isSmaller',
  isBiggerOrEqual: 'templates:conditionalWrapper.conditions.isBiggerOrEqual',
  isSmallerOrEqual: 'templates:conditionalWrapper.conditions.isSmallerOrEqual',
};

export const getConditionsHumanRepresentation = (
  conditions,
  variables,
  t,
  root = true,
) => {
  if (conditions.logicalOperator) {
    return wrapGroupInParenthesisWhenNotRoot(
      conditions.conditions
        .map(v => getConditionsHumanRepresentation(v, variables, t, false))
        .join(` ${t(conditionsTranslateTemp[conditions.logicalOperator])} `),
      root,
    );
  }

  const variable = variables.find(v => {
    if (conditions.variableName) {
      return v.name === conditions.variableName.split('.')[0];
    }

    return false;
  });
  return `${conditions.variableName} ${t(
    conditionsTranslateTemp[conditions.comparator],
  )}${
    conditions.otherValue
      ? ` ${formatValue(conditions.otherValue, variable)}`
      : ''
  }`;
};

export const supportedComparatorsPerVariable = {
  text: ['isDefined', 'isNotDefined', 'isEqual', 'isNotEqual'],
  textarea: ['isDefined', 'isNotDefined', 'isEqual', 'isNotEqual'],
  integer: [
    'isDefined',
    'isNotDefined',
    'isEqual',
    'isNotEqual',
    'isBigger',
    'isSmaller',
    'isBiggerOrEqual',
    'isSmallerOrEqual',
  ],
  boolean: ['isTrue', 'isFalse'],
  select: ['isDefined', 'isNotDefined', 'isEqual', 'isNotEqual'],
  dynamicList: ['isDefined', 'isNotDefined', 'isEqual', 'isNotEqual'],
  tabs: ['isDefined', 'isNotDefined', 'isEqual', 'isNotEqual'],
  money: [
    'isDefined',
    'isNotDefined',
    'isEqual',
    'isNotEqual',
    'isBigger',
    'isSmaller',
    'isBiggerOrEqual',
    'isSmallerOrEqual',
  ],
  date: [
    'isDefined',
    'isNotDefined',
    'isEqual',
    'isNotEqual',
    'isBigger',
    'isSmaller',
    'isBiggerOrEqual',
    'isSmallerOrEqual',
  ],
  company: fieldName => {
    if (fieldName.endsWith('differentContactAddress')) {
      return supportedComparatorsPerVariable.boolean;
    }

    if (
      fieldName.endsWith('paidCapital') ||
      fieldName.endsWith('shareCapital')
    ) {
      return supportedComparatorsPerVariable.integer;
    }

    return supportedComparatorsPerVariable.text;
  },
};
