import { LegacyTablePlugin } from './legacy';

import { Table, TableCell, TableRow } from './components';

const legacyTablePlugin = LegacyTablePlugin({
  typeTable: 'table',
  typeRow: 'tableRow',
  typeCell: 'tableCell',
  typeContent: 'paragraph',
});

export const table = {
  ...legacyTablePlugin,
  renderNode: (props, editor, next) => {
    switch (props.node.type) {
      case 'table':
        return <Table plugin={legacyTablePlugin} {...props} />;
      case 'tableRow':
        return <TableRow editor={editor} {...props} />;
      case 'tableCell':
        return <TableCell {...props} />;
      default:
        return next();
    }
  },
};
