import { useTranslation } from 'react-i18next';
import { Box, Tabs, Tooltip } from '@mantine/core';
import { useParams } from 'react-router-dom';

import { Variables } from './Variables';
import { Documents } from './Documents';
import { useContext } from 'react';
import { ErrorsContext } from '../../../../../context';

export const Sidebar = () => {
  const { t } = useTranslation('templates');
  const errors = useContext(ErrorsContext);
  const { templateId } = useParams();

  const hasErrors = errors.length > 0;
  const top = hasErrors ? 255 : 180;
  const documentIsAvailable = Boolean(templateId);

  return (
    <Box sx={{ position: 'sticky', top }}>
      <Tabs defaultValue="variables">
        <Tabs.List
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
          })}
        >
          <Tabs.Tab value="variables">{t('templates:variables')}</Tabs.Tab>
          <Tooltip
            sx={{ whiteSpace: 'pre-wrap' }}
            label={t('templates:saveTemplateToAddAttachment')}
            disabled={documentIsAvailable}
          >
            <Tabs.Tab value="documents" disabled={!documentIsAvailable}>
              {t('templates:documents')}
            </Tabs.Tab>
          </Tooltip>
        </Tabs.List>
        <Tabs.Panel value="variables">
          <Variables />
        </Tabs.Panel>
        <Tabs.Panel value="documents">
          {documentIsAvailable && <Documents />}
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};
