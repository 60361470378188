import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export const useGetZodDynamicListVariableSchema = () => {
  const { t } = useTranslation('common');

  const getTextFieldWithLimitSchema = limit =>
    z
      .string()
      .trim()
      .max(limit, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: limit,
        }),
      });

  return z.object({
    initialValue: getTextFieldWithLimitSchema(256),
    placeholder: getTextFieldWithLimitSchema(256),
    label: getTextFieldWithLimitSchema(256),
    hint: getTextFieldWithLimitSchema(256),
    url: getTextFieldWithLimitSchema(2048),
    authHeader: getTextFieldWithLimitSchema(8192),
    required: z.boolean(),
  });
};
