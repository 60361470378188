import removeTableByKey from './removeTableByKey';

/**
 * Delete the whole table at position
 */
export default function removeTable(opts, change) {
  const { value } = change;
  const { start } = value.selection;
  return removeTableByKey(opts, change, start.key);
}
