import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Box, Select } from '@mantine/core';
import { flatten } from 'flat';

import { TrashIcon } from 'icons';
import { VARIABLE_TYPES } from 'legacy/TemplateCreatorEditor/consts';
import { EditorContext } from 'legacy/TemplateCreatorEditor/context';
import { getPredefinedVariables } from '../../utils';
import { OtherValue } from './components/';
import { getAvailableVariableComparators } from './utils';
import { companyInitialValue } from '../../plugins/companyWrapper/utils';

export const VariableCondition = ({
  className,
  variableName,
  comparator,
  otherValue,
  deleteCondition,
  changeComparator,
  changeVariableName,
  changeOtherValue,
}) => {
  const { t } = useTranslation('templates');

  const defaultVariableName = variableName || '';
  const defaultOtherValue = otherValue || '';

  const [internalVariableName, setInternalVariableName] =
    useState(defaultVariableName);
  const [internalOtherValue, setInternalOtherValue] =
    useState(defaultOtherValue);

  const editor = useContext(EditorContext);
  const variables = editor.getVariables();
  const { type, options } = editor.getVariable(variableName);

  const predefinedVariables = getPredefinedVariables(t);

  // @TODO it could be stored in zustand store as we re-use it in a few places
  const availableVariables = [
    ...variables
      .map(variable => ({
        ...variable,
        label: variable.name,
      }))
      .map(({ name, type, initialValue }) => {
        if (type === VARIABLE_TYPES.company) {
          const initialValueCompatibilityHelper = !initialValue
            ? companyInitialValue
            : initialValue;
          return Object.keys(flatten(initialValueCompatibilityHelper)).map(
            flattenedKey => {
              const fullKey = `${name}.${flattenedKey}`;

              return {
                value: fullKey,
                label: fullKey,
                type: VARIABLE_TYPES.company,
              };
            },
          );
        }
        return {
          value: name,
          label: name,
        };
      })
      .flat(),
    ...predefinedVariables.map(({ name, label }) => ({
      value: name,
      label: label,
      type: VARIABLE_TYPES.predefined,
    })),
  ];

  const availableVariableComparators = getAvailableVariableComparators(
    variableName,
    type,
    availableVariables,
  );

  const isOtherValueInputAvailable =
    Boolean(comparator) &&
    availableVariableComparators.length > 0 &&
    availableVariableComparators.find(({ value }) => value === comparator)
      .isValueToCompareRequired;

  useEffect(() => {
    setInternalVariableName(defaultVariableName);
  }, [variableName]);

  useEffect(() => {
    setInternalOtherValue(defaultOtherValue);
  }, [otherValue]);

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.other.spacing(1),
      })}
      className={className}
    >
      <Select
        styles={{
          root: {
            width: 300,
          },
          dropdown: {
            maxHeight: 500,
            overflow: 'auto',
          },
        }}
        searchable
        className="VariableCondition_Autocomplete"
        label={t('templates:variable')}
        placeholder={t('templates:variable')}
        error={null}
        withinPortal
        data={availableVariables}
        value={internalVariableName}
        onChange={value => {
          setInternalVariableName(value);
          changeVariableName(value);
          changeComparator(null);
        }}
      />
      <Select
        label={t('templates:operator')}
        placeholder={t('templates:choose')}
        disabled={!internalVariableName}
        withinPortal
        error={null}
        value={comparator || ''}
        data={availableVariableComparators.map(({ value, translationKey }) => ({
          value,
          label: t(translationKey),
        }))}
        onChange={changeComparator}
      />
      {isOtherValueInputAvailable && (
        <OtherValue
          internalOtherValue={internalOtherValue}
          setInternalOtherValue={setInternalOtherValue}
          type={type}
          options={options}
          changeOtherValue={changeOtherValue}
        />
      )}
      {Boolean(deleteCondition) && (
        <ActionIcon
          sx={theme => ({
            alignSelf: 'flex-end',
            margin: theme.other.spacing(0, 0, '5px', 0.5),
          })}
          onClick={deleteCondition}
        >
          <TrashIcon color="red" />
        </ActionIcon>
      )}
    </Box>
  );
};
