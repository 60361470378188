import { useTranslation } from 'react-i18next';
import { Grid, Checkbox, Text } from '@mantine/core';
import { AddButtonSubtle, DescriptionField } from 'components/shared';

export const Required = ({
  areConditionsEnabled = false,
  checkboxProps,
  onOpenConditionsManagement,
  withBorder = false,
}) => {
  const { t } = useTranslation('templates');

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.isRequired')}
      withoutBorder={!withBorder}
      bottomSection={
        areConditionsEnabled ? (
          <Grid>
            <Grid.Col xs={12} sm={5}>
              <Text size="md" weight={600}>
                {t('templates:templateCreatorVariables.requiredWhen')}
              </Text>
            </Grid.Col>
            <Grid.Col xs={12} sm={7}>
              <AddButtonSubtle
                sx={theme => ({
                  fontSize: theme.fontSizes.lg,
                  paddingRight: theme.other.spacing(1),
                  paddingLeft: theme.other.spacing(1),
                })}
                onClick={onOpenConditionsManagement}
              >
                {t('templates:templateCreatorVariables.addCondition')}
              </AddButtonSubtle>
            </Grid.Col>
          </Grid>
        ) : undefined
      }
    >
      <Checkbox {...checkboxProps} sx={{ margin: 0 }} />
    </DescriptionField>
  );
};
