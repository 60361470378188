import { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactCropperElement } from 'react-cropper';
import { Avatar, Button } from '@mantine/core';

import { useDeleteAvatar, useGetUser, usePostAvatar } from 'api/account';
import { STANDARD_IMAGE_EXTENSIONS } from 'consts';
import { TrashIcon } from 'icons';
import { readFile } from 'helpers';
import { useServerErrors } from 'hooks';
import { UserType } from 'types';
import { FileDropzone, Modal } from 'components/shared';

import { IMAGE_SQUARE_SIZE } from './consts';
import { AvatarCropper } from './AvatarCropper';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const EditAvatarModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation(['common', 'user']);

  const { addServerErrorActions, getServerFieldError } = useServerErrors();

  const { data: userData } = useGetUser();
  const user = userData as UserType;
  const { mutateAsync: uploadAvatar, isLoading: isUploadingAvatar } =
    usePostAvatar();
  const { mutateAsync: deleteAvatar, isLoading: isDeletingAvatar } =
    useDeleteAvatar();

  const isUserAvatarAvailable = Boolean(user.avatar);

  const [avatarDataUrl, setAvatarDataUrl] = useState('');
  const [avatarError, setAvatarError] = useState('');
  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);

  const cropperRef = createRef<ReactCropperElement>();

  const resetState = () => {
    setAvatarDataUrl('');
    setAvatarError('');
    setIsDeleteButtonClicked(false);
  };

  const close = () => {
    onClose();
    resetState();
  };

  const handleUploadAvatar = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper
        .getCroppedCanvas({
          width: IMAGE_SQUARE_SIZE,
          height: IMAGE_SQUARE_SIZE,
        })
        .toBlob(async avatarBlob => {
          const formData = new FormData();

          formData.append('avatar', avatarBlob as Blob);

          try {
            await uploadAvatar({
              formData,
            });
            close();
          } catch (error) {
            setAvatarError(getServerFieldError(error, 'avatar'));
            addServerErrorActions(error);
          }
        });
    }
  };
  const handleDeleteAvatar = async () => {
    try {
      await deleteAvatar();
      close();
    } catch (error) {
      setAvatarError(getServerFieldError(error, 'avatar'));
      addServerErrorActions(error);
    }
  };

  const deleteButton = (
    <Button
      sx={theme => ({
        display: 'block',
        margin: theme.other.spacing(2, 'auto', 0),
      })}
      color="red"
      onClick={() => {
        setIsDeleteButtonClicked(true);
        setAvatarDataUrl('');
        setAvatarError('');
      }}
    >
      <TrashIcon
        sx={theme => ({
          marginRight: theme.other.spacing(1),
        })}
      />
      {t('common:delete')}
    </Button>
  );

  const isLoading = isUploadingAvatar || isDeletingAvatar;

  return (
    <Modal
      title={t('user:editAvatar')}
      isOpen={isOpen}
      size="md"
      onClose={close}
      defaultButtonAction={close}
      defaultButtonDisabled={isLoading}
      primaryButtonIsLoading={isLoading}
      primaryButtonAction={async () => {
        if (avatarDataUrl) {
          handleUploadAvatar();
        } else if (isDeleteButtonClicked && isUserAvatarAvailable) {
          await handleDeleteAvatar();
        } else {
          close();
        }
      }}
    >
      {isUserAvatarAvailable && !isDeleteButtonClicked ? (
        <>
          <Avatar sx={{ margin: 'auto' }} size={100} radius={100}>
            <img src={user.avatar as string} alt="" />
          </Avatar>
          {deleteButton}
        </>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {avatarDataUrl && !isLoading ? (
            <>
              <AvatarCropper
                avatarError={avatarError}
                cropperRef={cropperRef}
                avatarDataUrl={avatarDataUrl}
              />
              {deleteButton}
            </>
          ) : (
            <FileDropzone
              error={avatarError}
              setError={setAvatarError}
              onFileDrop={async uploadedFile => {
                const fileDataUrl = await readFile(uploadedFile, 'dataUrl');

                setAvatarDataUrl(fileDataUrl);
              }}
              isLoading={isLoading}
              accept={STANDARD_IMAGE_EXTENSIONS}
              acceptText={STANDARD_IMAGE_EXTENSIONS.filter(
                extension => extension !== '.jpeg',
              ).join(', ')}
            />
          )}
        </>
      )}
    </Modal>
  );
};
