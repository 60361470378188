import { useTranslation } from 'react-i18next';
import { ActionIcon, Box } from '@mantine/core';

import { TrashIcon } from 'icons';
import {
  AddButtonSubtle,
  DescriptionField,
  TextInput,
} from 'components/shared';

export const Options = ({
  optionsFieldArray: { fields, append, remove },
  register,
  error,
  clearOptionsError,
}) => {
  const { t } = useTranslation('templates');

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.options')}
      description={t('templates:templateCreatorVariables.tabsDescription')}
      error={error}
    >
      <Box sx={{ maxHeight: 400, overflowY: 'auto' }} component="ul">
        {fields.map(({ id, value }, index) => {
          const inputRegisteredProps = {
            ...register(`options.${index}.value`),
          };

          return (
            <Box
              sx={theme => ({
                display: 'flex',
                marginBottom: theme.other.spacing(2),
                columnGap: theme.other.spacing(1),
              })}
              component="li"
              key={id}
            >
              <TextInput
                sx={{ width: '100%' }}
                {...inputRegisteredProps}
                rightIcon={
                  fields.length > 1 ? (
                    <ActionIcon
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      <TrashIcon color="red" />
                    </ActionIcon>
                  ) : undefined
                }
                placeholder={`${t('templates:templateCreatorVariables.tab')} ${
                  index + 1
                }`}
                withErrorWrapperAlwaysEnabled={false}
                onChange={e => {
                  clearOptionsError();
                  inputRegisteredProps.onChange(e);
                }}
              />
            </Box>
          );
        })}
      </Box>
      <AddButtonSubtle
        sx={theme => ({
          fontSize: theme.fontSizes.lg,
          paddingRight: theme.other.spacing(1),
          paddingLeft: theme.other.spacing(1),
        })}
        onClick={() => {
          append({ value: '' });
        }}
      >
        {t('templates:templateCreatorVariables.addNext')}
      </AddButtonSubtle>
    </DescriptionField>
  );
};
