import { ComponentProps } from 'react';
import { packSx } from '@mantine/core';

import { DescriptionField } from 'components/shared';

type Props = ComponentProps<typeof DescriptionField>;

export const TemplateDescriptionField = ({
  sx,
  ...descriptionFieldProps
}: Props) => (
  <DescriptionField sx={[...packSx(sx)]} {...descriptionFieldProps} />
);
