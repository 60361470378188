import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { EditorActionButton } from '../EditorActionButton';
import { Punctuation } from './Punctuation';

export const SecondarySection = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const { t } = useTranslation('templates');

  return (
    <Box sx={theme => ({ display: 'flex', gap: theme.other.spacing(2) })}>
      <Punctuation />
      <EditorActionButton
        onClick={() => {
          setIsSidebarOpen(open => !open);
        }}
      >
        {t('templates:variablesAndDocuments')}
        <Text span size="md" color="violet">
          {isSidebarOpen
            ? t('templates:toolbar.hide')
            : t('templates:toolbar.show')}
        </Text>
      </EditorActionButton>
    </Box>
  );
};
