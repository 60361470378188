import { Box, Tabs } from '@mantine/core';
import { FormTextField } from './FormTextField';
import { FormSelectField } from './FormSelectField';
import {
  companyBasicFields,
  CONTRACTOR_TYPES,
  individualBasicFields,
} from '../constants';
import { FormPhoneInputField } from './FormPhoneInputField';

export const BasicTabPanel = ({
  register,
  formErrors,
  control,
  companyType,
  taxOffices,
  setValue,
}) => {
  const fields =
    companyType === CONTRACTOR_TYPES.NATURAL_PERSON
      ? individualBasicFields(taxOffices)
      : companyBasicFields(companyType);

  return (
    <Tabs.Panel value="basic">
      <Box>
        {fields.map((field, fieldIndex) => {
          if (field.name === 'phone') {
            return (
              <FormPhoneInputField
                key={`${field.name}_${fieldIndex}`}
                control={control}
                error={formErrors?.[field.name]?.message || ''}
                field={field}
                withoutBorder={fieldIndex === fields.length - 1}
              />
            );
          }

          if (!Boolean(field.options)) {
            return (
              <FormTextField
                key={`${field.name}_${fieldIndex}`}
                inputProps={{
                  ...register(field.name),
                  onKeyDown: ['pesel', 'nip'].includes(field.name)
                    ? event => {
                        const isNumberKey =
                          event.key >= '0' && event.key <= '9';
                        const isControlKey =
                          event.key === 'Backspace' ||
                          event.key === 'Delete' ||
                          event.key === 'ArrowLeft' ||
                          event.key === 'ArrowRight' ||
                          event.key === 'Tab' ||
                          (event.ctrlKey &&
                            (event.key === 'v' ||
                              event.key === 'c' ||
                              event.key === 'x')) ||
                          event.metaKey;

                        if (!isNumberKey && !isControlKey) {
                          event.preventDefault();
                        }
                      }
                    : undefined,
                  onPaste: event => {
                    event.preventDefault();
                    const pastedText = event.clipboardData.getData('Text');
                    const sanitizedText = pastedText.replace(/-/g, '');

                    setValue(field.name, sanitizedText);
                  },
                  type: ['nip', 'pesel', 'regon'].includes(field.name)
                    ? 'number'
                    : undefined,
                }}
                error={formErrors?.[field.name]?.message || ''}
                field={field}
                withErrorWrapperAlwaysEnabled={false}
                withoutBorder={fieldIndex === fields.length - 1}
              />
            );
          }

          if (Boolean(field.options)) {
            return (
              <FormSelectField
                key={`${field.name}_${fieldIndex}`}
                control={control}
                error={formErrors?.[field.name]?.message || ''}
                field={field}
                withoutBorder={fieldIndex === fields.length - 1}
              />
            );
          }
        })}
      </Box>
    </Tabs.Panel>
  );
};
