import { Box, Tabs } from '@mantine/core';
import { Modal } from 'components/shared';
import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  AddressTabPanel,
  BasicTabPanel,
  DetailsTabPanel,
  RepresentativesTabPanel,
  PartnersTabPanel,
} from './components';
import { useGetCompanyFromGus, usePostCompany } from 'api/company/hooks';
import { useGetZodCompanyModalSchema } from './hooks';
import {
  companyBasicFields,
  CONTRACTOR_TYPES,
  detailsFields,
  formDefaultValues,
  individualBasicFields,
} from './constants';
import { zodResolver } from '@hookform/resolvers/zod';
import { TabsErrorIcon } from './components/TabsErrorIcon';
import { useGetCurrentBoardId, useShowNotification } from 'hooks';
import { useQueryClient } from '@tanstack/react-query';
import { API_QUERY_KEYS } from 'consts';
import { CompanyGusSearch } from './components/CompanyGusSearch';
import { getServerErrorStatus } from '../../../hooks/useServerErrors';
import { useGetRegistryCourts, useGetTaxOffices } from 'api/dictionaries/hooks';

export const CompanyModal = ({ handleClose }) => {
  const [activeTab, setActiveTab] = useState('basic');
  const [companySearchValue, setCompanySearchValue] = useState('');
  const [companySearchError, setCompanySearchError] = useState(null);
  const companyModalFormRef = useRef(null);

  const schema = useGetZodCompanyModalSchema();

  const { data: taxOffices } = useGetTaxOffices();
  const { data: registryCourts } = useGetRegistryCourts();

  const {
    formState: { errors: formErrors },
    register,
    setValue,
    watch,
    control,
    handleSubmit,
    setError,
    clearErrors,
  } = useForm({
    resolver: zodResolver(schema),
    mode: 'all',
    defaultValues: formDefaultValues,
  });

  const representativesFieldArray = useFieldArray({
    control,
    name: 'representatives',
  });
  const partnersFieldArray = useFieldArray({
    control,
    name: 'partners',
  });

  const companyType = watch('companyType');
  const selectedGroup = watch('groups');

  useEffect(() => {
    clearErrors();
  }, [companyType, clearErrors]);

  const { refetch, isInitialLoading: isGetCompanyFromGusInitialLoading } =
    useGetCompanyFromGus(
      { queryParams: { nip: companySearchValue } },
      {
        enabled: false,
        onSuccess: data => {
          const {
            address: { city, street, zipCode } = {},
            companyName,
            companyType,
            name,
            nip,
            regon,
            surname,
          } = data;

          setValue('companyType', companyType);
          setValue('companyName', companyName);
          setValue('address.city', city);
          setValue('address.street', street);
          setValue('address.zipCode', zipCode);
          setValue('nip', nip);
          setValue('regon', regon);
          setValue('name', name);
          setValue('surname', surname);
        },
      },
    );

  const currentBoardId = useGetCurrentBoardId();
  const queryClient = useQueryClient();

  const showNotification = useShowNotification();

  const { mutate: postCompany } = usePostCompany(
    {
      pathParams: { boardId: currentBoardId },
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [API_QUERY_KEYS.companiesList],
        });
        handleClose();
        showNotification({
          message: 'Kontrahent został pomyślnie dodany',
        });
      },
      onError: error => {
        const validationServerErrors = getServerErrorStatus(error);

        for (const fieldKey of Object.keys(validationServerErrors)) {
          setError(fieldKey, { message: validationServerErrors[fieldKey] });
        }
      },
    },
  );

  const hasBasicTabErrors = (
    companyType === CONTRACTOR_TYPES.NATURAL_PERSON
      ? individualBasicFields(taxOffices)
      : companyBasicFields(companyType)
  ).some(field => Object.keys(formErrors).includes(field.name));

  const hasDetailsTabError = detailsFields(companyType, registryCourts).some(
    field => Object.keys(formErrors).includes(field.name),
  );

  const hasAddressTabErrors = ['address', 'contactAddress'].some(field =>
    Object.keys(formErrors).includes(field),
  );

  const hasRepresentativesTabErrors = ['representatives'].some(field =>
    Object.keys(formErrors).includes(field),
  );

  const hasPartnersTabErrors = ['partners'].some(field =>
    Object.keys(formErrors).includes(field),
  );

  const handleFormSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    handleSubmit(values => {
      postCompany({
        bodyParams: {
          ...values,
          contrator: false, // just random field from an old API
          representatives:
            values.representatives.length > 0
              ? values.representatives.map(r => r.value)
              : [],
          groups: values.groups !== '' ? [values.groups] : undefined,
        },
      });
    })(e);
  };

  return (
    <Modal
      isOpen={true}
      size="lg"
      onClose={handleClose}
      title="Dodawanie kontrahenta"
      defaultButtonText="Anuluj"
      defaultButtonAction={handleClose}
      primaryButtonText="Utwórz"
      primaryButtonColor="green"
      primaryButtonAction={() => {
        companyModalFormRef.current?.requestSubmit();
      }}
    >
      {activeTab === 'basic' && (
        <Box>
          <CompanyGusSearch
            fetchCompany={refetch}
            isLoading={isGetCompanyFromGusInitialLoading}
            companySearchValue={companySearchValue}
            setCompanySearchValue={setCompanySearchValue}
            companySearchError={companySearchError}
            setCompanySearchError={setCompanySearchError}
          />
        </Box>
      )}
      <Box
        sx={theme => ({
          marginTop: activeTab === 'basic' ? theme.other.spacing(3) : undefined,
        })}
      >
        <form
          ref={companyModalFormRef}
          key="company-modal-form"
          onSubmit={handleFormSubmit}
        >
          <Tabs value={activeTab} onTabChange={setActiveTab}>
            <Tabs.List
              sx={theme => ({
                marginBottom: theme.other.spacing(2),
              })}
            >
              <Tabs.Tab
                value="basic"
                rightSection={hasBasicTabErrors ? <TabsErrorIcon /> : undefined}
              >
                Podstawowe
              </Tabs.Tab>
              <Tabs.Tab
                value="details"
                rightSection={
                  hasDetailsTabError ? <TabsErrorIcon /> : undefined
                }
              >
                Szczegóły
              </Tabs.Tab>
              <Tabs.Tab
                value="address"
                rightSection={
                  hasAddressTabErrors ? <TabsErrorIcon /> : undefined
                }
              >
                Adres
              </Tabs.Tab>
              <Tabs.Tab
                value="representatives"
                rightSection={
                  hasRepresentativesTabErrors ? <TabsErrorIcon /> : undefined
                }
              >
                Przedstawiciele
              </Tabs.Tab>
              {companyType === CONTRACTOR_TYPES.CYWILNA && (
                <Tabs.Tab
                  value="partners"
                  rightSection={
                    hasPartnersTabErrors ? <TabsErrorIcon /> : undefined
                  }
                >
                  Wspólnicy
                </Tabs.Tab>
              )}
            </Tabs.List>
            <BasicTabPanel
              register={register}
              formErrors={formErrors}
              control={control}
              companyType={companyType}
              taxOffices={taxOffices}
              setValue={setValue}
            />
            <DetailsTabPanel
              register={register}
              formErrors={formErrors}
              setValue={setValue}
              selectedGroup={selectedGroup}
              companyType={companyType}
              control={control}
              registryCourts={registryCourts}
            />
            <AddressTabPanel
              register={register}
              formErrors={formErrors}
              watch={watch}
            />
            <RepresentativesTabPanel
              register={register}
              formErrors={formErrors}
              representativesFieldArray={representativesFieldArray}
            />
            {companyType === CONTRACTOR_TYPES.CYWILNA && (
              <PartnersTabPanel
                register={register}
                formErrors={formErrors}
                partnersFieldArray={partnersFieldArray}
              />
            )}
          </Tabs>
        </form>
      </Box>
    </Modal>
  );
};
