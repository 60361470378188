import { DescriptionField } from 'components/shared';
import { Checkbox } from '@mantine/core';

export const FormCheckboxField = ({
  checkboxProps,
  error,
  field,
  withoutBorder = false,
}) => {
  return (
    <DescriptionField
      label={field.label}
      description={field.description}
      error={error}
      withoutBorder={withoutBorder}
    >
      <Checkbox {...checkboxProps} sx={{ margin: 0 }} />
    </DescriptionField>
  );
};
