import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { usePatchTemplate } from 'api/templates';
import { DYNAMIC_URLS } from 'consts';
import { useServerErrors, useShowNotification } from 'hooks';
import { AttachmentTemplateType } from 'types';
import { TemplateAttachmentForm } from 'legacy/TemplateAttachmentForm';

type Props = {
  attachment: AttachmentTemplateType;
  attachmentIndex: number;
  attachmentTemplates: AttachmentTemplateType[];
  onPreviewOpen: () => void;
};

export const TemplateAttachmentFormHandler = ({
  attachment,
  attachmentIndex,
  attachmentTemplates,
  onPreviewOpen,
}: Props) => {
  const { t } = useTranslation('templates');

  const navigate = useNavigate();
  const { templateId } = useParams() as { templateId: string };

  const { addServerErrorActions, getServerFieldError } = useServerErrors();
  const showNotification = useShowNotification();

  const [nameError, setNameError] = useState('');

  const editorRef = useRef<{
    editorValueJson: object;
    setGeneralError: Dispatch<SetStateAction<string>>;
    triggerValidationAndGetErrors: () => string[];
  }>(null);

  const { mutateAsync: editTemplate, isLoading: isEditingTemplate } =
    usePatchTemplate({ pathParams: { templateId } });

  return (
    <TemplateAttachmentForm
      attachmentDefaultName={attachment.type}
      attachmentDefaultValue={attachment.data}
      isSaving={isEditingTemplate}
      nameError={nameError}
      setNameError={setNameError}
      editorRef={editorRef}
      onSubmit={async ({
        name,
        attachmentData,
      }: {
        name: string;
        attachmentData: AttachmentTemplateType['data'];
      }) => {
        if (
          attachmentTemplates.some(
            ({ type }, index) => type === name && index !== attachmentIndex,
          )
        ) {
          setNameError(t('templates:attachmentTemplateNameDuplication'));
        } else {
          const attachmentsToUpdate = [...attachmentTemplates];

          attachmentsToUpdate.splice(attachmentIndex, 1, {
            type: name,
            data: attachmentData,
          });

          try {
            await editTemplate({
              attachmentTemplates: attachmentsToUpdate,
            });

            showNotification({
              message: t('templates:documentsInTemplateUpdated'),
            });

            navigate(
              DYNAMIC_URLS.templateAttachmentEdit({
                templateId,
                attachmentName: name,
              }),
            );
          } catch (error) {
            const fieldPrefix = `attachmentTemplates.${attachmentIndex}`;

            setNameError(getServerFieldError(error, `${fieldPrefix}.type`));

            editorRef.current?.setGeneralError(
              getServerFieldError(error, `${fieldPrefix}.data`),
            );

            addServerErrorActions(error);
          }
        }
      }}
      onPreviewOpen={onPreviewOpen}
    />
  );
};
