import { useTranslation } from 'react-i18next';

import { DescriptionField, TextInput } from 'components/shared';

export const AuthHeader = ({ inputProps, error, onBlur }) => {
  const { t } = useTranslation('templates');

  return (
    <DescriptionField
      label={t(
        'templates:templateCreatorVariables.dynamicList.authHeaderField.label',
      )}
      description={t(
        'templates:templateCreatorVariables.dynamicList.authHeaderField.description',
      )}
      error={error}
    >
      <TextInput
        {...inputProps}
        placeholder={t(
          'templates:templateCreatorVariables.dynamicList.authHeaderField.label',
        )}
        withErrorWrapperAlwaysEnabled={false}
        onBlur={onBlur}
      />
    </DescriptionField>
  );
};
