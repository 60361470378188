import { useGetColor } from 'hooks';

import { Box } from '@mantine/core';

import { IconPropsType } from 'icons/types';

export const InsertRowIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="-4 -3 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.3178 11.6613C10.578 11.6613 10.8381 11.7588 11.0333 11.9539C11.2284 12.1491 11.3259 12.393 11.3259 12.6694V14.6694H13.3259C13.5861 14.6694 13.8463 14.7669 14.0414 14.9621C14.2365 15.1572 14.3341 15.4011 14.3341 15.6775C14.3341 15.9539 14.2365 16.1978 14.0414 16.393C13.8463 16.5881 13.6024 16.6856 13.3259 16.6856H11.3259V18.6856C11.3259 18.9458 11.2284 19.206 11.0333 19.4011C10.8381 19.5962 10.5942 19.6938 10.3178 19.6938C10.0414 19.6938 9.79748 19.5962 9.60236 19.4011C9.40724 19.206 9.30968 18.9621 9.30968 18.6856V16.6856H7.32594C7.06578 16.6856 6.80561 16.5881 6.61049 16.393C6.41537 16.1978 6.31781 15.9539 6.31781 15.6775C6.31781 15.4011 6.41537 15.1572 6.61049 14.9621C6.80561 14.7669 7.04952 14.6694 7.32594 14.6694H9.32594V12.6694C9.32594 12.4092 9.4235 12.1491 9.61862 11.9539C9.81375 11.7588 10.0576 11.6613 10.3341 11.6613H10.3178Z"
        fill={iconColor}
      />
      <path
        d="M17 0.753418C18.2426 0.753418 19.25 1.76078 19.25 3.00342V8.00342C19.25 9.24606 18.2426 10.2534 17 10.2534H3C1.75736 10.2534 0.75 9.24606 0.75 8.00342V3.00342C0.75 1.76078 1.75736 0.753418 3 0.753418H17Z"
        stroke={iconColor}
        stroke-width="1.5"
      />
      <line
        x1="12.75"
        y1="1.00342"
        x2="12.75"
        y2="11.0034"
        stroke={iconColor}
        strokeWidth="1.5"
      />
      <line
        x1="6.75"
        y1="1.00342"
        x2="6.75"
        y2="11.0034"
        stroke={iconColor}
        strokeWidth="1.5"
      />
    </Box>
  );
};
