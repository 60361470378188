import { VARIABLE_TYPES } from '../consts';

import { BooleanVariable } from './BooleanVariable';
import { CompanyVariable } from './CompanyVariable';
import { DateVariable } from './DateVariable';
import { DynamicListVariable } from './DynamicListVariable';
import { IntegerVariable } from './IntegerVariable';
import { MoneyVariable } from './MoneyVariable';
import { SelectVariable } from './SelectVariable';
import { TabsVariable } from './TabsVariable';
import { TextVariable } from './TextVariable';
import { TextareaVariable } from './TextareaVariable';

const getComponent = variableType => {
  switch (variableType) {
    case VARIABLE_TYPES.text: {
      return TextVariable;
    }
    case VARIABLE_TYPES.textarea: {
      return TextareaVariable;
    }
    case VARIABLE_TYPES.integer: {
      return IntegerVariable;
    }
    case VARIABLE_TYPES.boolean: {
      return BooleanVariable;
    }
    case VARIABLE_TYPES.select: {
      return SelectVariable;
    }
    case VARIABLE_TYPES.tabs: {
      return TabsVariable;
    }
    case VARIABLE_TYPES.money: {
      return MoneyVariable;
    }
    case VARIABLE_TYPES.date: {
      return DateVariable;
    }
    case VARIABLE_TYPES.company: {
      return CompanyVariable;
    }
    case VARIABLE_TYPES.dynamicList: {
      return DynamicListVariable;
    }
    default: {
      return null;
    }
  }
};

export const VariableTypeFields = ({
  variableType,
  fieldsInstanceRef,
  initialValues,
  onOpenConditionsManagement,
}) => {
  const FieldsComponent = getComponent(variableType);

  return FieldsComponent ? (
    <FieldsComponent
      fieldsInstanceRef={fieldsInstanceRef}
      initialValues={initialValues}
      onOpenConditionsManagement={onOpenConditionsManagement}
    />
  ) : null;
};
