import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const TEXT_LIMIT = 255;

export const useGetZodDateVariableSchema = () => {
  const { t } = useTranslation('common');

  return z.object({
    initialValue: z.string().nullable(),
    placeholder: z
      .string()
      .trim()
      .max(TEXT_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: TEXT_LIMIT,
        }),
      }),
    label: z
      .string()
      .trim()
      .max(TEXT_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: TEXT_LIMIT,
        }),
      }),
    hint: z
      .string()
      .trim()
      .max(TEXT_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: TEXT_LIMIT,
        }),
      }),
    minYearDelta: z.number().nullable(),
    maxYearDelta: z.number().nullable(),
    required: z.boolean(),
  });
};
