import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Tooltip } from '@mantine/core';

import { BRANDING_SOURCE } from 'consts';
import { useFormatUserDate, useGetOrganisationFeatureAccess } from 'hooks';
import { LetterheadIcon, NoLetterheadIcon } from 'icons';
import { Status } from 'components/shared';
import { TemplateStatuses } from 'components/Templates';

import { BoardTemplateLocalListItemType } from '../../types';
import { TemplateDetailsRow } from './TemplateDetailsRow';
import { TemplateRowLink } from './TemplateRowLink';

type Props = {
  isChecked: boolean;
  isLocalTemplate: boolean;
  isBase: boolean;
  isOneWay: boolean;
  isOffer: boolean;
  hasDetailsAccess: boolean;
  letterheadSource:
    | (typeof BRANDING_SOURCE)[keyof typeof BRANDING_SOURCE]
    | null;
  id: BoardTemplateLocalListItemType['id'];
  name: string;
  updatedAt: BoardTemplateLocalListItemType['updatedAt'];
  onCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void;
  refetchGetBoardTemplates: () => void;
};

export const TemplateRow = ({
  isChecked,
  isLocalTemplate,
  isBase,
  isOneWay,
  isOffer,
  hasDetailsAccess,
  letterheadSource,
  id,
  name,
  updatedAt,
  onCheckboxChange,
  refetchGetBoardTemplates,
}: Props) => {
  const { t } = useTranslation('templates');
  const [isExpanded, setIsExpanded] = useState(false);
  const formatUserDate = useFormatUserDate();
  const { data: isTemplateCreatorV2Enabled } =
    useGetOrganisationFeatureAccess('templateCreatorV2');

  return (
    <>
      <Box
        sx={theme => ({
          '&&': {
            backgroundColor: isChecked ? theme.colors.blue[1] : undefined,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.colors.blue[1],
            },
          },
        })}
        component="tr"
        onClick={() => {
          setIsExpanded(expanded => !expanded);
        }}
      >
        <td>
          <Checkbox
            sx={{ marginBottom: 0 }}
            checked={isChecked}
            onChange={onCheckboxChange}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        </td>
        <td>
          <Box sx={{ display: 'flex', alignItems: 'center' }} component="span">
            {letterheadSource === 'template' && (
              <Tooltip label={t('templates:attachedLetterhead')}>
                <span>
                  <LetterheadIcon
                    sx={theme => ({
                      marginRight: theme.other.spacing(0.5),
                    })}
                    color="primary"
                  />
                </span>
              </Tooltip>
            )}
            {letterheadSource === 'disabled' && (
              <Tooltip label={t('templates:noLetterhead')}>
                <span>
                  <NoLetterheadIcon
                    sx={theme => ({
                      marginRight: theme.other.spacing(0.5),
                    })}
                    color="orange"
                  />
                </span>
              </Tooltip>
            )}
            {hasDetailsAccess && isTemplateCreatorV2Enabled !== undefined ? (
              <TemplateRowLink
                name={name}
                templateId={id.toString()}
                v2Enabled={isTemplateCreatorV2Enabled}
                isLocalTemplate={isLocalTemplate}
              />
            ) : (
              name
            )}
          </Box>
          {isLocalTemplate && (
            <Status
              sx={theme => ({
                marginTop: theme.other.spacing(0.5),
              })}
              label={t('templates:templateTypes.draft')}
              dotSize={0}
            />
          )}
        </td>
        <td>{isLocalTemplate ? '...' : id}</td>
        <td>{formatUserDate(updatedAt)}</td>
        <td>
          <TemplateStatuses
            canEditByNegotiator={false}
            isBase={isBase}
            isOffer={isOffer}
            isOneWay={isOneWay}
          />
        </td>
      </Box>
      <TemplateDetailsRow
        templateId={id}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        refetchGetBoardTemplates={refetchGetBoardTemplates}
      />
    </>
  );
};
