import { MantineTheme } from '@mantine/core';

import { ThemeComponentType } from '../types';

export const getDayStyles = (theme: MantineTheme) => ({
  '&:hover': {
    backgroundColor: theme.colors.gray[1],
  },
  '&[data-selected="true"]': {
    backgroundColor: theme.colors[theme.primaryColor][0],
  },
  '&[data-in-range="true"]': {
    backgroundColor: theme.colors[theme.primaryColor][1],
    borderRadius: 0,
    color: theme.colors.gray[7],
    '&[data-selected="true"]': {
      color: theme.white,
      backgroundColor: theme.colors[theme.primaryColor][0],
    },
  },
  '&[data-first-in-range="true"]': {
    borderRadius: `${theme.radius.sm}px 0 0 ${theme.radius.sm}px`,
  },
  '&[data-last-in-range="true"]': {
    borderRadius: `0 ${theme.radius.sm}px ${theme.radius.sm}px 0`,
  },
});

export const Calendar: ThemeComponentType = {
  styles: theme => ({
    day: getDayStyles(theme),
    calendarHeaderLevel: {
      '&:hover': {
        backgroundColor: theme.colors.gray[1],
      },
    },
    monthPickerControl: {
      '&:hover': {
        backgroundColor: theme.colors.gray[1],
      },
    },
    monthPickerControlActive: {
      '&:hover': {
        backgroundColor: theme.colors[theme.primaryColor][0],
      },
    },
    yearPickerControl: {
      '&:hover': {
        backgroundColor: theme.colors.gray[1],
      },
    },
    yearPickerControlActive: {
      '&:hover': {
        backgroundColor: theme.colors[theme.primaryColor][0],
      },
    },
  }),
};
