import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const TEXT_LIMIT = 255;

export const useGetZodCompanyVariableSchema = () => {
  const { t } = useTranslation('common');

  return z.object({
    placeholder: z
      .string()
      .trim()
      .max(TEXT_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: TEXT_LIMIT,
        }),
      }),
    label: z
      .string()
      .trim()
      .max(TEXT_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: TEXT_LIMIT,
        }),
      }),
    hint: z
      .string()
      .trim()
      .max(TEXT_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: TEXT_LIMIT,
        }),
      }),
    initialValue: z
      .object({
        id: z.number(),
        companyType: z.string().nullable(),
        name: z.string().nullable(),
        nip: z.string().nullable(),
        surname: z.string().nullable(),
        companyName: z.string().nullable(),
        regon: z.string().nullable(),
        address: z
          .object({
            city: z.string().nullable(),
            street: z.string().nullable(),
            zipCode: z.string().nullable(),
          })
          .nullable(),
        bankAccount: z.string().nullable(),
        contactAddress: z
          .object({
            city: z.string().nullable(),
            street: z.string().nullable(),
            zipCode: z.string().nullable(),
          })
          .nullable(),
        representatives: z.array(z.any()).nullable(),
        partners: z.array(z.any()).nullable(),
        differentContactAddress: z.boolean(),
        phone: z.string().nullable(),
        email: z.string().email().nullable(),
        shareCapital: z.string().nullable(),
        krs: z.string().nullable(),
        pesel: z.string().nullable(),
        paidCapital: z.number().nullable(),
        contractor: z.string().nullable(),
        registryCourt: z.string().nullable(),
        taxOffice: z.string().nullable(),
        motherName: z.string().nullable(),
        fatherName: z.string().nullable(),
        board: z.number().nullable(),
        owner: z.number().nullable(),
        internalName: z.string().nullable(),
        internalNumber: z.string().nullable(),
        identityDoc: z.string().nullable(),
        groups: z.array(z.any()).nullable(),
      })
      .nullable()
      .optional(),
    required: z.boolean(),
  });
};
