import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { getGoogleAnalyticsHelper } from 'helpers';
import { CookiesCategoryType, CookiesSettingsType } from 'types';

import { initialCookiesSettings } from './helpers';
import type { CookiesStoreType } from './types';

export const useCookiesStore = create<CookiesStoreType>()(
  persist(
    (set, get) => {
      const { clearCookies: clearGaCookies, initialize: initializeGa } =
        getGoogleAnalyticsHelper();

      return {
        settings: initialCookiesSettings,
        savedSettings: null,
        toggleSetting: (category: CookiesCategoryType) => {
          if (!category.canBeDisabled) return;

          set({
            settings: {
              ...get().settings,
              [category.code]: !get().settings[category.code],
            },
          });
        },
        setAllSettingsValues: (value: boolean) => {
          const settings = { ...get().settings };

          Object.keys(settings).forEach(key => {
            settings[key as keyof CookiesSettingsType] = value;
          });

          set({ settings });
        },
        saveSettingsToLocalStorage: () => {
          const { settings, savedSettings } = get();

          if (savedSettings === null && settings.analytics) {
            initializeGa();
          }

          set({ savedSettings: settings });
        },
        _setSettings: (settings: CookiesSettingsType) => {
          set({ settings });
        },

        promptModal: {
          isOpen: true,
          close: () => {
            set({ promptModal: { ...get().promptModal, isOpen: false } });
          },
        },
        settingsModal: {
          isOpen: false,
          wasTriggeredByUser: false,
          open: (byUser: boolean = false) => {
            set({
              settingsModal: {
                ...get().settingsModal,
                isOpen: true,
                wasTriggeredByUser: byUser,
              },
            });
          },
          close: () => {
            set({ settingsModal: { ...get().settingsModal, isOpen: false } });
          },
        },

        shouldClearGaCookiesOnNextPageLoad: null,
        reloadWindowAndClearGaCookies: () => {
          set({ shouldClearGaCookiesOnNextPageLoad: true });
          window.location.reload();
        },
        _clearGaCookies: () => {
          clearGaCookies();
          set({ shouldClearGaCookiesOnNextPageLoad: false });
        },
      };
    },
    {
      name: 'cookies-settings',
      partialize: state => ({
        savedSettings: state.savedSettings,
        shouldClearGaCookiesOnNextPageLoad:
          state.shouldClearGaCookiesOnNextPageLoad,
      }),
      onRehydrateStorage: () => (state?: CookiesStoreType) => {
        if (state !== undefined) {
          if (state.savedSettings !== null) {
            state.promptModal.close();
            state._setSettings(state.savedSettings);
          }

          if (state.shouldClearGaCookiesOnNextPageLoad) {
            state._clearGaCookies();
          }
        }
      },
    },
  ),
);
