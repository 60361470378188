import { useMutation } from '@tanstack/react-query';
import { API_QUERY_KEYS } from 'consts';
import { postCompany } from '../requests';
import { PostCompanyRequestType } from '../types';

export const usePostCompany = (
  { pathParams }: { pathParams: PostCompanyRequestType['pathParams'] },
  options?: {
    onSuccess: () => void;
    onError: (error: unknown) => void;
  },
) =>
  useMutation({
    mutationKey: [API_QUERY_KEYS.postCompany],
    mutationFn: ({ bodyParams }: Pick<PostCompanyRequestType, 'bodyParams'>) =>
      postCompany({
        pathParams,
        bodyParams,
      }),
    ...options,
  });
