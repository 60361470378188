import { apiAuthClient } from 'api/helpers';
import { stringifyUrlQuery } from 'helpers';
import {
  GetCompanyFromGusRequestType,
  GetCompanyFromGusResponseType,
} from '../types';

export const getCompanyFromGus = async ({
  queryParams: { nip },
}: GetCompanyFromGusRequestType) => {
  const query = stringifyUrlQuery({
    nip,
  });
  const url = `/company/gusapi${query}`;

  const { data } = await apiAuthClient.get<GetCompanyFromGusResponseType>(url);

  return data.data;
};
