import { getCompanySnippet, updateCompanyVariables } from './utils';
import { CompaniesWrapper, CompanyWrapper } from './components';

export const companyWrapper = {
  onKeyDown: (e, editor, next) => {
    if (e.key === 'Enter' && e.shiftKey) {
      const node = editor.value.document.getClosest(
        editor.value.endBlock.key,
        v => v.object === 'block' && v.type === 'companyWrapper',
      );

      if (node) {
        e.preventDefault();
        editor.moveToEndOfNode(node);
        const path = editor.value.document
          .getPath(editor.value.endBlock.key)
          .toJSON();
        const index = path[path.length - 1];
        editor.splitNodeByKey(node.key, index + 1).moveForward(1);
        return null;
      }
    }

    return next();
  },
  renderNode: (props, editor, next) => {
    if (props.node.type === 'companiesWrapper') {
      return <CompaniesWrapper {...props} />;
    }

    if (props.node.type === 'companyWrapper') {
      return <CompanyWrapper {...props} />;
    }

    return next();
  },
  commands: {
    insertCompanyWrapper: (editor, numberOfParties = 2) => {
      const documentData = editor.value.document.data.toJS();

      updateCompanyVariables(editor, documentData, numberOfParties);

      const allCompanies = [...Array(numberOfParties)].map(
        (_, i) => `company${i + 1}`,
      );

      editor
        .insertBlock({
          object: 'block',
          type: 'paragraph',
          data: {},
          nodes: [],
        })
        .moveToEndOfPreviousBlock()
        .insertBlock(getCompanySnippet(allCompanies, false));
    },
  },
};
