import { Wrapper } from './Wrapper';
import { useTranslation } from 'react-i18next';
import { Box, Select } from '@mantine/core';
import { TrashIcon } from 'icons';
import { updateCompanyWrapper } from '../utils';

export const CompaniesWrapper = ({
  attributes,
  node,
  editor,
  children,
  isSelected,
  isFocused,
}) => {
  const { t } = useTranslation(['templates']);
  const data = node.data.toJS();

  const documentData = editor.value.document.data.toJS();

  return (
    <Wrapper
      attributes={attributes}
      isSelected={isSelected}
      isFocused={isFocused}
    >
      <Box
        sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          marginRight: -theme.other.spacing(2),
          marginBottom: theme.other.spacing(1),
          paddingTop: theme.other.spacing(2),
        })}
        contentEditable={false}
      >
        <Select
          label={t('templates:toolbar.numberOfContractsParties')}
          value={data.companies.length}
          onChange={value => {
            updateCompanyWrapper(node, editor, documentData, Number(value));
          }}
          data={[1, 2, 3, 4].map(val => ({ value: val, label: val }))}
        />
        <div>
          <TrashIcon
            onClick={e => {
              e.stopPropagation();
              editor.removeNodeByKey(node.key);
            }}
            sx={{ cursor: 'pointer' }}
          />
        </div>
      </Box>
      {children}
    </Wrapper>
  );
};
