import { VARIABLE_TYPES_COMPARATORS } from '../../consts/variableComparators';

export const getAvailableVariableComparators = (
  variableName,
  type,
  availableVariables,
) => {
  if (!type) {
    const parsedVariable = availableVariables.find(
      variable => variable.label === variableName,
    );
    const parsedVariableType = parsedVariable && parsedVariable.type;
    return parsedVariableType
      ? VARIABLE_TYPES_COMPARATORS[parsedVariableType]
      : [];
  }

  return type ? VARIABLE_TYPES_COMPARATORS[type] : [];
};
