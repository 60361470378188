export const VARIABLE_TYPES = {
  boolean: 'boolean',
  company: 'company',
  date: 'date',
  dynamicList: 'dynamicList',
  integer: 'integer',
  meta: 'meta',
  money: 'money',
  predefined: 'predefined',
  select: 'select',
  tabs: 'tabs',
  text: 'text',
  textarea: 'textarea',
};
