import { useQuery } from '@tanstack/react-query';
import { API_QUERY_KEYS } from 'consts';
import { getCompanyFromGus } from '../requests';
import { GetCompanyFromGusRequestType } from '../types';

export const useGetCompanyFromGus = (
  { queryParams: { nip } }: GetCompanyFromGusRequestType,
  options?: any,
) =>
  useQuery({
    queryKey: [API_QUERY_KEYS.companyDataFromGus],
    queryFn: () => getCompanyFromGus({ queryParams: { nip } }),
    ...options,
  });
