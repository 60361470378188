import { useState } from 'react';
import { DescriptionField, TextInput } from 'components/shared';
import { Grid, Checkbox, Text } from '@mantine/core';

export const AdvancedOptions = ({
  inputProps,
  listToBeClearedOnChange,
  setValue,
}) => {
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(
    Boolean(listToBeClearedOnChange),
  );

  return (
    <DescriptionField
      label="Zaawansowane"
      description="Description"
      withoutBorder
      bottomSection={
        showAdvancedOptions ? (
          <Grid>
            <Grid.Col xs={12} sm={5}>
              <Text size="md" weight={600}>
                Listy czyszczone po wybraniu opcji
              </Text>
            </Grid.Col>
            <Grid.Col xs={12} sm={7}>
              <TextInput
                {...inputProps}
                placeholder="Placeholder"
                withErrorWrapperAlwaysEnabled={false}
                description="Poszczególne pola oddzielaj przecinkami"
                inputWrapperOrder={['label', 'input', 'description']}
              />
            </Grid.Col>
          </Grid>
        ) : undefined
      }
    >
      <Checkbox
        checked={showAdvancedOptions}
        onChange={e => {
          const value = e.target.checked;
          if (!value) {
            setValue('listToBeClearedOnChange', '');
          }
          setShowAdvancedOptions(value);
        }}
        sx={{ margin: 0 }}
      />
    </DescriptionField>
  );
};
