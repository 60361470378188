import { createTable } from '../utils';
import { Point, Range } from 'slate';

/**
 * Insert a new table
 */
export default function insertTable(
  opts,
  change,
  columns = 2,
  rows = 2,
  getCellContent, // ?: (column: number, row: number) => Node[],
) {
  const { value } = change;

  if (!value.selection.start.key) {
    return change;
  }

  // Create the table node
  const table = createTable(opts, columns, rows, getCellContent);
  change = change.insertBlock(table);

  debugger;

  // Find the first cell
  const firstCell = table.nodes.first().nodes.first();

  // Set the cursor to the first cell
  const anchor = Point.create({
    key: firstCell.key,
    offset: 0,
  });

  const focus = Point.create({
    key: firstCell.key,
    offset: 0,
  });

  const range = Range.create({
    anchor,
    focus,
  });

  change = change.select(range);

  // Focus the editor to ensure the cursor is set
  change = change.focus();

  return change;
}
