import { Paper } from '@mantine/core';

export const Wrapper = ({ attributes, children, isSelected, isFocused }) => (
  <Paper
    {...attributes}
    elevation={isSelected && isFocused ? 'md' : 'sm'}
    sx={theme => ({
      padding: theme.other.spacing(2),
      paddingTop: 0,
      margin: theme.other.spacing(1, 0),
    })}
  >
    {children}
  </Paper>
);
