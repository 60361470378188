import { TablePosition } from '../utils';

import clearCell from './clearCell';

/**
 * Delete the column associated with the given cell key in a table
 */
export default function removeColumnByKey(opts, change, key) {
  const { value } = change;

  const pos = TablePosition.create(opts, value.document, key);
  const { table } = pos;

  const colIndex = pos.getColumnIndex();

  const rows = table.nodes;

  // Remove the cell from every row
  if (pos.getWidth() > 1) {
    change.withoutNormalizing(() => {
      rows.forEach(row => {
        const cell = row.nodes.get(colIndex);
        change.removeNodeByKey(cell.key);
      });
    });
  } else {
    // If last column, clear text in cells instead
    rows.forEach(row => {
      row.nodes.forEach(cell => {
        cell.nodes.forEach(() => clearCell(opts, change, cell));
      });
    });
  }

  // Replace the table
  return change;
}
