import { DescriptionField, TextInput } from 'components/shared';

export const FormTextField = ({
  inputProps,
  error,
  field,
  withoutBorder = false,
}) => {
  return (
    <DescriptionField
      label={field.label}
      description={field.description}
      error={error}
      withoutBorder={withoutBorder}
    >
      <TextInput
        {...inputProps}
        placeholder={field.placeholder}
        withErrorWrapperAlwaysEnabled={false}
      />
    </DescriptionField>
  );
};
