export const isValidNip = val => {
  if (!val) {
    return true;
  }

  if (val.length !== 10) {
    return false;
  }

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  const sum = weight.reduce(
    (accumulator, weightItem, index) =>
      accumulator + Number(val[index]) * weightItem,
    0,
  );

  return sum % 11 === Number(val[9]);
};
