export const Paragraph = ({ obj, children }) => {
  const id = obj.data.get('id');

  return (
    <div
      id={id ? `paragraph-${id}` : undefined}
      style={{
        position: 'relative',
        lineHeight: obj.data.get('lineHeight') || undefined,
        textAlign: obj.data.get('align') || undefined,
        marginTop: id > 1 ? '8px' : undefined,
      }}
    >
      {obj.text ? (
        children
      ) : (
        <span dangerouslySetInnerHTML={{ __html: '&#8203;' }} />
      )}
    </div>
  );
};
