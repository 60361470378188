import Options from './options';
import core from './core';
import { onKeyDown, onCopy, onPaste } from './handlers';

/**
 *  Returns the full plugin object (behavior + rendering + schema)
 */
export function EditTable(optionsParam) {
  const opts = new Options(optionsParam || {});
  const corePlugin = core(opts);

  return {
    ...corePlugin,
    onKeyDown: onKeyDown.bind(null, opts),
    onCopy: onCopy.bind(null, opts),
    onPaste: onPaste.bind(null, opts),
  };
}
