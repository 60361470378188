import { isList } from '../utils';

/**
 * A rule that joins adjacent lists of the same type
 */
function joinAdjacentLists(
  opts,
  node,
  editor,
  next,
) /* : void | (Change => any) */ {
  if (node.object !== 'document' && node.object !== 'block') {
    return next();
  }

  const invalids = node.nodes
    .map((child, i) => {
      if (!isList(opts, child)) {
        return null;
      }

      const nextNode = node.nodes.get(i + 1);

      if (
        !nextNode ||
        !isList(opts, nextNode) ||
        !opts.canMerge(child, nextNode)
      ) {
        return null;
      }

      return [child, nextNode];
    })
    .filter(Boolean);

  if (invalids.isEmpty()) {
    return next();
  }

  /**
   * Join the list pairs
   */
  // We join in reverse order, so that multiple lists folds onto the first one
  return () => {
    invalids.reverse().forEach(pair => {
      const [first, second] = pair;
      const updatedSecond = editor.value.document.getDescendant(second.key);
      editor.withoutNormalizing(() => {
        updatedSecond.nodes.forEach((secondNode, index) => {
          editor.moveNodeByKey(
            secondNode.key,
            first.key,
            first.nodes.size + index,
          );
        });

        editor.removeNodeByKey(second.key);
      });
    });
  };
}

/**
 * Create a schema definition with rules to normalize lists
 */
export default function validateNode(
  opts,
) /* : Node => void | Change => any */ {
  return (node, editor, next) => joinAdjacentLists(opts, node, editor, next);
}
