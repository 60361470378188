import { format, parseISO } from 'date-fns';
import { get } from 'lodash';

const datePattern = 'yyyy-MM-dd';

const transformBeforeComparison = (variable, value) => {
  if (variable.type === 'text' || variable.type === 'textarea') {
    return value && value.toLowerCase();
  }

  if (variable.type === 'date') {
    return (
      value &&
      format(typeof value === 'string' ? parseISO(value) : value, datePattern)
    );
  }

  if (
    typeof value === 'string' &&
    (variable.type === 'integer' || variable.type === 'money')
  ) {
    return Number(value);
  }

  return value;
};

const comparators = {
  isTrue: (variable, value) => !!value,
  isFalse: (variable, value) => !value,
  isDefined: (variable, value) =>
    value !== undefined && value !== null && value !== '',
  isNotDefined: (variable, value) =>
    value === undefined || value === null || value === '',
  isEqual: (variable, value, otherValue) =>
    comparators.isDefined(variable, value) &&
    transformBeforeComparison(variable, value) ===
      transformBeforeComparison(variable, otherValue),
  isNotEqual: (variable, value, otherValue) =>
    !comparators.isEqual(variable, value, otherValue),
  isBigger: (variable, value, otherValue) =>
    comparators.isDefined(variable, value) &&
    transformBeforeComparison(variable, value) >
      transformBeforeComparison(variable, otherValue),
  isSmaller: (variable, value, otherValue) =>
    comparators.isDefined(variable, value) &&
    transformBeforeComparison(variable, value) <
      transformBeforeComparison(variable, otherValue),
  isBiggerOrEqual: (variable, value, otherValue) =>
    comparators.isDefined(variable, value) &&
    transformBeforeComparison(variable, value) >=
      transformBeforeComparison(variable, otherValue),
  isSmallerOrEqual: (variable, value, otherValue) =>
    comparators.isDefined(variable, value) &&
    transformBeforeComparison(variable, value) <=
      transformBeforeComparison(variable, otherValue),
};

export const evaluateConditions = (
  conditions,
  variables,
  values,
  loopWrapperIndex = null,
) => {
  if (conditions.logicalOperator === 'or') {
    return conditions.conditions.some(v =>
      evaluateConditions(v, variables, values, loopWrapperIndex),
    );
  }

  if (conditions.logicalOperator === 'and') {
    return conditions.conditions.every(v =>
      evaluateConditions(v, variables, values, loopWrapperIndex),
    );
  }

  const comparator = comparators[conditions.comparator];

  if (conditions.variableName === null) {
    return false;
  }
  const variable = variables.find(
    v => v.name === conditions.variableName.split('.')[0],
  );
  const value = get(values, conditions.variableName);
  const subValue =
    variable.list && loopWrapperIndex !== null
      ? value[loopWrapperIndex]
      : value;

  return comparator(variable, subValue, conditions.otherValue);
};
