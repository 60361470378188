import {
  getSchema,
  getTemplateVariablesValues,
  renderTemplatePreview,
} from './helpers';
import { useForm, FormProvider } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

export const TemplatePreview = ({ template }) => {
  const preview = useMemo(() => renderTemplatePreview(template), [template]);
  const defaultValues = getTemplateVariablesValues(template);
  const validationSchema = yupResolver(getSchema(template));
  const formApi = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: validationSchema,
  });

  return <FormProvider {...formApi}>{preview}</FormProvider>;
};
