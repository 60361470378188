import { cloneFragment } from 'slate-react';

import { getCopiedFragment } from '../utils';

/**
 *  Handle copying content of tables
 */
export default function onCopy(opts, event, editor, next) {
  const copiedFragment = getCopiedFragment(opts, editor.value);

  if (!copiedFragment) {
    // Default copy behavior
    return next();
  }

  // Override default onCopy
  cloneFragment(event, editor.value, copiedFragment);
  return true;
}
