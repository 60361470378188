import { Switch } from '@mantine/core';
import { useFormContext } from 'react-hook-form';
import { useTemplateStore } from 'stores';

export const Header = ({ obj, template, children }) => {
  const { variables } = template.document.data;
  const variableName = obj.data.get('variable');
  const mappedVariable =
    variableName && variables.find(v => v.name === variableName);
  const { previewEditMode } = useTemplateStore();
  const id = obj.data.get('id') ? `paragraph-${obj.data.get('id')}` : undefined;

  if (mappedVariable && mappedVariable.type === 'conditional') {
    const shouldBeRendered = evaluateConditions(
      mappedVariable.condition,
      variables,
      values,
    );

    if (previewEditMode) {
      return (
        <h2
          id={id}
          style={{
            textAlign: 'center',
            ...(!shouldBeRendered && {
              color: 'grey',
              textDecoration: 'line-through',
            }),
          }}
        >
          {children}
        </h2>
      );
    }

    if (!previewEditMode && shouldBeRendered) {
      return (
        <h2 id={id} style={{ textAlign: 'center' }}>
          {children}
        </h2>
      );
    }

    return null;
  }

  if (variableName) {
    const { setValue, watch } = useFormContext();
    const value = watch(variableName);

    return (
      (value || previewEditMode) && (
        <h2
          id={id}
          style={{
            textAlign: 'center',
            ...(!value && {
              color: 'grey',
              textDecoration: 'line-through',
            }),
          }}
        >
          <Switch
            checked={value}
            onChange={e => setValue(mappedVariable.name, !value)}
            style={{ marginRight: '0.5rem' }}
          />
          {children}
        </h2>
      )
    );
  }

  return (
    <h2 id={id} style={{ textAlign: 'center' }}>
      &sect;
      <br />
      {children}
    </h2>
  );
};
