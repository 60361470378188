import { TransactionsIcon } from '../../../../icons';
import { Box, UnstyledButton } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export const LoopWrapperToolBar = ({
  editor,
  node,
  onDisable = (event, editor, node) => {
    editor.unwrapBlockByKey(node.key, 'loopWrapper');
  },
}) => {
  const { t } = useTranslation(['common', 'templates']);

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.other.spacing(1),
      })}
      contentEditable={false}
    >
      <TransactionsIcon />
      <span>{t('templates:loop')}</span>
      <UnstyledButton
        sx={theme => ({
          fontWeight: 500,
          color: theme.colors[theme.primaryColor][theme.primaryShade],
        })}
        onClick={event => onDisable(event, editor, node)}
      >
        {t('common:disable')}
      </UnstyledButton>
    </Box>
  );
};
