import { useState } from 'react';
import { Box, Text } from '@mantine/core';
import { ChevronDownIcon, ChevronRightIcon, TrashIcon } from 'icons';
import { FormTextField } from './FormTextField';
import { singlePartnerFields } from '../constants';

export const SinglePartner = ({ id, index, register, errors, remove }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Box
      key={id}
      sx={theme => ({
        marginBottom: theme.other.spacing(1),
      })}
    >
      <Box
        sx={() => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        })}
        onClick={() => setIsOpen(prev => !prev)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {isOpen ? (
            <ChevronDownIcon
              sx={theme => ({ marginRight: theme.other.spacing(1) })}
            />
          ) : (
            <ChevronRightIcon />
          )}
          <Text weight={700} size={16}>
            Wspólnik {index + 1}
          </Text>
        </Box>
        <TrashIcon
          color="#E63535"
          sx={() => ({ alignSelf: 'flex-end', cursor: 'pointer' })}
          onClick={() => {
            remove(index);
          }}
        />
      </Box>
      {isOpen && (
        <Box sx={theme => ({ marginTop: theme.other.spacing(1) })}>
          {singlePartnerFields.map((field, idx) => (
            <FormTextField
              inputProps={{ ...register(`partners.${index}.${field.name}`) }}
              field={field}
              withoutBorder={idx === singlePartnerFields.length - 1}
              error={errors ? errors[field.name]?.message : ''}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
