import { Box, Button, Tabs, Text } from '@mantine/core';
import { DescriptionField, NotFound, TextInput } from 'components/shared';
import { TrashIcon } from 'icons';

export const RepresentativesTabPanel = ({
  register,
  formErrors,
  representativesFieldArray,
}) => {
  const { fields, append, remove } = representativesFieldArray;
  return (
    <Tabs.Panel value="representatives">
      {fields.length > 0 ? (
        <Box>
          <DescriptionField
            label="Przedstawiciele"
            description="Description - przedstawiciele"
            error={formErrors?.['representatives']?.message || ''}
            withoutBorder
          >
            {fields.map((field, index) => {
              const error =
                formErrors?.['representatives']?.[index]?.value.message || '';
              return (
                <TextInput
                  key={field.id}
                  placeholder={`Przedstawiciel ${index + 1}`}
                  withErrorWrapperAlwaysEnabled={false}
                  {...register(`representatives.${index}.value`)}
                  sx={theme => ({
                    marginBottom: theme.other.spacing(2),
                  })}
                  rightIcon={<TrashIcon color="#E63535" />}
                  rightIconAction={() => {
                    remove(index);
                  }}
                  error={error}
                />
              );
            })}
            <Text
              size="14px"
              weight={500}
              color="green"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                append({ value: '' });
              }}
            >
              Dodaj kolejnego
            </Text>
          </DescriptionField>
        </Box>
      ) : (
        <Box
          sx={theme => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: theme.other.spacing(4),
          })}
        >
          <NotFound
            header="Nie dodałeś jeszcze żadnego przedstawiciela"
            sx={theme => ({
              marginBottom: theme.other.spacing(2),
            })}
          />
          <Button
            color="green"
            onClick={() => {
              append({ value: '' });
            }}
            sx={() => ({
              flexBasis: 'auto',
            })}
          >
            Dodaj przedstawiciela
          </Button>
        </Box>
      )}
    </Tabs.Panel>
  );
};
