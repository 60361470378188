import { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Dropzone, FileWithPath } from '@mantine/dropzone';
import { Anchor, ActionIcon, Box, Text, DefaultProps } from '@mantine/core';

import { TrashIcon, FileIcon, FileTypeWithoutExtensionIcon } from 'icons';
import { getUploadFileErrorMessage } from 'helpers';

import { CertificateFileErrorsMapType } from '../../types';

const MAX_CERTIFICATE_FILE_SIZE_IN_KB = 16;

type Props = {
  file: FileWithPath | null;
  setFile: any;
  fileErrorMessage: string;
  setFileErrorMessage: (message: string) => void;
  errorsMap: CertificateFileErrorsMapType;
  filesAccept: string[];
  filesAcceptDescription: ReactNode;
  sx?: DefaultProps['sx'];
};

export const GenerateNewCertificate = ({
  file,
  setFile,
  fileErrorMessage,
  setFileErrorMessage,
  errorsMap,
  filesAccept,
  filesAcceptDescription,
  sx,
}: Props) => (
  <Box sx={sx}>
    <Dropzone
      sx={theme => ({
        padding: theme.other.spacing(4, 2, 2),
      })}
      accept={filesAccept}
      multiple={false}
      useFsAccessApi={false}
      onDrop={([uploadedFile]) => {
        setFileErrorMessage('');
        setFile(uploadedFile);
      }}
      onReject={files => {
        const [{ errors }] = files;

        setFileErrorMessage(getUploadFileErrorMessage(errors, errorsMap));
      }}
      maxSize={MAX_CERTIFICATE_FILE_SIZE_IN_KB * 1000}
    >
      <FileTypeWithoutExtensionIcon
        sx={theme => ({
          display: 'block',
          margin: theme.other.spacing(0, 'auto', 1.75),
        })}
      />
      <Text
        sx={theme => ({
          marginBottom: theme.other.spacing(0.5),
          lineHeight: '22px',
        })}
        align="center"
      >
        <Trans
          i18nKey="api:certificateModal.uploadDescription"
          components={{
            br: <br />,
            b: <Anchor sx={{ fontSize: 'inherit' }} component="span" />,
          }}
        />
      </Text>
      <Text size="md" weight={400} align="center">
        {filesAcceptDescription}
      </Text>
    </Dropzone>
    {fileErrorMessage && (
      <Text color="red" mt="lg">
        {fileErrorMessage}
      </Text>
    )}
    {file && (
      <Box
        sx={theme => ({
          marginTop: theme.other.spacing(2),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FileIcon />
          <Text size="md" weight={600}>
            {file.name}
          </Text>
        </Box>
        <ActionIcon
          onClick={() => {
            setFile(null);
          }}
        >
          <TrashIcon color="red" />
        </ActionIcon>
      </Box>
    )}
  </Box>
);
