import { Children } from 'react';
import { LoopWrapper } from './common/LoopWrapper';
import { Paper } from '@mantine/core';

export const LoopWrapperNode = ({ obj, template, children }) => {
  const dependentStatics = obj
    .filterDescendants(v => v.type === 'variable')
    .toJS()
    .filter(v => v.data.variable.endsWith('.representatives'))
    .map(v => v.data.variable);
  const dependentVariableNames = obj
    .filterDescendants(v => v.type === 'input')
    .toJS()
    .filter(
      v =>
        !!template.document.data.variables.find(
          variable => variable.name === v.data.variable && variable.list,
        ),
    )
    .map(v => v.data.variable);

  if (Children.count === 0) {
    return null;
  }

  if (dependentVariableNames.length === 0 && dependentStatics.length === 0) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <LoopWrapper
      variables={template.document.data.variables}
      ActionsContainer="div"
      ActionsContainerProps={{ style: { margin: '8px 0 0 0' } }}
      dependentVariableNames={
        dependentVariableNames.length === 0
          ? dependentStatics
          : dependentVariableNames
      }
      ChildrenContainer={Paper}
      ChildrenContainerProps={{ style: { padding: 8 } }}
      onlyStatic={dependentVariableNames.length === 0}
    >
      {children}
    </LoopWrapper>
  );
};
