import { Box, Paper, Text } from '@mantine/core';

import { GroupIcon } from 'icons';
import { TransactionQueueParticipantType } from 'types';
import { Participant } from '../Participant';
import { TransactionQueueGroupWithParticipantType } from '../types';

type Props = {
  participant: TransactionQueueGroupWithParticipantType;
  errors: { [errorKey: string]: string[] };
};

export const Group = ({ participant, errors }: Props) => {
  const { name, users } = participant;
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <Paper
      shadow="none"
      sx={theme => ({
        border: hasErrors
          ? `1px solid ${theme.colors.red[theme.fn.primaryShade()]}`
          : undefined,
        backgroundColor: '#F7F8FF',
        borderRadius: '12px',
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <GroupIcon />
          <Text
            sx={theme => ({ marginLeft: theme.other.spacing(0.5) })}
            size="md"
            weight={600}
          >
            {name}
          </Text>
        </Box>
        <div>
          {users.map((user, index) => (
            <Participant
              /* eslint-disable-next-line react/no-array-index-key */
              key={`group.participant.${index}`}
              participant={user as TransactionQueueParticipantType}
              errors={errors}
            />
          ))}
        </div>
      </Box>
    </Paper>
  );
};
