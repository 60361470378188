import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrashIcon, EditIcon } from 'icons';
import { DataStreamTemplateType } from 'types';
import { Actions } from 'components/shared';

import { DataStreamEditTemplateModal } from '../DataStreamEditTemplateModal';
import { DataStreamDeleteTemplateModal } from '../DataStreamTemplateDeleteModal';

type Props = {
  templateId: DataStreamTemplateType['templateId'];
  templateName: DataStreamTemplateType['templateName'];
  templateIndex: number;
  documentName: DataStreamTemplateType['documentName'];
  documentExternalId: DataStreamTemplateType['documentExternalId'];
  documentReference: DataStreamTemplateType['documentReference'];
  documentContent: DataStreamTemplateType['documentContent'];
  isBlocked: DataStreamTemplateType['isBlocked'];
  attachTo: DataStreamTemplateType['attachTo'];
  templates: DataStreamTemplateType[];
};

export const DataStreamActions = ({
  templateId,
  templateName,
  templateIndex,
  documentName,
  documentExternalId,
  documentReference,
  documentContent,
  isBlocked,
  attachTo,
  templates,
}: Props) => {
  const { t } = useTranslation('common');

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <>
      <Actions
        items={[
          {
            name: t('common:edit'),
            icon: <EditIcon />,
            colorDesktop: 'blue',
            action: () => {
              setIsEditModalOpen(true);
            },
          },
          {
            name: t('common:delete'),
            icon: <TrashIcon />,
            colorDesktop: 'red',
            colorMobile: 'red',
            action: () => {
              setIsDeleteModalOpen(true);
            },
          },
        ]}
      />
      <DataStreamEditTemplateModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        templateId={templateId}
        templateName={templateName}
        templateIndex={templateIndex}
        documentName={documentName}
        documentExternalId={documentExternalId}
        documentReference={documentReference}
        documentContent={documentContent}
        isBlocked={isBlocked}
        attachTo={attachTo}
        templates={templates}
      />
      <DataStreamDeleteTemplateModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
        name={templateName || documentContent}
        type={templateId === null ? 'file' : 'template'}
        templateIndex={templateIndex}
      />
    </>
  );
};
