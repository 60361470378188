import { Block, Text } from 'slate';

import { TablePosition } from '../utils';

/**
 * Exit the current table, by inserting a default block after the table.
 */
export default function onModEnter(event, editor, opts, next) {
  const { value } = editor;

  if (!value.selection.isCollapsed) {
    return next();
  }

  event.preventDefault();

  const exitBlock = Block.create({
    type: opts.exitBlockType,
    nodes: [Text.create('')],
  });

  const { table } = TablePosition.create(
    opts,
    value.document,
    value.selection.start.key,
  );
  const tableParent = value.document.getParent(table.key);
  const insertionIndex = tableParent.nodes.indexOf(table) + 1;

  return editor
    .insertNodeByKey(tableParent.key, insertionIndex, exitBlock)
    .moveToStartOfNode(exitBlock);
}
