import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { ForceUpdateContext } from '../../context';
import { getMoneyVariableIcon, getNextLanguageValue } from '../input/helpers';
import {
  ActionIcon,
  Box,
  Tooltip,
  UnstyledButton,
  Popover,
} from '@mantine/core';
import { VariableValueIcon } from '../../icons';
import { useTemplateStore } from '../../../../stores';
import { useParams } from 'react-router-dom';
import { getAvailableVariables, getVariableOptions } from '../../utils';
import { VariableValues } from './components';
import { VariableAddModal } from '../../VariableAddModal';
import { VariableContextMenu } from '../../VariableContextMenu';

const Variable = ({ attributes, node, editor, isActive }) => {
  const { t } = useTranslation('templates');

  const forceUpdate = useContext(ForceUpdateContext);

  const nodeVariable = node.data.get('variable');

  const { attachmentName } = useParams();

  const [popoverKind, setPopoverKind] = useState();
  const [isVariableAddModalOpen, setIsVariableAddModalOpen] = useState(false);

  const baseVariables = useTemplateStore(store => store.baseVariables);

  const variableOptions = getVariableOptions(
    [...editor.getVariables()],
    Boolean(attachmentName) ? baseVariables : [],
    t,
  );

  const availableVariables = getAvailableVariables(
    variableOptions,
    attributes.allowedTypes,
    ['company'],
  );

  const variable = availableVariables.find(({ name }) => name === nodeVariable);
  const variableType = variable?.type;
  const variableLabel = variable?.predefined ? variable?.label : variable?.name;

  const handleOnClick = event => {
    event.stopPropagation();
    setPopoverKind('selectValue');
  };

  const handleOnClearClick = () => {
    setPopoverKind('selectValue');
    editor.updateNodeData(node, { variable: undefined });
  };

  const handleOnTrashClick = () => {
    setPopoverKind(undefined);
    editor.removeNodeByKey(node.key);
  };

  const handleOnClosePopover = () => {
    setPopoverKind(undefined);
  };

  const handleOnCreateNewVariable = () => {
    setPopoverKind(undefined);
    setIsVariableAddModalOpen(true);
  };

  const getAdditionalContent = () => {
    if (variableType === 'money' && variable.translateMoneyValue) {
      const { defaultMoneyTranslation } = variable;
      const nodeMoneyTranslation =
        node.data.get('moneyTranslation') || defaultMoneyTranslation;
      const Icon = getMoneyVariableIcon(nodeMoneyTranslation);

      return (
        <span
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Tooltip label={t('templates:clickToChangeLanguageTranslation')}>
            <ActionIcon
              sx={theme => ({
                marginLeft: theme.other.spacing(0.5),
              })}
              component="span"
              onClick={() => {
                editor.updateNodeData(node, {
                  moneyTranslation: getNextLanguageValue(nodeMoneyTranslation),
                });

                forceUpdate();
              }}
            >
              <Icon size={16} />
            </ActionIcon>
          </Tooltip>
        </span>
      );
    }
  };

  return (
    <>
      <Box
        sx={theme => ({
          position: 'relative',
          display: 'inline-block',
          marginBottom: theme.other.spacing(1),
          userSelect: 'none',
          verticalAlign: 'middle',
        })}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Popover
          opened={!!popoverKind}
          exitTransitionDuration={0}
          withinPortal
          onClose={handleOnClosePopover}
          position="bottom-start"
        >
          <Popover.Target>
            <UnstyledButton
              onClick={handleOnClick}
              sx={theme => {
                const activeBorderColor =
                  theme.focusRingStyles.inputStyles(theme).borderColor;

                const getBorderColor = () => {
                  if (isActive) {
                    return activeBorderColor;
                  }

                  return nodeVariable
                    ? theme.colors.gray[2]
                    : theme.colors.red[0];
                };

                return {
                  minHeight: '38px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: theme.other.spacing(1),
                  padding: theme.other.spacing(0.75, 2),
                  fontSize: theme.fontSizes.lg,
                  fontWeight: 500,
                  backgroundColor: nodeVariable
                    ? theme.colors.gray[1]
                    : theme.colors.red[1],
                  border: `1px solid ${theme.colors.gray[2]}`,
                  borderColor: getBorderColor(),
                  borderRadius: theme.radius.sm,
                };
              }}
            >
              <VariableValueIcon color="primary.9" />
              <Box sx={{ maxWidth: 240 }} component="span">
                {variable ? variableLabel : t('templates:chooseVariable')}
              </Box>
              {variable && (
                <VariableContextMenu
                  onOpen={handleOnClosePopover}
                  onClearClick={handleOnClearClick}
                  onRemoveClick={handleOnTrashClick}
                />
              )}
              {getAdditionalContent()}
            </UnstyledButton>
          </Popover.Target>
          <Popover.Dropdown sx={{ padding: 0 }}>
            {popoverKind === 'selectValue' && (
              <VariableValues
                node={node}
                availableVariables={availableVariables}
                onClose={handleOnClosePopover}
                onCreateNew={handleOnCreateNewVariable}
              />
            )}
          </Popover.Dropdown>
        </Popover>
      </Box>
      <VariableAddModal
        isOpen={isVariableAddModalOpen}
        onClose={() => {
          setIsVariableAddModalOpen(false);
        }}
        addVariableCallback={({ name }) => {
          editor.updateNodeData(node, { variable: name });
        }}
        defaultType={attributes.defaultType}
        allowedTypes={attributes.allowedTypes}
      />
    </>
  );
};

export const variable = {
  renderNode: ({ attributes, node, isSelected, isFocused }, editor, next) => {
    if (node.type === 'variable') {
      return (
        <Variable
          attributes={attributes}
          node={node}
          editor={editor}
          isActive={isSelected && isFocused}
        />
      );
    }

    return next();
  },
  schema: {
    inlines: {
      variable: {
        isVoid: true,
      },
    },
  },
};
