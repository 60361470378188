import { useState } from 'react';
import { Select } from '@mantine/core';
import { DescriptionField } from 'components/shared';
import { useGetCurrentBoardId } from 'hooks';
import { useGetCompanyGroups } from 'api/company/hooks';
import { useTranslation } from 'react-i18next';

const mapCompanyGroups = data =>
  Array.isArray(data) ? data.map(group => group.name) : [];

export const GroupsDropdown = ({ field, setValue, selectedGroup }) => {
  const { t } = useTranslation(['common']);

  const currentBoardId = useGetCurrentBoardId();

  const { data } = useGetCompanyGroups(
    {
      pathParams: { boardId: currentBoardId },
    },
    {
      onSuccess: data => {
        setDisplayedGroups(mapCompanyGroups(data));
      },
    },
  );

  const [displayedGroups, setDisplayedGroups] = useState(
    mapCompanyGroups(data),
  );

  return (
    <DescriptionField
      label={field.label}
      description={field.description}
      withoutBorder
    >
      <Select
        data={displayedGroups.map(group => ({
          value: group,
          label: group,
        }))}
        placeholder="Wybierz lub utwórz nową"
        nothingFound={t('common:notFoundResults')}
        searchable
        creatable
        clearable
        getCreateLabel={query => `+ Dodaj  ${query}`}
        value={selectedGroup}
        onChange={value => {
          setValue('groups', value);
        }}
        onCreate={query => {
          setDisplayedGroups(current => [...current, query]);
          return { value: query, label: query };
        }}
      />
    </DescriptionField>
  );
};
