import { getEventTransfer } from 'slate-react';
import { Range } from 'slate';

import { isSelectionInTable, isRangeInTable } from '../utils';
import { insertTableFragmentAtRange } from '../changes';

/**
 *  Handle pasting inside tables
 */
export default function onPaste(opts, event, editor, next) {
  // Outside of tables, do not alter paste behavior
  if (!isSelectionInTable(opts, editor.value)) {
    return next();
  }

  const transfer = getEventTransfer(event);
  const { type, fragment } = transfer;

  if (type !== 'fragment' || fragment.nodes.isEmpty()) {
    return next();
  }

  if (
    !isRangeInTable(
      opts,
      fragment,
      Range.create({
        anchor: fragment.getFirstText(),
        focus: fragment.getLastText(),
      }),
    )
  ) {
    return next();
  }

  return insertTableFragmentAtRange(
    opts,
    editor,
    editor.value.selection,
    fragment,
  );
}
