import { chunk } from 'lodash';
import { useTemplateStore } from 'stores';
import { TextInput, Stack, UnstyledButton } from '@mantine/core';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TrashIcon } from '../../../icons';

export const StaticTable = ({ obj }) => {
  const { t } = useTranslation('templates');
  const id = obj.data.get('id');
  const name = id?.toString();
  const { previewEditMode } = useTemplateStore();
  const { setValue } = useFormContext();
  const value = useWatch({ name });

  const handleOnRemove = () => {
    setValue(name, undefined);
  };

  return (
    <>
      {previewEditMode && (
        <Stack my={16}>
          {t('templates:staticTablePasteTableBelow')}
          <TextInput
            placeholder={
              value
                ? t('templates:staticTablePasteAgainToOverride')
                : t('templates:staticTablePasteHere')
            }
            value=""
            rightSection={
              <UnstyledButton onClick={handleOnRemove}>
                <TrashIcon />
              </UnstyledButton>
            }
            onPaste={e => {
              const html = e.clipboardData.getData('text/html');
              const parser = new DOMParser();
              const document = parser.parseFromString(html, 'text/html');
              const table = document.getElementsByTagName('table');

              if (!table[0]) {
                return;
              }

              const numberOfCells = table[0]
                .getElementsByTagName('tr')[0]
                .getElementsByTagName('td').length;
              const cells = Array.from(table[0].getElementsByTagName('td')).map(
                v => v.outerText,
              );

              setValue(name, chunk(cells, numberOfCells));
            }}
          />
        </Stack>
      )}
      {Array.isArray(value) && (
        <div style={{ overflowX: 'auto', margin: '16px 0' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <tbody>
              {value.map((tableRow, i) => (
                <tr
                  key={i}
                  style={
                    i === 0
                      ? { backgroundColor: '#f5f5f5', fontWeight: 'bold' }
                      : {}
                  }
                >
                  {tableRow.map((tableCell, j) => (
                    <td
                      key={j}
                      style={{
                        border: '1px solid #ddd',
                        padding: '0.5rem',
                        textAlign: 'center',
                      }}
                    >
                      {tableCell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
