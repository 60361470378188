import { DatePicker } from '@mantine/dates';
import { Sx } from '@mantine/core';
import 'dayjs/locale/pl';
import { CSSProperties } from 'react';
import { addYears, subYears } from 'date-fns';

type Props = {
  placeholder: string;
  label: string;
  value: Date | null;
  style: CSSProperties;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onChange: (value: Date | null) => void;
  maxYearDelta?: number;
  minYearDelta?: number;
  sx: Sx;
};

export const DatePickerSingleInput = ({
  placeholder,
  label,
  value,
  style,
  size,
  onChange,
  maxYearDelta,
  minYearDelta,
  sx,
}: Props) => {
  const minDate =
    minYearDelta !== undefined && minYearDelta !== null
      ? subYears(new Date(), minYearDelta)
      : undefined;

  const maxDate =
    maxYearDelta !== undefined && maxYearDelta !== null
      ? addYears(new Date(), maxYearDelta)
      : undefined;

  return (
    <DatePicker
      withinPortal
      locale="pl"
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      style={style}
      sx={sx}
      size={size}
      maxDate={maxDate}
      minDate={minDate}
    />
  );
};
