import { CONTRACTOR_TYPES_LABELS } from 'legacy/TemplateCreatorEditor/CompanyModal/constants';
import { LoopWrapperContext } from '../createRules';
import { MaybeTransformer } from './common/MaybeTransformer';
import { MissingData } from './common/MissingData';
import { useFormContext } from 'react-hook-form';

export const Variable = ({ obj, template }) => {
  const { watch } = useFormContext();
  let variableName = obj.data.get('variable');
  const bold = !!obj.data.get('bold');
  const italic = !!obj.data.get('italic');
  const fontSize = obj.data.get('fontSize') || null;

  if (variableName.startsWith('meta.')) {
    variableName = variableName.replace('meta', '_contractCommon');
  } else if (variableName.startsWith('base.')) {
    variableName = variableName.replace('base', '_contractBase');
  }

  return (
    <LoopWrapperContext.Consumer>
      {loopWrapperIndex => {
        if (variableName === 'loop.index') {
          return (
            <span>
              <MaybeTransformer bold={bold} italic={italic} fontSize={fontSize}>
                {loopWrapperIndex === null ? (
                  <MissingData />
                ) : (
                  loopWrapperIndex + 1
                )}
              </MaybeTransformer>
            </span>
          );
        }

        const variable = (template.document.data.variables || []).find(
          v => v.name === variableName,
        );

        const fieldName =
          variableName +
          (loopWrapperIndex !== null &&
          (variableName.endsWith('.representatives') ||
            (variable && variable.list))
            ? `.${loopWrapperIndex}`
            : '');
        const value = watch(fieldName);

        return (
          <span>
            <MaybeTransformer bold={bold} italic={italic} fontSize={fontSize}>
              {value ? (
                CONTRACTOR_TYPES_LABELS[value] ||
                (variable && variable.type === 'date'
                  ? format(
                      typeof value === 'string' ? parseISO(value) : value,
                      datePattern,
                    )
                  : value)
              ) : (
                <MissingData />
              )}
            </MaybeTransformer>
          </span>
        );
      }}
    </LoopWrapperContext.Consumer>
  );
};
