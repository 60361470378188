import { cloneElement, Children } from 'react';

export const Table = ({ children }) => (
  <div style={{ overflowX: 'auto' }}>
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <tbody>
        {Children.map(children, (child, i) =>
          cloneElement(child, { isFirst: i === 0 }),
        )}
      </tbody>
    </table>
  </div>
);
