import axios from 'axios';
import { GetRegistryCourtsResponseType } from '../types';

export const getRegistryCourts = async () => {
  const url = process.env.REACT_APP_DICTIONARIES_REGISTRY_COURTS_URL;
  const { data } = await axios.get<GetRegistryCourtsResponseType>(
    url as string,
  );

  return data;
};
