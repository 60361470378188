import { useGetColor } from 'hooks';

import { Box } from '@mantine/core';

import { IconPropsType } from 'icons/types';

export const RemoveColumnIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="-4 -3 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={iconColor}
        d="M12.828 6.843c.184-.184.437-.299.713-.299.276 0 .518.104.713.3l1.414 1.414 1.415-1.415c.184-.184.437-.299.712-.299.276 0 .518.104.713.3a.982.982 0 0 1 .3.712.982.982 0 0 1-.3.713l-1.414 1.414 1.414 1.415c.184.183.3.436.3.712a.982.982 0 0 1-.3.713.982.982 0 0 1-.713.3.982.982 0 0 1-.712-.3l-1.415-1.414-1.402 1.403a1.009 1.009 0 0 1-.713.299.982.982 0 0 1-.713-.3.982.982 0 0 1-.299-.712c0-.276.104-.518.299-.713l1.414-1.414-1.414-1.414a1.01 1.01 0 0 1-.299-.713c0-.276.104-.518.299-.713l-.012.011Z"
      />
      <path
        stroke={iconColor}
        strokeWidth={1.5}
        d="M.75 3.003A2.25 2.25 0 0 1 3 .753h5a2.25 2.25 0 0 1 2.25 2.25v14A2.25 2.25 0 0 1 8 19.253H3a2.25 2.25 0 0 1-2.25-2.25v-14ZM1 7.253h10M1 13.253h10"
      />
    </Box>
  );
};
