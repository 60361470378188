import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { CONTRACTOR_TYPES } from '../constants';
import { isValidNip } from '../utils';

const TEXT_LIMIT = 200;

export const useGetZodCompanyModalSchema = () => {
  const { t } = useTranslation('common');
  return z
    .object({
      companyType: z
        .string()
        .min(1, { message: t('common:formErrors.required') }),
      email: z.union([
        z.literal(''),
        z.string().email({ message: t('common:formErrors.incorrectEmail') }),
      ]),
      address: z.object({
        city: z
          .string()
          .min(1, { message: t('common:formErrors.required') })
          .max(TEXT_LIMIT),
        street: z
          .string()
          .min(1, { message: t('common:formErrors.required') })
          .max(TEXT_LIMIT),
        zipCode: z
          .string()
          .min(1, { message: t('common:formErrors.required') })
          .regex(/^[0-9]{2}-[0-9]{3}$/, {
            message: 'To pole musi zawierać kod pocztowy',
          }),
      }),
      contactAddress: z.object({
        city: z.string().max(TEXT_LIMIT).nullable(),
        street: z.string().max(TEXT_LIMIT).nullable(),
        zipCode: z.string().max(TEXT_LIMIT).nullable(),
      }),
      differentContactAddress: z.boolean().default(false),
      representatives: z
        .array(
          z.object({
            value: z
              .string()
              .min(1, { message: t('common:formErrors.required') })
              .max(TEXT_LIMIT),
          }),
        )
        .optional(),
      pesel: z.union([
        z.literal(''),
        z
          .string()
          .min(11, { message: 'To pole musi mieć co najmniej 11 znaków' })
          .max(11, { message: 'To pole może mieć maksymalnie 11 znaków' }),
      ]),
      krs: z.string().optional(),
      registryCourt: z.string().optional(),
      shareCapital: z.string().optional(),
      paidCapital: z.string().optional(),
      nip: z.string().optional(),
      regon: z.string().optional(),
      companyName: z.string().optional(),
      name: z.union([z.literal(''), z.string().nullable()]),
      surname: z.union([z.literal(''), z.string().nullable()]),
      identityDoc: z.string().optional(),
      motherName: z.string().max(TEXT_LIMIT).optional(),
      fatherName: z.string().max(TEXT_LIMIT).optional(),
      taxOffice: z.string().optional(),
      bankAccount: z.string(),
      internalName: z.string(),
      internalNumber: z.string(),
      phone: z.string(),
      partners: z
        .array(
          z.object({
            name: z
              .string()
              .min(1, { message: t('common:formErrors.required') }),
            surname: z
              .string()
              .min(1, { message: t('common:formErrors.required') }),
            city: z
              .string()
              .min(1, { message: t('common:formErrors.required') }),
            zipCode: z
              .string()
              .min(1, { message: t('common:formErrors.required') })
              .regex(/^[0-9]{2}-[0-9]{3}$/, {
                message: 'To pole musi zawierać kod pocztowy',
              }),
            street: z
              .string()
              .min(1, { message: t('common:formErrors.required') }),
          }),
        )
        .optional(),
      groups: z.string().nullable(),
    })
    .superRefine(
      (
        {
          groups,
          email,
          companyType,
          differentContactAddress,
          contactAddress: { city, street, zipCode },
          companyName,
          name,
          surname,
          nip,
        },
        refinementContext,
      ) => {
        if (groups !== '' && !email) {
          const requiredEmailParsedSchema = z
            .string()
            .min(1, { message: t('common:formErrors.required') })
            .email({ message: t('common:formErrors.incorrectEmail') })
            .safeParse(email);

          if (!requiredEmailParsedSchema.success) {
            refinementContext.addIssue({
              ...requiredEmailParsedSchema.error.issues[0],
              path: ['email'],
            });
          }
        }

        if (differentContactAddress) {
          if (city === '') {
            refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('common:formErrors.required'),
              path: ['contactAddress.city'],
            });
          }
          if (street === '') {
            refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('common:formErrors.required'),
              path: ['contactAddress.street'],
            });
          }
          if (zipCode === '') {
            refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('common:formErrors.required'),
              path: ['contactAddress.zipCode'],
            });
          }
          if (!/^[0-9]{2}-[0-9]{3}$/.test(zipCode)) {
            refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'To pole musi zawierać kod pocztowy',
              path: ['contactAddress.zipCode'],
            });
          }
        }

        if (
          [
            CONTRACTOR_TYPES.SINGLE_PERSON,
            CONTRACTOR_TYPES.NATURAL_PERSON,
          ].includes(companyType)
        ) {
          const requiredNameParsedSchema = z
            .string()
            .min(1, { message: t('common:formErrors.required') })
            .max(200, { message: t('common:formErrors.required') })
            .safeParse(name);

          const requiredSurnameParsedSchema = z
            .string()
            .min(1, { message: t('common:formErrors.required') })
            .max(200, { message: t('common:formErrors.required') })
            .safeParse(surname);

          if (!requiredNameParsedSchema.success) {
            refinementContext.addIssue({
              ...requiredNameParsedSchema.error.issues[0],
              path: ['name'],
            });
          }

          if (!requiredSurnameParsedSchema.success) {
            refinementContext.addIssue({
              ...requiredSurnameParsedSchema.error.issues[0],
              path: ['surname'],
            });
          }
        }

        if (
          companyType !== '' &&
          companyType !== CONTRACTOR_TYPES.NATURAL_PERSON
        ) {
          const requiredNipParsedSchema = z
            .string()
            .min(8, { message: 'To pole musi mieć co najmniej 8 znaków' })
            .max(15, {
              message: 'Maksymalna długość pola została przekroczona',
            })
            .safeParse(nip);

          if (!requiredNipParsedSchema.success) {
            refinementContext.addIssue({
              ...requiredNipParsedSchema.error.issues[0],
              path: ['nip'],
            });
          } else if (!isValidNip(nip)) {
            refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Wprowadź poprawny NIP',
              path: ['nip'],
            });
          }

          const requiredCompanyNameParsedSchema = z
            .string()
            .min(1, { message: t('common:formErrors.required') })
            .max(200, { message: t('common:formErrors.required') })
            .safeParse(companyName);

          if (!requiredCompanyNameParsedSchema.success) {
            refinementContext.addIssue({
              ...requiredCompanyNameParsedSchema.error.issues[0],
              path: ['companyName'],
            });
          }
        }
      },
    );
};
