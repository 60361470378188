import { create } from 'zustand';
import { ContractTemplateType, TemplateType } from 'types';

type TemplateStoreType = {
  baseVariables: unknown;
  template: TemplateType | null;
  editorJsonValue: ContractTemplateType | null;
  previewEditMode: boolean;
  setTemplate: (data: TemplateType) => void;
  setEditorJsonValue: (editorJsonValue: ContractTemplateType) => void;
  setPreviewEditMode: (previewEditMode: boolean) => void;
};

export const useTemplateStore = create<TemplateStoreType>(set => ({
  baseVariables: [],
  template: null,
  editorJsonValue: null,
  previewEditMode: true,
  setTemplate: (data: TemplateType) =>
    set({
      baseVariables: data.contractTemplate.document.data.variables || [],
      template: data,
    }),
  setEditorJsonValue: (editorJsonValue: ContractTemplateType) =>
    set({ editorJsonValue }),
  setPreviewEditMode: (previewEditMode: boolean) => set({ previewEditMode }),
}));
