import { TablePosition } from '../utils';

/**
 * Move selection to {x,y}
 */
export default function moveSelection(opts, change, x, y) {
  const { value } = change;
  const { start } = value.selection;
  const pos = TablePosition.create(opts, value.document, start.key);

  if (!pos.isInCell()) {
    throw new Error('moveSelection can only be applied from within a cell');
  }

  const { table } = pos;
  const row = table.nodes.get(y);
  const cell = row.nodes.get(x);

  return change.moveToStartOfNode(cell);
}
