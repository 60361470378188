import { forwardRef, useRef, useState } from 'react';
import {
  Select as MantineSelect,
  SelectProps as MantineSelectProps,
} from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';
import { ClearableInputRightSection } from '../ClearableInputRightSection';
import { ChevronDownIcon } from '../../../../icons';

export const Select = forwardRef<any, MantineSelectProps>((props, ref) => {
  const { value, clearable, onBlur, onFocus } = props;

  const [isFocussed, setIsFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const mergedRef = useMergedRef(inputRef, ref);

  const isClearable = clearable && isFocussed && value !== null && value !== '';

  return (
    <MantineSelect
      ref={mergedRef}
      rightSection={
        isClearable ? (
          <ClearableInputRightSection
            isClearable={isClearable}
            inputRef={inputRef}
            rightIcon={<ChevronDownIcon className="rotate" />}
          />
        ) : undefined
      }
      {...props}
      onBlur={e => {
        setIsFocused(false);

        if (onBlur) {
          onBlur(e);
        }
      }}
      onFocus={e => {
        setIsFocused(true);

        if (onFocus) {
          onFocus(e);
        }
      }}
    />
  );
});

export default Select;
