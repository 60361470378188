export const isValidNumberInitialValue = ({
  initialValue,
  minValue,
  maxValue,
}) => {
  if (initialValue == null) {
    return true;
  }

  if (minValue == null && maxValue == null) {
    return true;
  }

  const parsedInitialValue = Number(initialValue);
  const parsedMinValue = Number(minValue);
  const parsedMaxValue = Number(maxValue);

  if (minValue == null && maxValue != null) {
    return parsedInitialValue <= parsedMaxValue;
  }

  if (minValue != null && maxValue == null) {
    return parsedInitialValue >= minValue;
  }

  return (
    parsedMinValue <= parsedInitialValue && parsedInitialValue <= parsedMaxValue
  );
};
