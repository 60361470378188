import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ActionIcon, Box, UnstyledButton } from '@mantine/core';

import { useGetTemplate } from 'api/templates';
import { DYNAMIC_URLS } from 'consts';
import { AttachmentIcon, TemplatesIcon, TrashIcon } from 'icons';
import { DeleteModal } from './DeleteModal';

export const AttachmentTemplates = () => {
  const { templateId, attachmentName } = useParams();

  const { data: template } = useGetTemplate({
    pathParams: { templateId },
  });

  const [activeIndexToDelete, setActiveIndexToDelete] = useState(null);

  const { attachmentTemplates } = template;

  const currentDocumentName = attachmentName || templateId;

  return (
    <>
      <Box
        sx={theme => ({
          display: 'grid',
          paddingBottom: theme.other.spacing(1),
          maxHeight: 550,
          overflowY: 'auto',
        })}
        component="ul"
      >
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor:
              currentDocumentName === templateId && theme.colors.violet[1],
          })}
          py={8}
          component="li"
        >
          <UnstyledButton
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              columnGap: theme.other.spacing(0.5),
              fontSize: theme.fontSizes.lg,
              ':hover': {
                textDecoration: 'underline',
              },
            })}
            component={Link}
            to={DYNAMIC_URLS.templateEdit({
              templateId,
            })}
          >
            <TemplatesIcon />
            <Box
              sx={{
                maxWidth: 200,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              component="span"
            >
              {template?.name}
            </Box>
          </UnstyledButton>
        </Box>

        {attachmentTemplates.map(({ type }, index) => (
          <Box
            sx={theme => ({
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor:
                currentDocumentName === type && theme.colors.violet[1],
            })}
            py={8}
            component="li"
            key={type}
          >
            <UnstyledButton
              sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                columnGap: theme.other.spacing(0.5),
                fontSize: theme.fontSizes.lg,
                ':hover': {
                  textDecoration: 'underline',
                },
              })}
              component={Link}
              to={DYNAMIC_URLS.templateAttachmentEdit({
                templateId,
                attachmentName: window.encodeURIComponent(type),
              })}
            >
              <AttachmentIcon />
              <Box
                sx={{
                  maxWidth: 200,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                component="span"
              >
                {type}
              </Box>
            </UnstyledButton>
            <ActionIcon
              onClick={() => {
                setActiveIndexToDelete(index);
              }}
            >
              <TrashIcon color="red" />
            </ActionIcon>
          </Box>
        ))}
      </Box>
      {activeIndexToDelete !== null && (
        <DeleteModal
          isOpen
          onClose={() => {
            setActiveIndexToDelete(null);
          }}
          attachmentIndex={activeIndexToDelete}
          attachmentName={attachmentTemplates[activeIndexToDelete].type}
        />
      )}
    </>
  );
};
