import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';
import { IconPropsType } from 'icons/types';

export const Group2Icon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeWidth={1.5}
        d="M8 4.389H5.143C4.512 4.389 4 4.9 4 5.532v2.857m6.286-4h3.428m2.286 0h2.857c.631 0 1.143.511 1.143 1.143v2.857M4 10.674v3.429m16-3.429v3.429M4 16.389v2.857c0 .631.512 1.143 1.143 1.143H8m12-4v2.857c0 .631-.512 1.143-1.143 1.143H16m-4-12v8m-4-4h8m-5.714 8h3.428"
      />
    </Box>
  );
};
