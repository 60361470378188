import { useQuery } from '@tanstack/react-query';
import { API_QUERY_KEYS } from 'consts';
import { getCompanyGroups } from '../requests';
import {
  GetCompaniesResponseType,
  GetCompanyGroupsRequestType,
} from '../types';

export const useGetCompanyGroups = (
  { pathParams: { boardId } }: GetCompanyGroupsRequestType,
  options?: { onSuccess: (data: GetCompaniesResponseType) => void },
) =>
  useQuery({
    queryKey: [API_QUERY_KEYS.companyGroups],
    queryFn: () => getCompanyGroups({ pathParams: { boardId } }),
    ...options,
  });
