import { useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@mantine/core';
import { useGetOrganisationFeatureAccess, useGetV1Url } from 'hooks';
import { TemplatesIcon } from 'icons';
import {
  SectionHeader,
  StickyContent,
  TextInputReactRouterSearch,
} from 'components/shared';
import { Link } from 'react-router-dom';
import { URLS } from 'consts';

type Props = {
  hasTemplatesCreateAccess: boolean;
};

export const Header = ({ hasTemplatesCreateAccess }: Props) => {
  const { t } = useTranslation('templates');
  const getV1Url = useGetV1Url();
  const { data: isTemplateCreatorV2Enabled } =
    useGetOrganisationFeatureAccess('templateCreatorV2');

  return (
    <StickyContent hasPageHeaderSpacings>
      <SectionHeader icon={<TemplatesIcon />}>
        {t('templates:templates')}
      </SectionHeader>
      <Text
        sx={theme => ({
          marginBottom: theme.other.spacing(4),
        })}
        size="md"
      >
        {t('templates:pageDescription')}
      </Text>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {hasTemplatesCreateAccess &&
          (isTemplateCreatorV2Enabled ? (
            <Button color="green" component={Link} to={URLS.templateAdd}>
              {t('templates:createTemplate')}
            </Button>
          ) : (
            <Button color="green" component="a" href={getV1Url('templatesAdd')}>
              {t('templates:createTemplate')}
            </Button>
          ))}
        <TextInputReactRouterSearch
          sx={{
            maxWidth: 328,
            width: '100%',
            marginLeft: 'auto',
          }}
          placeholder={t('templates:searchTemplate')}
        />
      </Box>
    </StickyContent>
  );
};
