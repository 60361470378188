import { Range } from 'immutable';
import { Block } from 'slate';

import createRow from './createRow';

/**
 * Create a table
 */
export default function createTable(
  opts,
  columns,
  rows,
  getCellContent, // ?: (row: number, column: number) => Node[],
) {
  const rowNodes = Range(0, rows)
    .map(i =>
      createRow(
        opts,
        columns,
        getCellContent ? getCellContent.bind(null, i) : undefined,
      ),
    )
    .toList();

  return Block.create({
    type: opts.typeTable,
    nodes: rowNodes,
  });
}
