/**
 * Return the current list item, from current selection or from a node.
 */
export default function getCurrentItem(opts, value, block) {
  const { document } = value;

  if (!block) {
    if (!value.selection.start.key) {
      return null;
    }

    block = value.startBlock;
  }

  const parent = document.getParent(block.key);
  return parent && parent.type === opts.typeItem ? parent : null;
}
