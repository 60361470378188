import { TablePosition } from '../utils';

import removeRowByKey from './removeRowByKey';

/**
 * Remove current row in a table. Clear it if last remaining row
 */
export default function removeRow(opts, change, at) {
  const { value } = change;
  const { start } = value.selection;
  const pos = TablePosition.create(opts, value.document, start.key);
  let rowKey;

  if (typeof at === 'undefined') {
    rowKey = pos.row.key;
  } else {
    rowKey = pos.table.nodes.get(at).key;
  }

  return removeRowByKey(opts, change, rowKey);
}
