import { Box, Button } from '@mantine/core';
import { DescriptionField, TextInputSearch } from 'components/shared';
import { SearchIcon } from 'icons';
import { isValidNip } from '../../utils';

export const CompanyGusSearch = ({
  fetchCompany,
  isLoading,
  companySearchValue,
  setCompanySearchValue,
  companySearchError,
  setCompanySearchError,
}) => {
  return (
    <DescriptionField
      label="Wyszukaj kontakt w bazie"
      description="Wpisz NIP bez myślników i innych znaków specjalnych"
    >
      <Box
        sx={theme => ({
          display: 'flex',
          flexDirection: 'row',
          marginBottom: theme.other.spacing(1.25),
          justifyContent: 'space-between',
          alignContent: 'center',
        })}
      >
        <TextInputSearch
          sx={() => ({ width: '85%' })}
          autoFocus
          error={companySearchError}
          value={companySearchValue}
          onPaste={event => {
            event.preventDefault();
            const pastedText = event.clipboardData.getData('Text');
            const sanitizedText = pastedText.replace(/-/g, '');

            setCompanySearchValue(sanitizedText);
          }}
          onKeyDown={event => {
            if (event.key === 'Enter' && companySearchValue !== '') {
              fetchCompany();
            }

            const isNumberKey = event.key >= '0' && event.key <= '9';
            const isControlKey =
              event.key === 'Backspace' ||
              event.key === 'Delete' ||
              event.key === 'ArrowLeft' ||
              event.key === 'ArrowRight' ||
              event.key === 'Tab' ||
              (event.ctrlKey &&
                (event.key === 'v' ||
                  event.key === 'c' ||
                  event.key === 'x')) ||
              event.metaKey;

            if (!isNumberKey && !isControlKey) {
              event.preventDefault();
            }
          }}
          type="number"
          onChange={e => {
            setCompanySearchValue(e.target.value);
          }}
          onBlur={e => {
            const isValid = isValidNip(e.target.value);

            if (isValid) {
              setCompanySearchError(null);
            } else {
              setCompanySearchError('Niepoprawny nip');
            }
          }}
        />
        <Button
          loading={isLoading}
          sx={() => ({
            display: 'flex',
            width: '38px',
            height: '38px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
            border: '1px solid #E6E6E6',
            background: '#685EF4',
            marginTop: '-1px',
            '&[data-loading]': {
              '.mantine-Button-leftIcon': {
                marginLeft: '8px',
              },
            },
          })}
          onClick={() => {
            if (companySearchValue !== '') {
              fetchCompany();
            }
          }}
        >
          {!isLoading && <SearchIcon color="#F7F8FF" />}
        </Button>
      </Box>
    </DescriptionField>
  );
};
