/**
 * Run the given function against each cells of the table
 */
export default function forEachCells(
  opts,
  table,
  fn, // (cell: Block, row: number, column: number) => any,
) {
  return table.nodes.forEach((row, rowIndex) =>
    row.nodes.forEach((cell, columnIndex) => fn(cell, rowIndex, columnIndex)),
  );
}
