import { useFormContext } from 'react-hook-form';
import { LoopWrapperContext } from '../createRules';
import { evaluateConditions } from '../evaluateConditions';

export const ConditionalWrapper = ({ obj, template, children }) => {
  const condition = obj.data.get('condition');

  if (condition) return eval(condition) ? children : null;

  const { getValues } = useFormContext();

  return (
    <LoopWrapperContext.Consumer>
      {loopWrapperIndex =>
        evaluateConditions(
          obj.data.get('conditions'),
          template.document.data.variables,
          getValues(),
          loopWrapperIndex,
        )
          ? children
          : null
      }
    </LoopWrapperContext.Consumer>
  );
};
