import { STATIC_URLS } from './staticUrls';

export const AGREEMENTS_CONTENT = {
  privacy: {
    translationKey: 'agreeToDataProcessing',
    descriptionTranslationKey: 'agreeToDataProcessingDescription',
    source: {
      translationKey: 'agreeToDataProcessingSource',
      url: STATIC_URLS.privacyPolicy,
    },
    required: true,
  },
  terms: {
    translationKey: 'agreeToRegulations',
    descriptionTranslationKey: '',
    source: {
      translationKey: 'regulations',
      url: STATIC_URLS.termsAndConditions,
    },
    required: true,
  },
  marketing: {
    translationKey: 'agreeToMarketing',
    descriptionTranslationKey: 'agreeToMarketingDescription',
    source: {
      translationKey: 'agreeToMarketingSource',
      url: STATIC_URLS.privacyPolicy,
    },
    required: false,
  },
} as const;
