import { list } from 'legacy/TemplateCreatorEditor/plugins';
import { getListStyleType } from '../../TemplateCreatorEditor/plugins/list/utils';

export const OrderedList = ({ obj, slateObj, children }) => {
  const { document } = slateObj;

  return (
    <ol
      style={{
        marginLeft: list.utils.getItemDepth(slateObj, obj) === 0 ? 0 : 8,
        listStyleType: getListStyleType(document, obj),
        paddingLeft: '1.5rem',
      }}
    >
      {children}
    </ol>
  );
};
