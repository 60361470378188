export enum NOTIFICATION_TYPES {
  // @ts-ignore
  accountActivated = 'account_activated' as any,
  accountDeleted = 'account_deleted' as any,
  activateAccount = 'activate_account' as any,
  boardAccessGranted = 'board_access_granted' as any,
  boardDeleted = 'board_deleted' as any,
  boardInvitation = 'board_invitation' as any,
  certificateExpire = 'certificate_expire' as any,
  contractorJoinedDocument = 'contractor_joined_document' as any,
  contractorRejectedDocument = 'contractor_rejected_document' as any,
  contractorRejectedDocumentInvitation = 'contractor_rejected_document_invitation' as any,
  contractorRemovedFromDocument = 'contractor_removed_from_document' as any,
  documentCreatedFromOffer = 'document_created_from_offer' as any,
  documentDeleted = 'document_deleted' as any,
  documentFinished = 'document_finished' as any,
  documentLink = 'document_link' as any,
  documentQueueUpdated = 'document_queue_updated' as any,
  documentSignInvitation = 'document_sign_invitation' as any,
  documentUpdated = 'document_updated' as any,
  documentWaitingForAcceptance = 'document_waiting_for_acceptance' as any,
  documentWaitingForSignature = 'document_waiting_for_signature' as any,
  emailChanged = 'email_changed' as any,
  organisationDelete = 'organisation_delete' as any,
  otpCode = 'otp_code' as any,
  passwordChange = 'password_change' as any,
  passwordChangedConfirmation = 'password_changed_confirmation' as any,
  userLeftOrganisation = 'user_left_organisation' as any,
  userRemovedFromBoard = 'user_removed_from_board' as any,
}
