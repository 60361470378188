import { useMantineTheme } from '@mantine/core';
import { isTableRowFirst } from '../utils';
import { LoopWrapperContextProvider } from '../../loopWrapper/context';
import { LoopWrapperToolBar } from '../../loopWrapper/LoopWrapperToolBar';

export const TableRow = ({ attributes, children, editor, node }) => {
  const data = node.data.toJS();
  const theme = useMantineTheme();

  if (data.loop) {
    return (
      <LoopWrapperContextProvider>
        <div style={{ marginTop: 10 }}>
          <LoopWrapperToolBar
            editor={editor}
            node={node}
            onDisable={(_, editor, node) => {
              editor.setNodeByKey(node.key, { data: {} });
            }}
          />
        </div>
        <tr {...attributes}>{children}</tr>
        <div style={{ marginBottom: 10 }} />
      </LoopWrapperContextProvider>
    );
  }

  const style = isTableRowFirst(editor.value.document, node)
    ? {
        backgroundColor: theme.colors.gray[1],
        fontWeight: 700,
      }
    : {};

  return (
    <tr style={style} {...attributes}>
      {children}
    </tr>
  );
};
