import { Box } from '@mantine/core';

import { PaperCard } from 'components/shared';

import { getPanelWrapperWidthStyles } from '../helpers';
import { Notifications } from './Notifications';
import { Agreements } from './Agreements';
// import { Cookies } from './Cookies';

export const NotificationsAndAgreements = () => (
  <Box sx={getPanelWrapperWidthStyles}>
    <PaperCard
      sx={theme => ({
        marginBottom: theme.other.spacing(2),
      })}
    >
      <Notifications />
    </PaperCard>
    <PaperCard
      sx={theme => ({
        marginBottom: theme.other.spacing(2),
      })}
    >
      <Agreements />
    </PaperCard>
    {/* NOTE: cookies and GTM temporarily hidden in production */}
    {/* <PaperCard>
      <Cookies />
    </PaperCard> */}
  </Box>
);
