import { Box, Tooltip as MantineTooltip } from '@mantine/core';
import { InfoIcon } from 'icons';
import { useTemplateStore } from 'stores';

export const Tooltip = ({ obj }) => {
  const { previewEditMode } = useTemplateStore();

  return (
    previewEditMode && (
      <MantineTooltip label={obj.data.get('text')} position="top">
        <Box
          sx={{
            display: 'inline-block',
            position: 'relative',
            transform: 'translateY(3px)',
          }}
        >
          <InfoIcon size={18} />
        </Box>
      </MantineTooltip>
    )
  );
};
