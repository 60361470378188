import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionIcon,
  Box,
  Tooltip,
  UnstyledButton,
  Popover,
  Text,
  Stack,
} from '@mantine/core';

import { VARIABLES } from 'legacy/TemplateCreatorEditor/consts';
import { ForceUpdateContext } from 'legacy/TemplateCreatorEditor/context';
import { VariableIcon } from 'legacy/TemplateCreatorEditor/icons';
import { VariableMapping } from './components';

import { getMoneyVariableIcon, getNextLanguageValue } from './helpers';
import { getVariableOptions } from '../../utils';
import { VariableContextMenu } from '../../VariableContextMenu';
import { VariableAddModal } from '../../VariableAddModal';
import { VariableEditModal } from '../../VariableEditModal';
import { LoopWrapperContext } from '../loopWrapper/context';

const Input = ({ attributes, node, editor, isActive }) => {
  const { t } = useTranslation('templates');

  const forceUpdate = useContext(ForceUpdateContext);
  const [popoverKind, setPopoverKind] = useState();
  const [isVariableAddModalOpen, setIsVariableAddModalOpen] = useState(false);
  const [isVariableEditModalOpen, setIsVariableEditModalOpen] = useState(false);
  const loopWrapperContext = useContext(LoopWrapperContext);

  const variables = getVariableOptions(editor.getVariables(), [], t);

  const nodeVariable = node.data.get('variable');

  const variable = variables.find(({ name }) => name === nodeVariable);
  const variableType = variable?.type;
  const variableIcon = variableType
    ? VARIABLES.find(({ value }) => value === variableType)?.icon
    : null;
  const variableLabel = variable?.predefined ? variable?.label : variable?.name;
  const loopIssues = variable ? variable.list && !loopWrapperContext : false;

  const getAdditionalContent = () => {
    if (variableType === 'money' && variable.translateMoneyValue) {
      const { defaultMoneyTranslation } = variable;
      const nodeMoneyTranslation =
        node.data.get('moneyTranslation') || defaultMoneyTranslation;
      const Icon = getMoneyVariableIcon(nodeMoneyTranslation);

      return (
        <span
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Tooltip label={t('templates:clickToChangeLanguageTranslation')}>
            <ActionIcon
              sx={theme => ({
                marginLeft: theme.other.spacing(0.5),
              })}
              component="span"
              onClick={() => {
                editor.updateNodeData(node, {
                  moneyTranslation: getNextLanguageValue(nodeMoneyTranslation),
                });

                forceUpdate();
              }}
            >
              <Icon size={16} />
            </ActionIcon>
          </Tooltip>
        </span>
      );
    }
  };

  const handleOnClick = event => {
    event.stopPropagation();
    setPopoverKind('selectValue');
  };

  const handleOnClosePopover = () => {
    setPopoverKind(undefined);
  };

  const handleOnClearClick = () => {
    setPopoverKind('selectValue');
    editor.updateNodeData(node, { variable: undefined });
  };

  const handleOnEditClick = () => {
    setPopoverKind(undefined);
    setIsVariableEditModalOpen(true);
  };

  const handleOnTrashClick = () => {
    setPopoverKind(undefined);
    editor.removeNodeByKey(node.key);
  };

  const handleOnCreateNewVariable = () => {
    setPopoverKind(undefined);
    setIsVariableAddModalOpen(true);
  };

  return (
    <>
      <Box
        sx={theme => ({
          position: 'relative',
          display: 'inline-block',
          margin: `3px 3px 5px`,
          userSelect: 'none',
          verticalAlign: 'middle',
        })}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Stack spacing={1}>
          <Popover
            opened={!!popoverKind}
            exitTransitionDuration={0}
            withinPortal
            onClose={handleOnClosePopover}
            position="bottom-start"
          >
            <Popover.Target>
              <UnstyledButton
                onClick={handleOnClick}
                sx={theme => {
                  const activeBorderColor =
                    theme.focusRingStyles.inputStyles(theme).borderColor;

                  const getBorderColor = () => {
                    if (isActive) {
                      return activeBorderColor;
                    }

                    return nodeVariable
                      ? theme.colors.gray[2]
                      : theme.colors.red[0];
                  };

                  return {
                    minHeight: '38px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: theme.other.spacing(1),
                    padding: theme.other.spacing(0.75, 2),
                    fontSize: theme.fontSizes.lg,
                    fontWeight: 500,
                    backgroundColor: nodeVariable
                      ? theme.colors.gray[1]
                      : theme.colors.red[1],
                    border: `1px solid ${theme.colors.gray[2]}`,
                    borderColor: getBorderColor(),
                    borderRadius: theme.radius.sm,
                  };
                }}
              >
                {variableIcon ? variableIcon : <VariableIcon color="blue" />}
                <Box sx={{ maxWidth: 240 }} component="span">
                  {nodeVariable ? variableLabel : t('templates:chooseVariable')}
                </Box>
                {variable && (
                  <VariableContextMenu
                    onOpen={handleOnClosePopover}
                    onClearClick={handleOnClearClick}
                    onEditClick={handleOnEditClick}
                    onRemoveClick={handleOnTrashClick}
                  />
                )}
                {getAdditionalContent()}
              </UnstyledButton>
            </Popover.Target>
            <Popover.Dropdown sx={{ padding: 0 }}>
              {popoverKind === 'selectValue' && (
                <VariableMapping
                  {...attributes}
                  node={node}
                  onEdit={handleOnEditClick}
                  onClose={handleOnClosePopover}
                  onCreateNew={handleOnCreateNewVariable}
                />
              )}
            </Popover.Dropdown>
          </Popover>
          {loopIssues && (
            <Text color="red">
              {t('templates:templateCreatorVariables.listIssues')}
            </Text>
          )}
        </Stack>
      </Box>
      <VariableAddModal
        isOpen={isVariableAddModalOpen}
        onClose={() => {
          setIsVariableAddModalOpen(false);
        }}
        addVariableCallback={({ name }) => {
          editor.updateNodeData(node, { variable: name });
        }}
        defaultType={attributes.defaultType}
        allowedTypes={attributes.allowedTypes}
      />
      <VariableEditModal
        isOpen={isVariableEditModalOpen}
        onClose={() => {
          setIsVariableEditModalOpen(false);
        }}
        variableName={nodeVariable}
        editVariableCallback={({ name }) => {
          editor.updateNodeData(node, { variable: name });
          forceUpdate();
        }}
        allowedTypes={attributes.allowedTypes}
      />
    </>
  );
};

export const input = {
  renderNode: ({ attributes, node, isSelected, isFocused }, editor, next) => {
    if (node.type === 'input') {
      return (
        <Input
          attributes={attributes}
          node={node}
          editor={editor}
          isActive={isSelected && isFocused}
        />
      );
    }

    return next();
  },
  schema: {
    inlines: {
      input: {
        isVoid: true,
      },
    },
  },
};
