import { Link } from 'react-router-dom';
import { Anchor, Box } from '@mantine/core';

import { DYNAMIC_URLS } from 'consts';
import { useGetV1Url } from 'hooks';
import { OpenIcon } from 'icons';

type Props = {
  name: string;
  templateId: string;
  v2Enabled: boolean | null;
  isLocalTemplate: boolean;
};

export const TemplateRowLink = ({
  name,
  templateId,
  v2Enabled,
  isLocalTemplate,
}: Props) => {
  const getV1Url = useGetV1Url();
  const sx = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    color: 'inherit',
    svg: {
      visibility: 'hidden',
    },
    ':hover': {
      svg: {
        visibility: 'visible',
      },
    },
  };
  const children = (
    <>
      <Box
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        component="span"
      >
        {name}
      </Box>
      <OpenIcon
        sx={theme => ({
          flexShrink: 0,
          marginTop: -2,
          marginLeft: theme.other.spacing(0.25),
        })}
      />
    </>
  );

  return v2Enabled ? (
    <Anchor
      // @ts-expect-error
      sx={sx}
      component={Link}
      to={DYNAMIC_URLS.templateEdit({ templateId: templateId.toString() })}
    >
      {children}
    </Anchor>
  ) : (
    <Anchor
      // @ts-expect-error
      sx={sx}
      href={
        isLocalTemplate
          ? getV1Url('templatesNew', { localTemplateId: templateId })
          : getV1Url('template', { templateId })
      }
      target="_blank"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {children}
    </Anchor>
  );
};
