import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from 'icons/types';

export const VariableValueIcon = ({
  color,
  size = 17,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color); //"#004FC4"

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={iconColor}
        d="M1.568 8c0 4.484 2.666 6.173 2.78 6.243a.616.616 0 0 1-.636 1.054C3.574 15.214.337 13.21.337 8 .337 2.788 3.574.786 3.712.703a.615.615 0 0 1 .636 1.054c-.124.076-2.78 1.767-2.78 6.243ZM12.96.703a.615.615 0 1 0-.636 1.054c.114.07 2.781 1.759 2.781 6.243s-2.667 6.173-2.778 6.241a.615.615 0 1 0 .633 1.056c.138-.083 3.376-2.086 3.376-7.297 0-5.212-3.238-7.214-3.376-7.297Z"
      />
      <path
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m5.875 11.692 5.539-7.384m-5.539 0 5.539 7.384"
      />
    </Box>
  );
};
