import Options from './options';
import { onEnter, onTab, onBackspace } from './handlers';
import core from './core';

const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';
const KEY_BACKSPACE = 'Backspace';

/**
 * User is pressing a key in the editor
 */
function onKeyDown(opts, event, editor, next) {
  const args = [event, editor, opts];

  switch (event.key) {
    case KEY_ENTER:
      return onEnter(...args, next);
    case KEY_TAB:
      return onTab(...args, next);
    case KEY_BACKSPACE:
      return onBackspace(...args, next);
    default:
      return next();
  }
}

/**
 * A Slate plugin to handle keyboard events in lists.
 */
export const editList = (
  // Options for the plugin
  opts = {},
) => {
  opts = new Options(opts);
  const corePlugin = core(opts);

  return {
    ...corePlugin,
    onKeyDown: onKeyDown.bind(null, opts),
  };
};
