import { Block } from 'slate';

/**
 * Clear the content of the given cell
 */
export default function clearCell(opts, change, cell) {
  const newBlock = Block.create({ type: opts.typeContent });
  const { nodes } = cell;

  // Insert a new empty node
  change.withoutNormalizing(() => {
    change.insertNodeByKey(cell.key, 0, newBlock);
  });

  // Remove all previous nodes
  nodes.forEach(node => {
    change.removeNodeByKey(node.key);
  });

  return change;
}
