import { forwardRef, ReactNode, useRef, useState } from 'react';
import {
  NumberInput as NumberInputMantine,
  NumberInputProps,
  packSx,
} from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';

import { API_NUMBER_LIMIT } from 'consts';

import { useGetClearableInputRightSectionData } from '../hooks';
import { ClearableInputRightSection } from '../ClearableInputRightSection';

export type Props = {
  /* value and onchange required add here */
  rightIcon?: ReactNode;
  rightIconAction?: () => void;
  withErrorWrapperAlwaysEnabled?: boolean;
} & NumberInputProps;

export const NumberInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      sx,
      error,
      rightIcon,
      rightIconAction,
      withErrorWrapperAlwaysEnabled = true,
      hideControls = true,
      value,
      disabled,
      onBlur,
      onFocus,
      max = API_NUMBER_LIMIT,
      ...numberInputMantineProps
    },
    ref,
  ) => {
    const { inputPaddingRight, rightSectionWidth } =
      useGetClearableInputRightSectionData(Boolean(rightIcon));

    const [isFocused, setIsFocused] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const mergedRef = useMergedRef(inputRef, ref);

    const isClearable = isFocused && value !== undefined;

    return (
      <NumberInputMantine
        sx={[
          {
            input: {
              paddingRight: hideControls ? inputPaddingRight : undefined,
            },
            '.mantine-NumberInput-rightSection': {
              right: hideControls ? undefined : -1,
            },
          },

          ...packSx(sx),
        ]}
        ref={mergedRef}
        disabled={disabled}
        value={value}
        /* TODO: remove empty space */
        error={!error && withErrorWrapperAlwaysEnabled ? ' ' : error}
        max={max}
        rightSection={
          hideControls ? (
            <ClearableInputRightSection
              isClearable={isClearable}
              inputRef={inputRef}
              rightIcon={rightIcon}
              rightIconAction={rightIconAction}
              disabled={disabled}
            />
          ) : undefined
        }
        rightSectionWidth={hideControls ? rightSectionWidth : undefined}
        onBlur={e => {
          setIsFocused(false);

          if (onBlur) {
            onBlur(e);
          }
        }}
        onFocus={e => {
          setIsFocused(true);

          if (onFocus) {
            onFocus(e);
          }
        }}
        {...numberInputMantineProps}
      />
    );
  },
);
