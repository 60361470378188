import { useGetColor } from 'hooks';

import { Box } from '@mantine/core';

import { IconPropsType } from 'icons/types';

export const InsertColumnIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="-4 -3 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeWidth={1.5}
        d="M.75.753H8a2.25 2.25 0 0 1 2.25 2.25v14A2.25 2.25 0 0 1 8 19.253H.75V.753ZM1 7.253h10M1 13.253h10"
      />
      <g clipPath="url(#a)">
        <path
          fill={iconColor}
          d="M16 6.003c.26 0 .52.098.715.293a.982.982 0 0 1 .293.716v2h2c.26 0 .52.097.716.292a.982.982 0 0 1 .292.716c0 .276-.097.52-.292.715a.982.982 0 0 1-.716.293h-2v2c0 .26-.097.52-.293.715a.982.982 0 0 1-.715.293.982.982 0 0 1-.715-.293.982.982 0 0 1-.293-.715v-2h-1.984c-.26 0-.52-.098-.715-.293A.982.982 0 0 1 12 10.02c0-.277.098-.52.293-.716a.982.982 0 0 1 .715-.292h2v-2c0-.26.098-.52.293-.716a.982.982 0 0 1 .715-.293H16Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M12 6.003h8v8h-8z" />
        </clipPath>
      </defs>
    </Box>
  );
};
