import { legacyEditListPlugin } from './legacy';
import { getListDepthLevel, getListStyleType } from './utils';

import { List } from '@mantine/core';
import { ListItem } from './components';

const legacyEditList = legacyEditListPlugin({
  types: ['orderedList', 'unorderedList'],
  typeItem: 'listItem',
});

export const list = {
  ...legacyEditList,
  renderNode: (
    { attributes, node, children, isSelected, isFocused },
    editor,
    next,
  ) => {
    switch (node.type) {
      case 'orderedList':
        return (
          <List
            type="ordered"
            listStyleType={getListStyleType(editor.value.document, node)}
            {...attributes}
            style={
              getListDepthLevel(editor.value.document, node) > 0
                ? {
                    marginLeft: -150,
                    marginTop: 8,
                  }
                : undefined
            }
          >
            {children}
          </List>
        );
      case 'listItem':
        return (
          <ListItem
            editor={editor}
            attributes={attributes}
            node={node}
            isActive={isSelected && isFocused}
          >
            {children}
          </ListItem>
        );
      default:
        return next();
    }
  },
  commands: {
    toggleListWrap: editor => {
      if (legacyEditList.utils.isSelectionInList(editor.value)) {
        editor.command(legacyEditList.changes.unwrapList);
      } else {
        editor.command(legacyEditList.changes.wrapInList);
      }
    },
  },
};
