import { ThemeComponentType } from '../types';

export const Code: ThemeComponentType = {
  styles: theme => ({
    root: {
      padding: theme.other.spacing(2),
      backgroundColor: theme.colors.gray[1],
      fontSize: theme.fontSizes.md,
      color: theme.colors.gray[7],
    },
  }),
};
