import { Box } from '@mantine/core';
import { XIcon } from 'icons';

export const TabsErrorIcon = () => {
  return (
    <Box
      sx={() => ({
        display: 'flex',
        width: '24px',
        height: '24px',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: '27px',
        background: '#FDEBEB',
      })}
    >
      <XIcon />
    </Box>
  );
};
