import { TablePosition } from '../utils';

import removeColumnByKey from './removeColumnByKey';

/**
 * Delete current column in a table
 */
export default function removeColumn(opts, change, at) {
  const { value } = change;
  const { start } = value.selection;
  const pos = TablePosition.create(opts, value.document, start.key);

  let columnKey;
  if (typeof at === 'undefined') {
    columnKey = pos.cell.key;
  } else {
    columnKey = pos.row.nodes.get(at).key;
  }

  return removeColumnByKey(opts, change, columnKey);
}
