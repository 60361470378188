import { Fragment } from 'react';
import { Box } from '@mantine/core';

import { SectionDivider } from '../SectionDivider';
import { HistoryControlSection } from './HistoryControlSection';
import { TextBlockTypesSection } from './TextTypesSection';
import { TextSizeSection } from './TextSizeSection';
import { InterlineSection } from './InterlineSection';
import { TextDecoratorsSection } from './TextDecoratorsSection';
import { TextAlignmentSection } from './TextAlignmentSection';

const SECTION_COMPONENTS = [
  HistoryControlSection,
  TextBlockTypesSection,
  TextSizeSection,
  InterlineSection,
  TextDecoratorsSection,
  TextAlignmentSection,
];

export const TopSection = () => {
  return (
    <Box
      sx={theme => ({
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: theme.other.spacing(2),
        padding: theme.other.spacing(2),
        borderBottom: `1px solid ${theme.colors.blue[2]}`,
      })}
    >
      {SECTION_COMPONENTS.map((Component, index) => (
        <Fragment key={Component.name}>
          <Component />
          {index !== SECTION_COMPONENTS.length - 1 && <SectionDivider />}
        </Fragment>
      ))}
    </Box>
  );
};
