import { DescriptionField } from 'components/shared';
import { Select } from '@mantine/core';
import { Controller } from 'react-hook-form';

export const FormSelectField = ({
  error,
  field,
  control,
  withoutBorder = false,
}) => {
  return (
    <DescriptionField
      label={field.label}
      description={field.description}
      error={error}
      withoutBorder={withoutBorder}
    >
      <Controller
        name={field.name}
        control={control}
        render={({ field: formField }) => (
          <Select
            {...formField}
            defaultValue={null}
            placeholder={field.placeholder}
            error={null}
            data={field.options}
            searchable
          />
        )}
      />
    </DescriptionField>
  );
};
