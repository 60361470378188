import { DescriptionField } from 'components/shared';

import { ContractorPicker } from './ContractorPicker';
import { useTranslation } from 'react-i18next';

export const InitialCompaniesValue = ({ value, setValue, error }) => {
  const { t } = useTranslation('templates');

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.initialValue')}
      description={t(
        'templates:templateCreatorVariables.initialValueDescription',
      )}
    >
      <ContractorPicker value={value} setValue={setValue} error={error} />
    </DescriptionField>
  );
};
