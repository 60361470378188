import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { PaperCard } from 'components/shared';
import {
  EMPTY_CONDITION,
  EMPTY_GROUP,
} from 'legacy/TemplateCreatorEditor/consts';

import { ManageButtons } from './ManageButtons';
import { LogicalOperatorSwitch } from './LogicalOperatorSwitch';
import {
  getVariableConditionsWithoutItem,
  getVariableConditionsWithUpdatedItem,
} from './helpers';
import { VariableCondition } from './VariableCondition';

const GROUP_CLASS_NAME = 'group';
const CONDITION_CLASS_NAME = 'condition';

export const VariableConditions = ({
  className,
  logicalOperator,
  conditions,
  currentPath = [],
  setVariableConditions,
}) => {
  const { t } = useTranslation('templates');

  const hasOnlyOneItem = conditions.length === 1;
  const isFirstLevel = currentPath.length === 0;

  const deleteGroup = () => {
    setVariableConditions(currentVariableConditions =>
      getVariableConditionsWithoutItem(currentVariableConditions, currentPath),
    );
  };

  const manageButtons = (
    <ManageButtons
      addGroup={() => {
        setVariableConditions(currentVariableConditions =>
          getVariableConditionsWithUpdatedItem(
            currentVariableConditions,
            [...currentPath, 'conditions'],
            [EMPTY_GROUP],
          ),
        );
      }}
      addCondition={() => {
        setVariableConditions(currentVariableConditions =>
          getVariableConditionsWithUpdatedItem(
            currentVariableConditions,
            [...currentPath, 'conditions'],
            [EMPTY_CONDITION],
          ),
        );
      }}
      deleteGroup={isFirstLevel ? undefined : deleteGroup}
    />
  );

  return (
    <PaperCard
      className={className}
      sx={theme => ({
        display: 'grid',
        gap: theme.other.spacing(2),
      })}
    >
      {!hasOnlyOneItem && (
        <LogicalOperatorSwitch
          value={logicalOperator}
          onChange={nextLogicalOperator => {
            setVariableConditions(currentVariableConditions =>
              getVariableConditionsWithUpdatedItem(
                currentVariableConditions,
                currentPath,
                { logicalOperator: nextLogicalOperator },
              ),
            );
          }}
        />
      )}
      {conditions.length === 0 && (
        <>
          <Text color="red">
            {t(
              'templates:templateCreatorVariables.addAtLeastOneConditionOrDeleteGroup',
            )}
          </Text>
          {manageButtons}
        </>
      )}
      <Box
        sx={
          hasOnlyOneItem
            ? undefined
            : theme => ({
                display: 'grid',
                gap: theme.other.spacing(2),
                position: 'relative',
                paddingLeft: 34,
                ':before': {
                  content: '""',
                  position: 'absolute',
                  width: 1.5,
                  height: '100%',
                  backgroundColor: theme.colors.blue[0],
                  zIndex: 2,
                  top: 0,
                  left: 0,
                },
              })
        }
      >
        {conditions.map((condition, conditionIndex) => {
          const { variableName, comparator, otherValue } = condition;

          const conditionPath = [...currentPath, 'conditions', conditionIndex];

          const deleteCondition = () => {
            setVariableConditions(currentVariableConditions =>
              getVariableConditionsWithoutItem(currentVariableConditions, [
                ...currentPath,
                'conditions',
                conditionIndex,
              ]),
            );
          };

          return (
            <Box
              key={`${currentPath.join()}${conditionIndex}`}
              sx={theme => {
                const itemBeforeStyles = {
                  ':before': {
                    content: '""',
                    position: 'absolute',
                    width: 21,
                    height: 1.5,
                    backgroundColor: theme.colors.blue[0],
                    top: 0,
                    bottom: 0,
                    left: -34,
                    margin: 'auto',
                  },
                };

                return {
                  display: 'grid',
                  gap: 16,
                  position: 'relative',
                  minWidth: 680,
                  ...(!hasOnlyOneItem && {
                    '>': {
                      [`.${GROUP_CLASS_NAME}`]: itemBeforeStyles,
                      [`.${CONDITION_CLASS_NAME}`]: {
                        '.VariableCondition_Autocomplete': {
                          '.mantine-Input-wrapper': itemBeforeStyles,
                        },
                      },
                    },
                  }),
                };
              }}
            >
              {condition.conditions ? (
                <VariableConditions
                  className={GROUP_CLASS_NAME}
                  logicalOperator={condition.logicalOperator}
                  conditions={condition.conditions}
                  currentPath={conditionPath}
                  setVariableConditions={setVariableConditions}
                />
              ) : (
                <VariableCondition
                  className={CONDITION_CLASS_NAME}
                  variableName={variableName}
                  comparator={comparator}
                  otherValue={otherValue}
                  deleteCondition={
                    isFirstLevel && hasOnlyOneItem && !conditions[0].conditions
                      ? undefined
                      : () => {
                          if (conditions.length === 1) {
                            deleteGroup();
                          } else {
                            deleteCondition();
                          }
                        }
                  }
                  changeComparator={nextComparator => {
                    setVariableConditions(currentVariableConditions =>
                      getVariableConditionsWithUpdatedItem(
                        currentVariableConditions,
                        conditionPath,
                        { comparator: nextComparator, otherValue: null },
                      ),
                    );
                  }}
                  changeVariableName={nextVariableName => {
                    setVariableConditions(currentVariableConditions =>
                      getVariableConditionsWithUpdatedItem(
                        currentVariableConditions,
                        conditionPath,
                        { variableName: nextVariableName },
                      ),
                    );
                  }}
                  changeOtherValue={nextOtherValue => {
                    setVariableConditions(currentVariableConditions =>
                      getVariableConditionsWithUpdatedItem(
                        currentVariableConditions,
                        conditionPath,
                        { otherValue: nextOtherValue },
                      ),
                    );
                  }}
                />
              )}
              {conditions.length - 1 === conditionIndex && manageButtons}
            </Box>
          );
        })}
      </Box>
    </PaperCard>
  );
};
