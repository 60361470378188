declare global {
  interface Window {
    dataLayer?: any[];
  }
}

const getCookieDomain = () => {
  const hostElements = window.location.host.split('.');

  return hostElements.reduce((acc, element, i) => {
    let appendix = i >= hostElements.length - 2 ? element.split(':')[0] : '';
    appendix += i < hostElements.length - 1 ? '.' : '';
    return acc + appendix;
  }, '');
};

export const getGoogleAnalyticsHelper = () => ({
  clearCookies: () => {
    const domain = getCookieDomain();
    document.cookie.split(';').forEach(cookie => {
      if (/_ga(_[A-Z0-9]{10})?/.test(cookie)) {
        const cookieName = cookie.split('=')[0].trim();
        const dueExpirationDate = new Date(0).toUTCString();

        document.cookie = `${cookieName}=;expires=${dueExpirationDate};path=/;domain=${domain}`;
      }
    });
  },
  initialize: () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'initialize_ga' });
  },
});
