import { useQuery } from '@tanstack/react-query';
import { API_QUERY_KEYS } from 'consts';
import { getCompanies } from '../requests';
import { GetCompaniesRequestType } from '../types';

export const useGetCompanies = (
  {
    pathParams: { boardId },
    queryParams: { email, search, perPage, sortBy } = {},
  }: GetCompaniesRequestType,
  options?: any,
) =>
  useQuery({
    queryKey: [
      API_QUERY_KEYS.companiesList,
      { boardId, email, search, perPage, sortBy },
    ],
    queryFn: () =>
      getCompanies({
        pathParams: { boardId },
        queryParams: { email, search, perPage, sortBy },
      }),
    ...options,
  });
