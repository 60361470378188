import { Box } from '@mantine/core';
import { IconPropsType } from 'icons/types';

export const EraseIcon = ({ size = 24, sx, ...props }: IconPropsType) => (
  <Box
    component="svg"
    sx={sx}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#1E1C49"
      d="m19.46 8.34-3.184-3.183a1.846 1.846 0 0 0-2.61 0L4.54 14.282a1.846 1.846 0 0 0 0 2.61l2.311 2.312a.616.616 0 0 0 .437.18h11.478a.615.615 0 0 0 0-1.23h-6.513l7.205-7.203a1.845 1.845 0 0 0 0-2.61Zm-8.946 9.813h-2.97l-2.132-2.131a.615.615 0 0 1 0-.87l4.127-4.127 4.052 4.052-3.077 3.076Zm8.075-8.074-4.128 4.128-4.052-4.052 4.129-4.126a.614.614 0 0 1 .87 0L18.59 9.21a.615.615 0 0 1 0 .87l-.001-.002Z"
    />
  </Box>
);
