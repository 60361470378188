import { Stack } from '@mantine/core';
import { useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getZodSchemaErrorMessages } from 'helpers';
import { Modal } from 'components/shared';
import { EMPTY_GROUP } from 'legacy/TemplateCreatorEditor/consts';
import { EditorContext } from 'legacy/TemplateCreatorEditor/context';

import { VariableConditions } from '../VariableConditions';
import { VariableTypeFields, Name, Type } from '../VariableTypeFields';
import { useGetVariableNameError } from './hooks';

export const VariableEditModal = ({
  isOpen,
  onClose,
  variableName,
  editVariableCallback,
  allowedTypes,
}) => {
  const { t } = useTranslation(['common', 'templates']);

  const editor = useContext(EditorContext);

  const variable = editor.getVariable(variableName);
  const {
    name: initialName,
    type: initialType,
    requiredWhen,
    ...initialValues
  } = variable;
  const initialRequiredWhen = requiredWhen || EMPTY_GROUP;

  const getVariableNameError = useGetVariableNameError();

  const [name, setName] = useState(initialName);
  const [nameError, setNameError] = useState('');
  const [type, setType] = useState(initialType);
  const [variableConditions, setVariableConditions] = useState(null);
  const [
    variableConditionsLastStateOnModalOpen,
    setVariableConditionsLastStateOnModalOpen,
  ] = useState(null);
  const [isEditConditionModeEnabled, setIsEditConditionModeEnabled] =
    useState(false);

  const fieldsInstanceRef = useRef(null);

  const handleClose = () => {
    onClose();

    setName(initialName);
    setNameError('');
    setType(initialType);
    setVariableConditions(initialRequiredWhen);
    fieldsInstanceRef.current = null;
  };

  const handleVariableConditionsModalClose = () => {
    setIsEditConditionModeEnabled(false);
    setVariableConditions(variableConditionsLastStateOnModalOpen);
  };

  useEffect(() => {
    setVariableConditions(initialRequiredWhen);
  }, [requiredWhen]);

  useEffect(() => {
    setName(initialName);
    setNameError('');
    setType(initialType);
  }, [initialName, initialType]);

  return (
    <>
      <Modal
        sx={{ visibility: isEditConditionModeEnabled ? undefined : 'hidden' }}
        size="xl"
        isOpen={isOpen}
        onClose={handleVariableConditionsModalClose}
        title={t('templates:addingCondition')}
        defaultButtonText={t('common:back')}
        defaultButtonAction={handleVariableConditionsModalClose}
        primaryButtonAction={() => {
          setIsEditConditionModeEnabled(false);
        }}
      >
        {Boolean(variableConditions) && (
          <VariableConditions
            conditions={variableConditions.conditions}
            logicalOperator={variableConditions.logicalOperator}
            setVariableConditions={setVariableConditions}
          />
        )}
      </Modal>
      <Modal
        sx={{ visibility: isEditConditionModeEnabled ? 'hidden' : undefined }}
        isOpen={isOpen}
        onClose={handleClose}
        size="xl"
        title={t('templates:templateCreatorVariables.editVariable')}
        isRedButtonLeftAligned
        redButtonAction={() => {
          editor.deleteVariable(initialName);

          handleClose();
        }}
        defaultButtonAction={handleClose}
        primaryButtonAction={() => {
          const { getValues, schema, clearErrors, setError } =
            fieldsInstanceRef.current;
          const values = getValues();

          const variableNameError = getVariableNameError(name, variableName);

          setNameError(variableNameError);

          const errors =
            getZodSchemaErrorMessages({
              schema,
              fields: values,
            }) || {};

          clearErrors();

          Object.entries(errors).forEach(([field, message]) => {
            setError(field, { message });
          });

          if (!(variableNameError || Object.keys(errors).length > 0)) {
            const updatedVariable = {
              ...values,
              name,
              type,
              requiredWhen: variableConditions,
            };

            editor.updateVariable(variableName, updatedVariable);

            if (editVariableCallback) {
              editVariableCallback(updatedVariable);
            }

            handleClose();
          }
        }}
      >
        <form>
          <Stack py={24} sx={{ gap: 24 }}>
            <Name
              value={name}
              onChange={value => {
                setNameError('');
                setName(value);
              }}
              error={nameError}
            />
            <Type value={type} onChange={setType} allowedTypes={allowedTypes} />
            <VariableTypeFields
              variableType={type}
              fieldsInstanceRef={fieldsInstanceRef}
              initialValues={initialType === type ? initialValues : undefined}
              onOpenConditionsManagement={() => {
                setIsEditConditionModeEnabled(true);
                setVariableConditionsLastStateOnModalOpen(variableConditions);
              }}
            />
          </Stack>
        </form>
      </Modal>
    </>
  );
};
