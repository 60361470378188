export const CONFIG = {
  fontSize: 14,
  textAlign: 'left',
  lineHeight: 1.5,
  header: {
    fontSize: 21,
    fontWeight: 600,
    textAlign: 'center',
  },
  title: {
    fontSize: 30,
    textAlign: 'center',
  },
};
