import { Data } from 'slate';
import { List } from 'immutable';

import { isList } from '../utils';

/**
 * Returns the highest list of blocks that cover the current selection
 */
function getHighestSelectedBlocks(value) {
  const range = value.selection;
  const { document } = value;
  const startBlock = document.getClosestBlock(range.start.key);
  const endBlock = document.getClosestBlock(range.end.key);

  if (startBlock === endBlock) {
    return List([startBlock]);
  }
  const ancestor = document.getCommonAncestor(startBlock.key, endBlock.key);
  const startPath = ancestor.getPath(startBlock.key);
  const endPath = ancestor.getPath(endBlock.key);

  return ancestor.nodes.slice(startPath[0], endPath[0] + 1);
}

/**
 * Wrap the blocks in the current selection in a new list. Selected
 * lists are merged together.
 */
export default function wrapInList(
  opts,
  editor,
  type, // ?: string,
  data, // ?: Object | Data,
) {
  const selectedBlocks = getHighestSelectedBlocks(editor.value);
  type = type || opts.types[0];

  editor.withoutNormalizing(() => {
    // Wrap in container
    editor.wrapBlock({
      type,
      data: Data.create(data),
    });

    // Wrap in list items
    selectedBlocks.forEach(node => {
      if (isList(opts, node)) {
        // Merge its items with the created list
        node.nodes.forEach(({ key }) => editor.unwrapNodeByKey(key));
      } else {
        editor.wrapBlockByKey(node.key, opts.typeItem);
      }
    });
  });

  return editor;
}
