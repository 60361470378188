import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Loader, Space } from '@mantine/core';
import { DescriptionField, TextInput } from 'components/shared';
import { DynamicListPreviewModal } from '../DynamicListPreviewModal';

export const Url = ({
  inputProps,
  error,
  list,
  isValid,
  isChecking,
  onBlur,
  onListRefresh,
}) => {
  const { t } = useTranslation('templates');
  const [listPreviewModalOpen, setListPreviewModalOpen] = useState(false);

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.dynamicList.urlField.label')}
      description={t(
        'templates:templateCreatorVariables.dynamicList.urlField.description',
      )}
      error={error}
    >
      <TextInput
        {...inputProps}
        placeholder={t(
          'templates:templateCreatorVariables.dynamicList.urlField.label',
        )}
        withErrorWrapperAlwaysEnabled={false}
        onBlur={onBlur}
      />
      {isChecking && (
        <>
          <Space h="sm" />
          <Loader size="sm" />
        </>
      )}
      {isValid && (
        <>
          <Space h="sm" />
          <Button
            size="xs"
            variant="default"
            onClick={() => setListPreviewModalOpen(true)}
          >
            {t(
              'templates:templateCreatorVariables.dynamicList.urlField.previewButton',
            )}
          </Button>
        </>
      )}
      {list && (
        <DynamicListPreviewModal
          list={list}
          isOpen={listPreviewModalOpen}
          onClose={() => setListPreviewModalOpen(false)}
          onListRefresh={onListRefresh}
        />
      )}
    </DescriptionField>
  );
};
