import { apiAuthClient } from 'api/helpers';
import {
  GetCompanyGroupsRequestType,
  GetCompanyGroupsResponseType,
} from '../types';

export const getCompanyGroups = async ({
  pathParams: { boardId },
}: GetCompanyGroupsRequestType) => {
  const url = `/board/${boardId}/groups`;

  const { data } = await apiAuthClient.get<GetCompanyGroupsResponseType>(url);

  return data.data;
};
