import { MantineColor } from '@mantine/core';

export const getMantineColorAndShade = (
  color: MantineColor = 'currentColor',
) => {
  const [baseColor, shadeColor] = color.split('.');

  return shadeColor
    ? ([baseColor, Number(shadeColor)] as const)
    : ([baseColor] as const);
};
