import { TableToolbar } from './TableToolbar';

export const Table = ({
  attributes,
  children,
  editor,
  isFocused,
  isSelected,
  node,
  plugin,
}) => {
  return (
    <div {...attributes}>
      <TableToolbar
        editor={editor}
        node={node}
        isFocused={isFocused}
        isSelected={isSelected}
        plugin={plugin}
      />
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};
