import { DescriptionField, PhoneInput } from 'components/shared';
import { Controller } from 'react-hook-form';

export const FormPhoneInputField = ({
  control,
  error,
  field,
  withoutBorder = false,
}) => {
  return (
    <DescriptionField
      label={field.label}
      description={field.description}
      error={error}
      withoutBorder={withoutBorder}
    >
      <Controller
        name={field.name}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <PhoneInput {...field} inputProps={ref} error={error} />
        )}
      />
    </DescriptionField>
  );
};
