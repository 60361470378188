import * as yup from 'yup';
import { CONTRACTOR_TYPES } from '../../CompanyModal/constants';
import { supportedComparatorsPerVariable } from './utils';
import { getVariableOptions } from '../../utils';

export const schema = allVariables =>
  yup.object({
    logicalOperator: yup.string().oneOf(['or', 'and']).default('and'),
    conditions: yup
      .array(
        yup.lazy(value =>
          value.logicalOperator
            ? schema(allVariables)
            : yup.object({
                variableName: yup
                  .string()
                  .required()
                  .oneOf(
                    getVariableOptions(allVariables).map(v => v.name),
                    'required',
                  ),
                comparator: yup
                  .mixed()
                  .when('variableName', ([variableName], schema) => {
                    const variableNamePrefix = variableName.split('.')[0];
                    const mappedVariable = allVariables.find(
                      v => v.name === variableNamePrefix,
                    );

                    if (!mappedVariable) {
                      return yup.string();
                    }

                    return yup
                      .string()
                      .required()
                      .oneOf(
                        mappedVariable.type !== 'company'
                          ? supportedComparatorsPerVariable[mappedVariable.type]
                          : supportedComparatorsPerVariable.company(
                              variableName,
                            ),
                        'required',
                      );
                  }),
                otherValue: yup
                  .mixed()
                  .nullable()
                  .default(null)
                  .when(
                    ['comparator', 'variableName'],
                    ([comparator, variableName], schema) => {
                      if (
                        !variableName ||
                        ![
                          'isEqual',
                          'isNotEqual',
                          'isBigger',
                          'isSmaller',
                          'isBiggerOrEqual',
                          'isSmallerOrEqual',
                        ].includes(comparator)
                      ) {
                        return schema;
                      }

                      const variableNamePrefix = variableName.split('.')[0];
                      const mappedVariable = allVariables.find(
                        v => v.name === variableNamePrefix,
                      );

                      if (
                        !mappedVariable ||
                        (mappedVariable.type === 'company' &&
                          variableName.endsWith('differentContactAddress'))
                      ) {
                        return schema;
                      }

                      const variableType = mappedVariable.type;

                      if (variableType === 'integer') {
                        return yup.number().required().integer();
                      }

                      if (variableType === 'money') {
                        return yup
                          .number()
                          .required()
                          .min(1)
                          .max(9999999)
                          .test(
                            'decimal-places',
                            'Must have at most 2 decimal places',
                            value =>
                              value ? /^\d+(\.\d{1,2})?$/.test(value) : true,
                          );
                      }

                      if (
                        variableType === 'select' ||
                        variableType === 'tabs'
                      ) {
                        const validOptions = mappedVariable.options.map(
                          o => o.value,
                        );

                        return yup
                          .string()
                          .required()
                          .oneOf(validOptions, 'required');
                      }

                      if (
                        variableType === 'company' &&
                        variableName.endsWith('companyType')
                      ) {
                        return yup
                          .string()
                          .required()
                          .oneOf(Object.keys(CONTRACTOR_TYPES), 'required');
                      }

                      if (variableType === 'date') {
                        return yup.date().nullable().required();
                      }

                      return yup.string().nullable().required();
                    },
                  ),
              }),
        ),
      )
      .ensure()
      .min(1, 'addOneConditionOrRemoveGroup'), // those error messages are not important right now because we show one, generic error message when error occurs
  });
