import { useState } from 'react';
import { Text } from 'slate';
import { Box, Paper, TextInput } from '@mantine/core';
import { EMPTY_GROUP } from '../../consts';
import { VariableConditions } from '../../VariableConditions';
import { Modal } from '../../../../components/shared';
import { useTranslation } from 'react-i18next';
import { getConditionsHumanRepresentation } from './utils';
import { schema } from './schema';
import { VariableContextMenu } from '../../VariableContextMenu';

const Wrapper = ({ inline, attributes, children, isSelected, isFocused }) => (
  <Paper
    {...attributes}
    elevation={isSelected && isFocused ? 'md' : 'sm'}
    display={inline ? 'inline-block' : 'block'}
    sx={theme => ({ margin: theme.other.spacing(2, 0) })}
  >
    {children}
  </Paper>
);

const ConditionalWrapper = ({
  attributes,
  node,
  editor,
  children,
  isSelected,
  isFocused,
}) => {
  const { t } = useTranslation(['common', 'templates']);

  const inline = node.object === 'inline';
  const data = node.data.toJS();

  const [variableConditions, setVariableConditions] = useState(
    data.conditions || EMPTY_GROUP,
  );

  const variables = editor.getVariables();

  const conditionsHumanRepresentation = data.conditions
    ? getConditionsHumanRepresentation(data.conditions, variables, t)
    : '';

  const [isEditConditionModeEnabled, setIsEditConditionModeEnabled] =
    useState(false);

  const [
    variableConditionsLastStateOnModalOpen,
    setVariableConditionsLastStateOnModalOpen,
  ] = useState(null);

  const handleVariableConditionsModalClose = () => {
    setIsEditConditionModeEnabled(false);
    setVariableConditions(variableConditionsLastStateOnModalOpen);
  };

  const { conditions, logicalOperator } = variableConditions;

  let error;

  if (data.conditions) {
    try {
      schema(variables).validateSync(data.conditions, {
        abortEarly: true,
      });
    } catch (e) {
      error = true;
    }
  }

  const openVariableConditionModal = () => {
    setVariableConditionsLastStateOnModalOpen(variableConditions);
    setIsEditConditionModeEnabled(true);
  };

  const handleOnEditClick = () => {
    openVariableConditionModal();
  };

  const handleOnRemoveClick = () => {
    if (inline) {
      editor.unwrapInlineByKey(node.key, {
        type: 'conditionalWrapper',
      });
    } else {
      editor.unwrapBlockByKey(node.key, {
        type: 'conditionalWrapper',
      });
    }
  };

  return (
    <>
      {isEditConditionModeEnabled && (
        <Modal
          size="xl"
          isOpen={isEditConditionModeEnabled}
          onClose={handleVariableConditionsModalClose}
          title={t('templates:addingCondition')}
          defaultButtonText={t('common:back')}
          defaultButtonAction={handleVariableConditionsModalClose}
          primaryButtonAction={() => {
            setIsEditConditionModeEnabled(false);
            editor.setNodeByKey(node.key, {
              data: { conditions: variableConditions },
            });
          }}
        >
          <VariableConditions
            conditions={conditions}
            logicalOperator={logicalOperator}
            setVariableConditions={setVariableConditions}
          />
        </Modal>
      )}
      <Wrapper
        inline={inline}
        attributes={attributes}
        children={children}
        isSelected={isSelected}
        isFocused={isFocused}
      >
        <TextInput
          sx={theme => ({
            display: inline ? 'inline-block' : undefined,
            marginBottom: !inline ? theme.other.spacing(1) : undefined,
            input: { height: theme.other.spacing(4.75) },
            minWidth: theme.other.spacing(31.25),
          })}
          variant="filled"
          size="sm"
          error={
            data.conditions && error ? (
              <span contentEditable={false}>
                {t('templates:synchroniseWithVariables')}
              </span>
            ) : null
          }
          value={conditionsHumanRepresentation || data.condition || ''}
          placeholder={t('templates:conditionalWrapper.createCondition')}
          readOnly
          onClick={e => {
            e.preventDefault();
            handleOnEditClick();
          }}
          rightSection={
            <Box sx={theme => ({ marginLeft: theme.other.spacing(1) })}>
              <VariableContextMenu
                position="bottom-end"
                editLabel="templates:templateCreatorVariables.editConditional"
                onEditClick={handleOnEditClick}
                onRemoveClick={handleOnRemoveClick}
              />
            </Box>
          }
        />
        {children}
      </Wrapper>
    </>
  );
};

export const conditionalWrapper = {
  onKeyDown: (e, editor, next) => {
    if (e.key === 'Enter' && e.shiftKey) {
      const node = editor.value.document.getClosest(
        editor.value.endBlock.key,
        v => v.object === 'block' && v.type === 'conditionalWrapper',
      );

      if (node) {
        e.preventDefault();
        editor.moveToEndOfNode(node);
        const path = editor.value.document
          .getPath(editor.value.endBlock.key)
          .toJSON();
        const index = path[path.length - 1];
        editor.splitNodeByKey(node.key, index + 1).moveForward(1);
        return null;
      }
    }

    return next();
  },
  renderNode: (props, editor, next) => {
    if (props.node.type === 'conditionalWrapper') {
      return <ConditionalWrapper {...props} />;
    }
    return next();
  },
  schema: {
    inlines: {
      conditionalWrapper: {
        text: string => !string.slice(string.length - 1, string.length).trim(),
        normalize: (editor, error) => {
          if (error.code === 'node_text_invalid') {
            editor.insertNodeByKey(
              error.node.key,
              error.node.nodes.size,
              Text.create({ text: ' ' }),
            );
          }
        },
      },
    },
    blocks: {
      conditionalWrapper: {
        first: [
          { type: 'paragraph' },
          { type: 'header' },
          { type: 'unorderedList' },
          { type: 'orderedList' },
          { type: 'table' },
          { type: 'conditionalWrapper' },
          { type: 'languageWrapper' },
          { type: 'table' },
          { type: 'pageBreak' },
          { type: 'signatures' },
        ],
        normalize: (editor, error) => {
          if (error.code === 'first_child_type_invalid') {
            editor.setNodeByKey(error.node.key, 'paragraph');
          }
        },
      },
    },
  },
};
