import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mantine/core';
import { DescriptionField } from 'components/shared';

export const ListSupport = ({ error, checkboxProps }) => {
  const { t } = useTranslation('templates');
  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.list')}
      description={t('templates:templateCreatorVariables.listDescription')}
      error={error}
    >
      <Checkbox {...checkboxProps} sx={{ margin: 0 }} />
    </DescriptionField>
  );
};
