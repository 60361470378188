import { useMantineTheme } from '@mantine/core';

export const TableCell = ({ attributes, children }) => {
  const theme = useMantineTheme();

  return (
    <td
      {...attributes}
      style={{
        border: `1px solid ${theme.colors.gray[2]}`,
        padding: theme.other.spacing(1),
        textAlign: 'center',
        minWidth: '100px',
      }}
    >
      {children}
    </td>
  );
};
