import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDebouncedValue } from '@mantine/hooks';

import { DEBOUNCED_INPUT_DELAY_IN_MS } from 'consts';
import { TrashIcon, SaveIcon } from 'icons';
import { ActionsBar } from 'components/shared/ActionsBar';

import { getMarginRangeValues } from './helpers';
import { MarginType } from '../types';
import { ActiveMarginType } from './types';
import { MarginInput } from './MarginInput';
import { MarginPreview } from './MarginPreview';

type Props = {
  margin: MarginType;
  isDeletingLetterhead: boolean;
  isUpdatingBrandingConfig: boolean;
  urlToRedirectAfterCancel: string;
  refreshPreview: (margin: MarginType) => void;
  deleteLetterhead: () => void;
  update: (margin: MarginType) => void;
};

export const DocumentMarginForm = ({
  margin,
  isDeletingLetterhead,
  isUpdatingBrandingConfig,
  urlToRedirectAfterCancel,
  refreshPreview,
  deleteLetterhead,
  update,
}: Props) => {
  const { t } = useTranslation(['common', 'organisations']);

  const [focusedMargin, setFocusedMargin] = useState<ActiveMarginType>('none');

  const { setValue, control, watch, reset, getValues, setFocus } =
    useForm<MarginType>({
      defaultValues: margin,
    });

  const top = watch('top');
  const right = watch('right');
  const bottom = watch('bottom');
  const left = watch('left');

  const trueValues = useMemo(
    () => getValues(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [top, right, bottom, left],
  );

  const [debouncedTrueValues] = useDebouncedValue(
    trueValues,
    DEBOUNCED_INPUT_DELAY_IN_MS,
  );

  useEffect(() => {
    refreshPreview(getMarginRangeValues(getValues()));
  }, [debouncedTrueValues, getValues, refreshPreview]);

  const getLabelTranslation = (variant: keyof MarginType) => {
    switch (variant) {
      case 'top':
        return t('organisations:brandingMarginTop');
      case 'right':
        return t('organisations:brandingMarginRight');
      case 'bottom':
        return t('organisations:brandingMarginBottom');
      case 'left':
        return t('organisations:brandingMarginLeft');
      default:
        return '';
    }
  };

  const isDuringAction = isDeletingLetterhead || isUpdatingBrandingConfig;

  useEffect(() => {
    reset(margin);
  }, [margin, reset]);

  return (
    <form>
      <MarginPreview
        sx={theme => ({
          marginBottom: theme.other.spacing(3),
        })}
        activeMargin={focusedMargin}
        onMarginItemClick={marginToFocus => {
          setFocus(marginToFocus);
        }}
        marginTopInCm={watch('top')}
        marginRightInCm={watch('right')}
        marginBottomInCm={watch('bottom')}
        marginLeftInCm={watch('left')}
      />
      {(['top', 'bottom', 'right', 'left'] as const).map(variant => (
        <Controller
          key={variant}
          name={variant}
          control={control}
          render={({ field: { ref, value } }) => (
            <MarginInput
              ref={ref}
              value={value}
              nestedLabel={getLabelTranslation(variant)}
              onBlur={() => {
                setFocusedMargin('none');
              }}
              onFocus={() => {
                setFocusedMargin(variant);
              }}
              onChange={marginValue => {
                setValue(variant, marginValue as number);
              }}
            />
          )}
        />
      ))}
      <ActionsBar
        items={[
          {
            name: t('organisations:cancelAndExit'),
            to: urlToRedirectAfterCancel,
            disabled: isDuringAction,
          },
          {
            name: t('organisations:deleteLetterhead'),
            action: deleteLetterhead,
            color: 'red',
            icon: <TrashIcon />,
            disabled: isUpdatingBrandingConfig,
            isLoading: isDeletingLetterhead,
          },
          {
            name: t('common:save'),
            action: () => {
              update(getValues());
            },
            icon: <SaveIcon />,
            color: 'blue',
            disabled: isDeletingLetterhead,
            isLoading: isUpdatingBrandingConfig,
          },
        ]}
      />
    </form>
  );
};
