import { Box, Stack, UnstyledButton } from '@mantine/core';
import { CreateIcon } from 'icons';
import { LoopWrapperContext } from 'legacy/TemplatePreview/createRules';
import { Fragment } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { useTemplateStore } from 'stores';
import { VariableContextMenu } from '../../../TemplateCreatorEditor/VariableContextMenu';

export const LoopWrapper = ({
  dependentVariableNames,
  children,
  ActionsContainer = Stack,
  ActionsContainerProps = {},
  ChildrenContainer = Fragment,
  ChildrenContainerProps = {},
  onlyStatic = false,
}) => {
  const { previewEditMode } = useTemplateStore();
  const { setValue, getValues } = useFormContext();
  const value = useWatch({
    name: dependentVariableNames[0],
  });

  if (!value) {
    return null;
  }

  const removeIsEnabled = value.length > 1;

  const insert = index => {
    dependentVariableNames.forEach(variableName => {
      const variableValue = getValues(variableName);

      if (Array.isArray(variableValue)) {
        variableValue.splice(index + 1, 0, '');
        setValue(variableName, variableValue);
      } else {
        throw new Error(`Variable ${variableName} is not an array`);
      }
    });
  };

  const remove = index => {
    dependentVariableNames.forEach(variableName => {
      const variableValue = getValues(variableName);

      if (Array.isArray(variableValue)) {
        variableValue.splice(index, 1);
        setValue(variableName, variableValue);
      } else {
        throw new Error(`Variable ${variableName} is not an array`);
      }
    });
  };

  const copy = index => {
    dependentVariableNames.forEach(variableName => {
      const variableValue = getValues(variableName);

      if (Array.isArray(variableValue)) {
        variableValue.splice(index + 1, 0, variableValue[index]);
        setValue(variableName, variableValue);
      } else {
        throw new Error(`Variable ${variableName} is not an array`);
      }
    });
  };

  return value.map((_, i) => (
    <LoopWrapperContext.Provider value={i} key={i}>
      {previewEditMode && !onlyStatic && (
        <ActionsContainer {...ActionsContainerProps}>
          <Box
            sx={theme => ({
              display: 'flex',
              justifyContent: 'flex-end',
              gap: theme.other.spacing(1),
            })}
          >
            <UnstyledButton onClick={() => insert(i)}>
              <CreateIcon />
            </UnstyledButton>

            <VariableContextMenu
              onCopyClick={() => copy(i)}
              onRemoveClick={removeIsEnabled ? () => remove(i) : undefined}
            />
          </Box>
        </ActionsContainer>
      )}
      {!previewEditMode || onlyStatic ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{children}</>
      ) : (
        <ChildrenContainer {...ChildrenContainerProps}>
          {children}
        </ChildrenContainer>
      )}
    </LoopWrapperContext.Provider>
  ));
};
