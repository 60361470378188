import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mantine/core';

import { CreateIcon } from 'icons';
import { Group2Icon } from 'legacy/TemplateCreatorEditor/icons';

export const ManageButtons = ({ addGroup, addCondition, deleteGroup }) => {
  const { t } = useTranslation('templates');

  const buttons = [
    {
      key: 'addCondition',
      translationKey: 'templates:templateCreatorVariables.addCondition',
      color: 'green',
      icon: <Group2Icon />,
      action: addCondition,
    },
    {
      key: 'addGroup',
      translationKey: 'templates:templateCreatorVariables.addGroup',
      color: 'green',
      icon: <CreateIcon />,
      action: addGroup,
    },
    ...(deleteGroup
      ? [
          {
            key: 'deleteGroup',
            translationKey: 'templates:templateCreatorVariables.deleteGroup',
            color: 'red',
            icon: null,
            action: deleteGroup,
          },
        ]
      : []),
  ];

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        gap: theme.other.spacing(1),
      })}
    >
      {buttons.map(({ key, translationKey, color, icon, action }) => (
        <Button
          sx={theme => ({
            fontSize: theme.fontSizes.lg,
            letterSpacing: -0.1,
            '.mantine-Button-label': {
              gap: theme.other.spacing(1),
            },
          })}
          key={key}
          variant="default"
          size="xs"
          color={color}
          onClick={action}
        >
          {icon} {t(translationKey)}
        </Button>
      ))}
    </Box>
  );
};
